import React, { useContext, useEffect, useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
// @ts-expect-error
import cookies from 'js-cookie';

import {
    abTestDataContext, MAIN_REDESIGN,
    MAIN_REDESIGN_CONTROL, MAIN_REDESIGN_TEST,
} from 'app/containers/contexts/abTest.context';

const USER_QUERY_FOR_AB_GROUPS = gql`
        query USER_QUERY_FOR_AB_GROUPS {
            user {
                id
                ab_groups
            }
        }
`;

export const useMainAbTest = () => {
    // @ts-ignore
    const { setABTestValue } = useContext(abTestDataContext);
    const { data: { user } } = useQuery(USER_QUERY_FOR_AB_GROUPS, {
        fetchPolicy: 'cache-only',
    });

    const isUserInABTestAlready = useMemo(
        () => user?.ab_groups.some((group: string) => group.includes(MAIN_REDESIGN)),
        [user],
    );

    const isTest = useMemo(
        () => user?.ab_groups.some((group: string) => group.includes(MAIN_REDESIGN_TEST)),
        [user],
    );

    useEffect(
        () => {
            if (isUserInABTestAlready) {
                const testValue = isTest
                    ? MAIN_REDESIGN_TEST
                    : MAIN_REDESIGN_CONTROL;

                cookies.set(MAIN_REDESIGN?.toUpperCase(), testValue, { expires: 30 });
                setABTestValue(MAIN_REDESIGN?.toUpperCase(), testValue);
            }
        },
        [isUserInABTestAlready, isTest, setABTestValue],
    );
};
