import { useState } from 'react';


function resolveInitialAuthState({ userQuery, lsPhone }) {
    if (userQuery?.data?.user) {
        return 'authResult';
    }
    // if (lsPhone) {
    //     return 'pincode';
    // }
    return 'telephone';
}

/**
 * telephone => pincode => authResult
 * */
export function useAuthState(userQuery) {
    const lsPhone = sessionStorage.getItem('phone');
    const initialAuthState = resolveInitialAuthState({ userQuery, lsPhone });
    const [authState, setAuthState] = useState(initialAuthState);

    return [
        authState,
        setAuthState,
        initialAuthState,
    ];
}
