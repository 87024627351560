import React from 'react';
import BasketNewUpsell from './BR1131/components/BasketNewUpsell';

import './basket.scss';

const BasketPromoArea = () => (
    <BasketNewUpsell />
);

export default React.memo(BasketPromoArea);


/*

DEPRECATED ---------------------------------------------------------------------

const BasketMenuSelectionMoblieSlider = (props) => {
    const {
        preparedBasketAreas: {
            basketStyleModifier,
            promo: { header, backgroundType },
        },
    } = props;

    const rootClasses = cn({
        [basketStyleModifier]: true,
        'basket-menu-selections-root': true,
        'basket-menu-selections-root--white': backgroundType === 'white',
    });

    return (
        <div styleName={rootClasses}>
            {header !== 'off' && (
                <UIHeading level="3.2">
                    <span styleName="basket-menu-selections-title">
                        Добавить другие блюда?
                    </span>
                </UIHeading>
            )}
            <div styleName="basket-menu-selections-slider__list-container">
                <SelectionsMobileSlider realm="basket_realm" {...props} />
            </div>
        </div>
    );
};

BasketMenuSelectionMoblieSlider.propTypes = {
    closeHandler: PropTypes.func.isRequired,
    preparedBasketAreas: PropTypes.shape({
        basketStyleModifier: PropTypes.string.isRequired,
        promo: PropTypes.shape({
            header: PropTypes.oneOf([null, 'off']),
            backgroundType: PropTypes.oneOf([null, 'white']),
            isMenuSelectionsMobileSlider: PropTypes.bool.isRequired,
        }).isRequired,
    }).isRequired,
};

*/
