import React, { FC } from 'react';
import classNames from 'classnames';
import css from './BasketSetsSelectionSlider.module.scss';
import { TCurrentSetType } from '../BasketSetsSelection';

// const greenIconsCollection: string[] = ['halloween1', 'halloween2', 'halloween3', 'halloween4', 'halloween5', 'halloween6'];

const imgConllectionRecord = {
    hp: ['hp1', 'hp2', 'hp3', 'hp4', 'hp5', 'hp6', 'hp7', 'hp8'],
    ny: ['ny1', 'ny2', 'ny3', 'ny4', 'ny5'],
    romantic: ['romantic1', 'romantic3', 'romantic6', 'romantic7'],
    maslenitsa: ['maslenitsa1', 'maslenitsa2', 'maslenitsa3', 'maslenitsa4'],
    green: ['green1', 'green2', 'green3', 'green4', 'green5', 'green6'],
    march: ['march1', 'march2', 'march3', 'march4', 'march5'],
    marchgifts: ['marchgifts1', 'marchgifts2', 'marchgifts3'],
    easter: ['easter1', 'easter2', 'easter3', 'easter4', 'easter5'],
};

interface IBasketSetsSelectionSliderProps {
    setName: TCurrentSetType
}

export const BasketSetsSelectionSlider = (props: IBasketSetsSelectionSliderProps) => {
    const { setName } = props;
    return (
        <div className={css.basketSetsSelectionSliderRoot}>
            {imgConllectionRecord[setName].map((slide: string) => {
                const classes = classNames({
                    [css.basketSetsSelectionSliderCard]: true,
                    [css[slide]]: true,
                });
                return (
                    <div
                        key={slide}
                        className={classes}
                    />
                );
            })}
        </div>
    );
};

