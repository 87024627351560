import React from 'react';
import PropTypes from 'prop-types';

import './button-bottom-area.scss';
import classNames from 'classnames';


const ButtonBottomArea = ({
    children,
    zIndex = 0,
    position = null,
}) => {
    const classes = classNames({
        'button-bottom-area__root': true,
        [position]: true,
    });

    return (
        <div
            styleName={classes}
            style={{ zIndex }}
        >
            {children}
        </div>
    );
};

ButtonBottomArea.propTypes = {
    children: PropTypes.node.isRequired,
    zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    position: PropTypes.string,
};

export default ButtonBottomArea;
