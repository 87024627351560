import PropTypes from 'prop-types';

import { RENDERING_CONTEXT_MENU, ALL_RENDERING_CONTEXTS, RENDERING_CONTEXT_LPS } from '../../const';

export function PortionsButtonGuard(props) {
    const { renderingContext, children } = props;

    if (renderingContext !== RENDERING_CONTEXT_MENU && renderingContext !== RENDERING_CONTEXT_LPS) return null;

    return children;
}

PortionsButtonGuard.propTypes = {
    renderingContext: PropTypes.oneOf(ALL_RENDERING_CONTEXTS).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};
