/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { errorService } from 'global/services';
import addDays from 'date-fns/add_days';
import { getTrialScenarioFromUrl } from 'app/utils/trial-scenario';
import { SUBSCRIPTION_TYPES } from 'app/const/subscription';
import { paymentMethodVar, selectedPeriodVar, subscriptionTypeVar } from 'app/apollo/reaction';
import { getAddressForQuery } from 'app/utils/address';

import {
    MSK_CODES,
    SPB_CODES,
} from 'app/const/location';
import {
    PAYMENT_PARAM_RECURRENT,
} from 'app/const/billing-payment-methods';


const _getIsRecurrentHiddenByPeriod = (menuFilter, selectedPeriod) => {
    const period = menuFilter && menuFilter.periods.find((p) => p.start === selectedPeriod);

    // hiddenPaymentMethods добавляются в patchQueryPeriods на клиенте
    const hideRecurrentByPeriod = period
        ? period.hiddenPaymentMethods && period.hiddenPaymentMethods.includes(PAYMENT_PARAM_RECURRENT)
        : false;
    return hideRecurrentByPeriod;
};

/* */
const checkIsOnlinePaymentHidden = (props) => {
    const {
        hiddenElements: {
            payment_button_online: hideOnline,
        },
        basketQuery: { cart: { totals: { static_common_price } } },
    } = props;
    const isFreeOrder = static_common_price === 0;
    return isFreeOrder || hideOnline;
};

/**
 * @description Проверяет нужно ли скрыть оплату по рекурентной подписке
 */
export const checkIsRecurrentPaymentHidden = (props) => {
    const {
        filterQuery: { menuFilter },
        selectedFilters: { selectedPeriod },
        basketQuery: { cart: { typeOfSet } },
        hiddenElements: { payment_button_recurrent: hideRecurrent },
    } = props;

    const isRegularBasket = typeOfSet === 'regular';
    const isRecurrentHiddenByPeriod = _getIsRecurrentHiddenByPeriod(menuFilter, selectedPeriod);
    const isOnlinePaymentHidden = checkIsOnlinePaymentHidden(props);
    return Boolean(hideRecurrent || (isRecurrentHiddenByPeriod && isRegularBasket) || isOnlinePaymentHidden);
};


export const isAddressesInSameSubdivision = (address1, address2) => {
    try {
        const { country_subdivision: subdivision1 } = address1;
        const { country_subdivision: subdivision2 } = address2;

        // TODO: убрать это условие, когда бэк перестанет возвращать null
        if (subdivision1 === null || subdivision2 === null) {
            return true;
        }

        const isInSPB = SPB_CODES.includes(subdivision1) && SPB_CODES.includes(subdivision2);
        const isInMSK = MSK_CODES.includes(subdivision1) && MSK_CODES.includes(subdivision2);

        return isInSPB || isInMSK;
    } catch (error) {
        errorService.log({
            source: 'client',
            text: 'Error addresses subdivision resolving in isAddressesInSameSubdivision',
            scope: 'Checkout.js',
            error,
        });
        return false;
    }
};

export const isSPBArea = (address) => {
    const { country_subdivision: subdivision } = address;
    return SPB_CODES.includes(subdivision);
};


/**
 * @description Проверяет нужно ли показывать диолог о том что выбранная дата доставки
 * в следующем периоде
 * @param {selectedPeriod, selectedDeliveryDay} строки в формате ГГГГ-ММ-ДД
 * FDF Full Date Format
 */
export const checkIsNeedToShowDialog = (selectedPeriod, selectedDeliveryDay) => {
    const isTrialOrder = Boolean(getTrialScenarioFromUrl());

    if (isTrialOrder) {
        return false;
    }
    if (!selectedDeliveryDay) {
        return false;
    }

    const selectedPeriod_FDF = new Date(selectedPeriod);
    const periodEnd_FDF = addDays(selectedPeriod_FDF, 6);
    const deliveryDay_FDF = new Date(selectedDeliveryDay);

    const isDeliveryDateInOtherPeriod = deliveryDay_FDF > periodEnd_FDF
        || deliveryDay_FDF < selectedPeriod_FDF;

    return isDeliveryDateInOtherPeriod;
};

export const setSpecificSpbPaymentMethod = async (props) => {
    const { mutateBilling, country_subdivision } = props;
    const currenPaymentMethod = paymentMethodVar();
    const period = selectedPeriodVar();

    if (
        (currenPaymentMethod === 'cash' || currenPaymentMethod === 'sbp')
        && ['RU-LEN', 'RU-SPE'].includes(country_subdivision)
    ) {
        paymentMethodVar('online');
        subscriptionTypeVar(SUBSCRIPTION_TYPES.singlePay)
        await mutateBilling({ variables: { payment_method: 'online', period } });
    }
};

/**
 * @param {address || intialAddress} lastDeliveredAddress
 * @param {address || intialAddress} sessionAddress
 */

const intialAddress = {
    city: '',
    country_subdivision: '',
    district: '',
    entrance: '',
    flat: '',
    floor: '',
    house: '',
    id: null,
    street: '',
};


const addressStateFactory = (address) => ({
    street: {
        value: getAddressForQuery(address),
        data: address,
    },
    apartment: address.flat,
    entrance: address.entrance,
    floor: address.floor,
});

export function getInitialStreetFormValue(
    sessionAddress = intialAddress,
    lastDeliveredAddress = intialAddress,
) {
    try {
        const lastAddressInStr = getAddressForQuery(lastDeliveredAddress || intialAddress);
        const sesstionAddressInStr = getAddressForQuery(sessionAddress || intialAddress);

        const isSessionAddressCompleted = sessionAddress?.district && sessionAddress?.city && sessionAddress?.house;

        if (isSessionAddressCompleted) {
            return addressStateFactory(sessionAddress);
        }

        /**
        * Кейс в когда в sessionAddress есть регион который совпадает с регионом из lasstAddress
        * Фактически есть адрес в lastDeliveredAddress и его регион совпадает с адресом в сессии
        */
        if (lastAddressInStr.includes(sesstionAddressInStr)) {
            return addressStateFactory(lastDeliveredAddress);
        }

        /**
        * Кейс в когда ни в lastAddress ни в sessionAddress нет данных об улицы
        * Фактически нет данных об адаресе
        */
        if (!lastDeliveredAddress?.street && !sessionAddress?.street) {
            return addressStateFactory(intialAddress);
        }

        /**
        * Кейс в котором есть адрес в сессии но при этом все верхние кейсы не отработали
        * Фактически есть заполненый адрес в сессии
        * @note Например кейс отработает если заполнить адрес на promo-pages, или после
        * перезагрузки странцы
        */
        if (sessionAddress?.street) {
            return addressStateFactory(sessionAddress);
        }

        return addressStateFactory(intialAddress);
    } catch (error) {
        errorService.log({
            source: 'client',
            text: error,
            scope: 'checkout.utils.js > getInitialStreetFormValue',
            error,
        });
        return addressStateFactory(intialAddress);
    }
}
