/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { DISH_ITEM_TYPE } from 'app/graphql/client/basket';
import { useBasketTypeChain } from '../basketTypeMiddleware/BasketTypeChain.middleware';


/* BASKET STATE CONTEXT */
const initialState = {
    // Уникальный CSS модификатор для каждой уникальной корзины. Всегда равен типу корзины
    basketStyleModifier: 'initial_basket',
    header: {
        isHeader: true,
    },
    top: {
        isCustomizationComment: false,
    },
    list: {
        isBasketDishesList: false,
        isBasketDishesListFlat: false,
        isEmptyBasket: false,
        titleType: null,
    },
    promo: {
        isMenuSelectionsMobileSlider: false,
        isNewUpsell: false,
        header: null,
    },
};

function reducer(state, action) {
    const { payload: { preparedAreas: { list } } } = action;

    switch (action.type) {
        case 'loading_basket': {
            return {
                ...initialState,
                header: { isHeader: false },
                basketStyleModifier: action.payload.type,
            };
        }
        case 'empty_basket':
            return {
                ...initialState,
                list: {
                    ...list,
                    isEmptyBasket: true,
                },
                basketStyleModifier: action.payload.type,
            };
        /* Корзина: маленкие картинки с выбором наборов блюд */
        case 'not_empty_basket':
            return {
                ...initialState,
                top: { isCustomizationComment: true },
                list: {
                    ...list,
                    isBasketDishesList: true,
                },
                promo: { isMenuSelectionsMobileSlider: false },
                basketStyleModifier: action.payload.type,
            };
        default:
            return { ...state };
    }
}

export const BasketTypeContext = React.createContext(initialState);
export const BasketTypeDispatchContext = React.createContext(null);

export const withUIBasketStore = (WrappedComponent) => (props) => {
    const [data, dispatch] = useReducer(reducer, initialState);
    const { type, preparedAreas } = useBasketTypeChain({ ...props });

    useEffect(() => {
        // console.log(`%c ${type}`, 'color: #FFDB00; font-family: Gilroy; font-size: 12px');
        dispatch({ type, payload: { type, preparedAreas } });
    }, [type]);

    return (
        <BasketTypeContext.Provider value={dispatch}>
            <BasketTypeDispatchContext.Provider value={data}>
                <WrappedComponent
                    prearedBasketAreas={data}
                    {...props}
                />
            </BasketTypeDispatchContext.Provider>
        </BasketTypeContext.Provider>
    );
};

export const useBasketUISelector = (props) => {
    const [data, dispatch] = useReducer(reducer, initialState);

    const { type, preparedAreas } = useBasketTypeChain({ ...props });


    useEffect(() => {
        dispatch({ type, payload: { type, preparedAreas } });
    }, [type]);

    return {
        prearedBasketAreas: data,
        ...props,
    };
};

withUIBasketStore.propTypes = {
    children: PropTypes.element.isRequired,
};

