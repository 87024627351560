import React, { FC } from 'react';
import BasketCategoriesList from './components/BasketCategoriesList/BasketCategoriesList';
import BasketUpsellSelections from './components/BasketUpsellSelections/BasketUpsellSelections';
// import BasketGreenSelection from './components/BasketGreenSelection';

import css from './BasketNewUpsell.module.scss';
import BasketSetsSelection from './components/BasketSetsSelection';
// import BasketGreenSelection from './components/BasketGreenSelection';
import { useBigSetBannerByPeriod } from './useBigSetBannerByPeriod';

const BasketNewUpsell: FC = () => {
    const setName = useBigSetBannerByPeriod();
    return (
        <div className={css.basketNewUpsellRoot}>
            <div className={css.basketUpsellTitle}>Добавить что-то</div>
            <BasketCategoriesList />
            {/* @ts-ignore */}
            <BasketUpsellSelections setName={setName} />
            <BasketSetsSelection setName={setName} />
        </div>
    );
};

export default BasketNewUpsell;
