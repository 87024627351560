/* eslint-disable react/static-property-placement */
/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ScrollLock from 'react-scrolllock';
import { Scrollbars } from 'react-custom-scrollbars';
import Raven from 'raven-js';
import compose from 'lodash/flowRight';
import sortBy from 'lodash/sortBy';
import { graphql } from '@apollo/client/react/hoc';
import { analyticService } from 'global/services';

import { VIEW_CUSTOMIZATION_COMMENT } from 'app/graphql/network/customizationComment';
import { SUBSCRIPTION_TYPES, isSubscription } from 'app/const/subscription';
import { ADD_ITEMS_TO_CART_BY_PERIOD } from 'app/graphql/network/basket/basketMutations';
import {
    CATEGORIES_ORDER,
    CATEGORY_MOST_INTRESTING,
    CATEGORY_MOST_INTRESTING_TAG_ID,
    CATEGORY_TITLES,
} from 'app/const/categories';
import {
    NY_DISHES,
    PROTEINS_ID,
    STYLE_ID,
    INGREDIENTS_ID,
    IS_PREMIUM_FILTER_ENABLED,
    PREMIUM_FILTER_TITLE,
    PREMIUM_FILTER_ID,
    BEST_TAG_ID,
    NO_FAT_FILTER,
    NO_FAT_FILTER_TITLE,
    getCategoryCount,
    extendTagByCount,
} from 'app/utils/filters';
import { selectDishes } from 'app/utils/dish';

import connect from 'app/connect';
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { abTestDataContext } from 'app/containers/contexts/abTest.context';
import { OverlayContextDispatch, withDispatchOverlay } from 'app/containers/contexts/overlay.context';
import { clearAfterSwitchDialogTexts } from 'app/containers/contexts/filters.context/filters.context';

import { appendTagReaction, deleteTagReaction, resetSelectedFiltersReaction } from 'app/hooks/useFilterHandlers';
import Responsive from 'app/components/Responsive';
import { LocaleContext, withLocaleContext } from 'app/containers/LocaleProvider';
import PeriodFilter from './components/Period';
import MenuFilters from './components/MenuFilters';
import SubscriptionSwitch from './components/SubscriptionSwitch';

import { locales } from './filters.locales';

import './filters.scss';
import {
    pickPeriodReaction, setCategoriesReaction,
} from '../../apollo/reactions/filters';
import CityFilter from './components/CityFilter';
import { MenuDataContext } from 'app/containers/contexts/menu.context';
import { useBasketQueryAdapter } from 'app/connect/connectToBasket';
import { subscriptionTypeVar } from 'app/apollo/reaction';


const isPeriodEmpty = (period) => !period || period === '';

const inRange = (number, start = 0, end = Infinity) => (number >= start && number <= end);

const getScrollTopPx = () => {
    // при изменение поменять в filters.scss
    const widthAndScroll = {
        1280: 146,
        1366: 150,
    };
    const is1280 = inRange(window.innerWidth, 960, 1280);
    const is1366 = inRange(window.innerWidth);
    let result;
    switch (true) {
        case is1280:
            result = widthAndScroll['1280'] + window.scrollY;
            break;
        case is1366:
            result = widthAndScroll['1366'] + window.scrollY;
            break;
        default:
            result = window.scrollY;
            break;
    }
    return result;
};


const mapCategories = (categories, selectedCategories, menuDishes, selectedTagIds, basketDishes, locale) => {
    const mapped = categories
        .map((c) => {
            const isSelected = selectedCategories.includes(c.id);
            const count = getCategoryCount({
                id: c.id,
                menuDishes,
                selectedTagIds,
                basketDishes,
            });
            const title = CATEGORY_TITLES[locale][c.id] || c.title;
            return {
                ...c,
                title,
                count,
                isSelected,
            };
        });

    const mostIntrestingCategory = {
        id: CATEGORY_MOST_INTRESTING,
        title: CATEGORY_TITLES[locale][CATEGORY_MOST_INTRESTING],
        count: selectDishes(
            menuDishes,
            null,
            [CATEGORY_MOST_INTRESTING_TAG_ID, ...selectedTagIds],
            selectedCategories,
        ).length,
        isSelected: selectedCategories.includes(CATEGORY_MOST_INTRESTING),
    };

    const resultCategories = CATEGORIES_ORDER.includes(CATEGORY_MOST_INTRESTING)
        ? [mostIntrestingCategory, ...mapped]
        : mapped;

    return resultCategories.filter((c) => {
        if (selectedTagIds.length > 0) return true;
        return c.count > 0;
    });
};


const getTagTitle = (tag) => {
    if (tag.id === NO_FAT_FILTER) {
        return NO_FAT_FILTER_TITLE;
    }
    if (tag.id === PREMIUM_FILTER_ID) {
        return PREMIUM_FILTER_TITLE;
    }
    // if (tag.id === '1219') {
    //     return 'Пост';
    // }
    return tag.title;
};


// eslint-disable-next-line arrow-body-style
const mapTags = (tags, categoryId, menuDishes, selectedCategories, selectedTagsIds, basketDishes, canCustomizeMenu) => {
    const mappedTags = tags
        .filter((t) => categoryId.includes(String(t.categoryId)))
        .map((t) => {
            const isSelected = Boolean(selectedTagsIds.find((id) => id === t.id));
            const { count } = extendTagByCount(t, menuDishes, selectedCategories, selectedTagsIds, basketDishes);

            return {
                ...t,
                title: getTagTitle(t),
                count,
                isSelected,
                disabled: !canCustomizeMenu,
            };
        });

    return sortBy(mappedTags, [
        (tag) => tag.id !== PREMIUM_FILTER_ID,
        (tag) => tag.id === BEST_TAG_ID,
    ]);
};


class Filters extends React.PureComponent {
    filtersRef = React.createRef();

    handleCloseFilters = (e) => {
        const filters = this.filtersRef.current;
        if (filters && filters.contains(e.target)) return;

        const { onClose } = this.props;
        onClose();
    };

    /*
        TODO: Метод скопипащен в Basket.js и Checkout.js
        В идеале нужно вынести из компонентов вместе с перезапроами корзины и состояния промокода
    */
    toggleSubscription = async (event) => {
        event.stopPropagation();
        event.preventDefault();


        const {
            updateBilling,
            subscriptionTypeQuery: {
                subscriptionType,
            },
            paymentMethod: { paymentMethod },
            basketQuery,
        } = this.props;

        const nextType = isSubscription(subscriptionType)
            ? SUBSCRIPTION_TYPES.singlePay
            : SUBSCRIPTION_TYPES.subscription;

        subscriptionTypeVar(nextType);

        try {
            await updateBilling({ variables: { payment_method: isSubscription(nextType) ? 'recurrent' : paymentMethod } });
            await basketQuery.refetch();
        } catch (e) {
            Raven.captureException(e);
        }
    };

    handleSetPeriod = async (start) => {
        const {
            filterQuery: { menuFilter: { periods } },
            selectedFilters: {
                selectedPeriod,
                prevSelectedPeriods,
                selectedTags,
            },
            updateBilling,
            addItemsToCartByPeriod,
            basketQuery,
            dispatchOverlayContext: { pushDialogOverlay, closeLastOverlays },
            localeContext: { locale },
        } = this.props;

        const callSetPeriodMutations = async (isNeededClearBasket = false) => {
            pickPeriodReaction({ periodStart: start });
            analyticService.push({
                eventName: 'Change_Filter_Item',
                itemKey: 'period',
                periodIndex: periods.findIndex((p) => p.start === start),
            });

            window.scrollTo(0, 0);
            try {
                await updateBilling();

                if (!prevSelectedPeriods.includes(start)) {
                    await addItemsToCartByPeriod({
                        variables: {
                            period: start,
                            fillByPeriod: selectedPeriod,
                            tags: selectedTags,
                        },
                    });
                }

                await basketQuery.refetch({ clear: isNeededClearBasket });
            } catch (e) {
                Raven.captureException(e);
            }
        };

        const nextPeriod = periods.find((p) => p.start === start);

        const isBasketEmpty = basketQuery.cart.sections.length === 0;
        const isNeededClearBasket = nextPeriod.clearAfterSwitchFrom;

        if (isNeededClearBasket && !isBasketEmpty) {
            const dialogData = {
                ...clearAfterSwitchDialogTexts[locale],
                onConfirm: async () => {
                    await callSetPeriodMutations(isNeededClearBasket);
                    closeLastOverlays();
                },
                notifyOnly: false,
                oneRowButtons: true,
            };

            pushDialogOverlay('clear_basket_dialog_desktop_filters', dialogData);
        } else {
            await callSetPeriodMutations();
        }
    };

    handleAddCategory = ({ id }) => {
        const {
            selectedFilters: { selectedCategories },
        } = this.props;

        setCategoriesReaction({ categories: selectedCategories.concat(id) });
        analyticService.push({
            eventName: 'Change_Filter_Item',
            itemKey: 'category',
            action: 'on',
            categoryId: id,
        });
    };

    handleRemoveCategory = ({ id }) => {
        const {
            selectedFilters: { selectedCategories },
        } = this.props;

        setCategoriesReaction({ categories: selectedCategories.filter((cid) => cid !== id) });
        analyticService.push({
            eventName: 'Change_Filter_Item',
            itemKey: 'category',
            action: 'off',
            categoryId: id,
        });
    };

    handleAddTag = (tag) => {
        appendTagReaction({ id: tag.id, isUserChangesTags: true });

        analyticService.push({
            eventName: 'Change_Filter_Item',
            itemKey: 'tag',
            action: 'on',
            tagTitle: tag.title,
        });
        window.scrollTo(0, 0);
    };

    handleRemoveTag = (tag) => {
        deleteTagReaction({ tagId: tag.id, isUserChangesTags: true });
        analyticService.push({
            eventName: 'Change_Filter_Item',
            itemKey: 'tag',
            action: 'off',
            tagTitle: tag.title,
        });
        window.scrollTo(0, 0);
    };

    handleResetFilters = () => {
        resetSelectedFiltersReaction();
        analyticService.push({
            eventName: 'Change_Filter_Item',
            itemKey: 'clear',
        });
    };

    handleExpandFilterItem = ({ isExpanded }) => {
        if (isExpanded) {
            analyticService.push({
                eventName: 'Change_Filter_Item',
                itemKey: 'openWeek',
            });
        }
    };

    renderFilters = () => {
        const {
            menuDatesContext: {
                state:
                {
                    selectedDate,
                    filterPeriod: menuDatesFilterPeriod,
                    isSelectedDateInEditableRange,
                },
            },
            abTestDataContext: { menu: menuAbTestValue },
            menuDishesQuery: { allDishes, basketDishes },
            filterQuery: { menuFilter },
            selectedFilters: {
                selectedPeriod,
                selectedCategoryId,
                selectedCategories,
                selectedTags,
                isSelectedDefaultFilters,
            },
            subscriptionTypeQuery: {
                subscriptionType,
            },
            isCommentEditEnabled,
            sessionCommentQuery: { viewCustomizationComment },
            isNeededRenderPeriod,
            isNeededRenderSubscription,
            isCommentAnimationDisabled,
            onClickSubscriptionInfoOpen,
            localeContext: { locale },
        } = this.props;

        // Locale content data
        const {
            categoriesTitle, proteinsTitle, stylesTitle, ingredientsTitle,
        } = locales[locale];

        if (!selectedPeriod || !selectedCategoryId) return null;

        const { periods } = menuFilter;
        const period = periods.find((p) => p.start === selectedPeriod);
        const { categories, tags: periodTags } = period;

        const tags = periodTags.filter((tag) => {
            if (tag.id === PREMIUM_FILTER_ID) {
                return IS_PREMIUM_FILTER_ENABLED;
            }
            if (tag.id === NY_DISHES) {
                return false;
            }
            return true;
        });

        const canCustomizeMenu = menuDatesFilterPeriod === selectedPeriod ? isSelectedDateInEditableRange : true;

        const categoriesData = {
            key: 'categories-list',
            title: categoriesTitle,
            options: mapCategories(categories, selectedCategories, allDishes, selectedTags, basketDishes, locale),
            onAdd: this.handleAddCategory,
            onRemove: this.handleRemoveCategory,
        };

        const ingredientsData = {
            key: INGREDIENTS_ID,
            title: ingredientsTitle,
            options: mapTags(
                tags, [PROTEINS_ID, INGREDIENTS_ID], allDishes, selectedCategories, selectedTags, basketDishes, canCustomizeMenu,
            ),
            onAdd: this.handleAddTag,
            onRemove: this.handleRemoveTag,
        };
        const stylesData = {
            key: STYLE_ID,
            title: <Responsive desktop={stylesTitle.short} wideDesktop={stylesTitle.long} />,
            options: mapTags(tags, [STYLE_ID], allDishes, selectedCategories, selectedTags, basketDishes, canCustomizeMenu),
            onAdd: this.handleAddTag,
            onRemove: this.handleRemoveTag,
        };

        const menuFiltersData = [
            categoriesData,
            ingredientsData,
            stylesData,
        ];

        const isCommentAnimationRunning = (
            !isCommentAnimationDisabled
            && !(viewCustomizationComment && viewCustomizationComment.comment)
        );

        return (
            <Scrollbars
                autoHide
                renderTrackVertical={() => <div />}
                renderThumbVertical={() => <div />}
            >
                <>
                    <CityFilter
                        menuAbTestValue={menuAbTestValue}
                        selectedDate={selectedDate}
                        menuDatesFilterPeriod={menuDatesFilterPeriod}
                        periods={periods.filter((p) => p.isVisibleInFilter === 'visible')}
                        onSelect={this.handleSetPeriod}
                        selectedPeriod={selectedPeriod}
                        itemKey="period"
                        onExpand={this.handleExpandFilterItem}
                        locale={locale}
                    />
                    {isNeededRenderPeriod && (
                        <PeriodFilter
                            menuAbTestValue={menuAbTestValue}
                            selectedDate={selectedDate}
                            menuDatesFilterPeriod={menuDatesFilterPeriod}
                            periods={periods.filter((p) => p.isVisibleInFilter === 'visible')}
                            onSelect={this.handleSetPeriod}
                            selectedPeriod={selectedPeriod}
                            itemKey="period"
                            onExpand={this.handleExpandFilterItem}
                            locale={locale}
                        />
                    )}
                    <MenuFilters
                        isCommentEditEnabled={isCommentEditEnabled}
                        isCommentAnimationRunning={isCommentAnimationRunning}
                        isResetButtonVisible={!isSelectedDefaultFilters}
                        canCustomizeMenu={canCustomizeMenu}
                        filtersData={menuFiltersData}
                        onExpandSection={this.handleExpandFilterItem}
                        onClickResetButton={this.handleResetFilters}
                        locale={locale}
                    />
                    <div styleName="discount">
                        {isNeededRenderSubscription && (
                            <SubscriptionSwitch
                                isSubscriptionActivated={isSubscription(subscriptionType)}
                                onChange={this.toggleSubscription}
                                onClickSubscriptionInfo={onClickSubscriptionInfoOpen}
                                locale={locale}
                            />
                        )}
                    </div>
                </>
            </Scrollbars>
        );
    };

    render() {
        const {
            filterQuery: { loading, error },
            selectedFilters: {
                selectedPeriod,
            },
            onToggle,
            isFiltersOpen,
            overlayIsOpen,
            localeContext: { locale },
        } = this.props;

        // Locale content data
        const {
            errorText,
            filterButtonText,
        } = locales[locale];


        if (loading || isPeriodEmpty(selectedPeriod)) {
            return null;
        }
        if (error) return <div>{errorText}</div>;

        const scrollTopPx = getScrollTopPx();
        const classes = classNames({
            'filters-wrap filters-wrap--tablet': true,
            open: isFiltersOpen,
        });
        const buttonClasses = classNames({
            'filters-button': true,
            open: isFiltersOpen,
        });

        return (
            <div styleName="filters" ref={this.filtersRef}>
                <Responsive
                    desktop={(
                        <>
                            {/* eslint-disable-next-line */}
                            <div
                                aria-label="Открыть фильтры"
                                styleName={buttonClasses}
                                onClick={onToggle}
                                data-test-id="filters-button"
                            >
                                <div styleName="filters-button-text">{filterButtonText}</div>
                            </div>
                            <div styleName={classes}>
                                {this.renderFilters()}
                            </div>
                            {isFiltersOpen && (
                                <ScrollLock />
                            )}
                        </>
                    )}
                    wideDesktop={(
                        <div styleName="filters-wrap" style={overlayIsOpen ? { top: `${scrollTopPx}px` } : {}}>
                            {this.renderFilters()}
                        </div>
                    )}
                />
            </div>
        );
    }
}


Filters.propTypes = {
    overlayIsOpen: PropTypes.bool,
    localeContext: PropTypes.shape({
        locale: PropTypes.string,
    }).isRequired,

    abTestDataContext: PropTypes.shape({
        menu: PropTypes.oneOf(['new', 'old', null]),
    }).isRequired,

    menuDatesContext: PropTypes.shape({
        state: PropTypes.shape({
            selectedDate: PropTypes.string.isRequired,
            filterPeriod: PropTypes.string.isRequired,
            isSelectedDateInEditableRange: PropTypes.bool.isRequired,
        }).isRequired,
    }).isRequired,

    filterQuery: PropTypes.shape({
        loading: PropTypes.bool,
        error: PropTypes.object,
        menuFilter: PropTypes.shape({
            periods: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }).isRequired,

    selectedFilters: PropTypes.shape({
        selectedCategories: PropTypes.arrayOf(
            PropTypes.string,
        ),
        selectedPeriod: PropTypes.string,
        selectedCategoryId: PropTypes.string,
        selectedTags: PropTypes.arrayOf(
            PropTypes.string,
        ),
    }).isRequired,

    menuDishesQuery: PropTypes.shape({
        menuDishes: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,

    sessionCommentQuery: PropTypes.shape({
        viewCustomizationComment: PropTypes.shape({
            comment: PropTypes.string,
        }),
    }).isRequired,

    subscriptionTypeQuery: PropTypes.shape({
        subscriptionType: PropTypes.string.isRequired,
    }).isRequired,

    updateBilling: PropTypes.func.isRequired,

    addItemsToCartByPeriod: PropTypes.func.isRequired,

    basketQuery: PropTypes.shape({
        refetch: PropTypes.func,
        cart: PropTypes.shape({}),
    }),

    paymentMethod: PropTypes.shape({
        paymentMethod: PropTypes.string,
    }).isRequired,

    isCommentEditEnabled: PropTypes.bool,
    isCommentAnimationDisabled: PropTypes.bool,
    isNeededRenderPeriod: PropTypes.bool,
    isNeededRenderSubscription: PropTypes.bool,
    isFiltersOpen: PropTypes.bool,

    onClose: PropTypes.func,
    onToggle: PropTypes.func,
    onClickSubscriptionInfoOpen: PropTypes.func,

    dispatchOverlayContext: PropTypes.shape({
        pushDialogOverlay: PropTypes.func.isRequired,
        closeLastOverlays: PropTypes.func.isRequired,
    }).isRequired,
};

const FiltersDataAdapter = (props) => {
    const {
        overlayIsOpen = false,
        isCommentEditEnabled = true,
        isCommentAnimationDisabled = false,
        isNeededRenderPeriod = true,
        isNeededRenderSubscription = true,
        isFiltersOpen = false,
        onClose = () => { },
        onToggle = () => { },
        onClickSubscriptionInfoOpen = () => { },
    } = props;

    const { basketQuery } = useBasketQueryAdapter();

    const menuDatesContext = useContext(menuDatesState);
    const abTestData = useContext(abTestDataContext);
    const localeContext = useContext(LocaleContext);
    const dispatchOverlayContext = useContext(OverlayContextDispatch);

    const [addItemsToCartByPeriod] = useMutation(ADD_ITEMS_TO_CART_BY_PERIOD);

    return (
        <Filters
            {...props}
            localeContext={localeContext}
            menuDatesContext={menuDatesContext}
            abTestDataContext={abTestData}
            dispatchOverlayContext={dispatchOverlayContext}
            overlayIsOpen={overlayIsOpen}
            isCommentEditEnabled={isCommentEditEnabled}
            isCommentAnimationDisabled={isCommentAnimationDisabled}
            isNeededRenderPeriod={isNeededRenderPeriod}
            isNeededRenderSubscription={isNeededRenderSubscription}
            isFiltersOpen={isFiltersOpen}
            onClose={onClose}
            onToggle={onToggle}
            onClickSubscriptionInfoOpen={onClickSubscriptionInfoOpen}
            basketQuery={basketQuery}
            addItemsToCartByPeriod={addItemsToCartByPeriod}
        />
    );
};

export default connect(
    compose(
        graphql(VIEW_CUSTOMIZATION_COMMENT, { name: 'sessionCommentQuery' }),
    )(FiltersDataAdapter),
);
