/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    createContext,
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import {
    useHistory,
    useLocation,
} from 'react-router-dom';
import queryString from 'query-string';


export const actions = {
    OPEN: 'OPEN',
    CLOSE: 'CLOSE',
    FILTER_BY_TAGS: 'FILTER_BY_TAGS',
};


const initialState = {
    data: {
        isOpen: false,
        tag: null,
    },
    onOpenSelection: () => { },
    closeSelectionHeaderHandler: () => { },
    onCloseSelectionCTA: () => { },
};


export const DishesSelectionContext = createContext(initialState);

export const withDishesSelectionContext = (Component) => (props) => (
    <DishesSelectionContext.Consumer>
        {(dishesSelection) => <Component {...props} dishesSelection={dishesSelection} />}
    </DishesSelectionContext.Consumer>
);

export const DishesSelectionProvider = (props) => {
    const { children } = props;

    const history = useHistory();
    const location = useLocation();

    const [prevLocation, setPrevLocation] = useState(location);
    /**
     * @param {target_realm, menu_realm, basket_realm} realm
     * @description Отпределяет из какой области открыта подборка
     */
    const [realm, setRealm] = useState('target_realm');
    const [isOpen, setIsOpen] = useState(false);
    const [tag, setTag] = useState([]);

    const onOpenSelection = ({ tag: nextTag, targetRealm }) => {
        const currentQuery = queryString.parse(location.search);
        if (nextTag === currentQuery.st) return;

        const nextQuery = {
            ...currentQuery,
            st: nextTag,
        };

        setPrevLocation(location);
        setRealm(targetRealm);

        history.push({
            pathname: location.pathname,
            search: queryString.stringify(nextQuery),
        });
    };

    const closeSelectionHeaderHandler = () => {
        const backToSource = sessionStorage.getItem('backToSource');
        const forwardSource = sessionStorage.getItem('forwardSource');

        if (backToSource) {
            window.location.hash = backToSource;
            window.location.pathname = forwardSource;
            sessionStorage.removeItem('forwardSource');
            sessionStorage.removeItem('backToSource');
        } else {
            history.push(prevLocation);
        }
    };

    const onCloseSelectionCTA = () => {
        const toCheckout = sessionStorage.getItem('toCheckout');

        if (toCheckout) {
            history.push('/checkout/');
        } else {
            history.push(prevLocation);
        }

        sessionStorage.removeItem('forwardSource');
        sessionStorage.removeItem('backToSource');
    };

    useEffect(() => {
        const params = queryString.parse(location.search);
        const { st, ...paramsWithoutSelectionTags } = params;

        setPrevLocation({
            ...location,
            search: queryString.stringify(paramsWithoutSelectionTags),
        });
    }, []);

    useEffect(() => {
        const selectionTagParam = queryString.parse(location.search).st;
        if (selectionTagParam) {
            setIsOpen(true);
            setTag(selectionTagParam);
        } else {
            setIsOpen(false);
            setTag(null);
        }
    }, [location.search]);

    const value = {
        data: {
            isOpen,
            tag,
            realm,
        },
        onOpenSelection,
        onCloseSelectionCTA,
        closeSelectionHeaderHandler,
    };

    return (
        <DishesSelectionContext.Provider value={value}>
            {children}
        </DishesSelectionContext.Provider>
    );
};

DishesSelectionProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
