import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import classNames from 'classnames';
import Velocity from 'velocity-animate';

import CustomizationCommentFilters from 'app/components/CustomizationComment/CustomizationCommentFilters';
import FilterIcon from 'assets/svg/filter_icon.svg';
import FilterItem from '../FilterItem';
import MenuFilterOptionList from '../MenuFilterOptionList';
import { locales } from './menuFilters.locales';

import './menu-filters.scss';


const OVERLAY_DISSAPEARING_DELAY = 700;


const ANIMATION_OPTIONS = {
    duration: 400,
    easing: 'ease-in-out',
};

function animateExpand(el) {
    Velocity(
        el,
        'slideDown',
        ANIMATION_OPTIONS,
    );
}

function animateCollapse(el) {
    Velocity(
        el,
        'slideUp',
        ANIMATION_OPTIONS,
    );
}

class MenuFilters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isExpanded: false,
            isCommentExpanded: false,
            // isButtonSticky: false,
            expandedSectionKeys: [],

            isCustomizationCommentAreaShown: false,
            isCustomizationCommentSidebarShown: false,
        };

        this.filtersContentWrapRef = React.createRef();

        this.resetButtonWrapRef = React.createRef();
        // this.iObserver = new IntersectionObserver(this.handleToggleResetButtonState);
    }

    componentDidMount() {
        animateCollapse(this.filtersContentWrapRef.current);

        const { isResetButtonVisible } = this.props;

        if (isResetButtonVisible) {
            this.handleToggle(true);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.resolveFiltersContentWrapVisibility(prevState);

        const { isResetButtonVisible: currentIsResetButtonVisible } = this.props;
        const { isResetButtonVisible: prevIsResetButtonVisible } = prevProps;
        const isResetButtonVisibilityChanged = currentIsResetButtonVisible !== prevIsResetButtonVisible;

        if (isResetButtonVisibilityChanged && currentIsResetButtonVisible) {
            this.handleToggle(true);
        }
    }

    // eslint-disable-next-line react/sort-comp
    resolveFiltersContentWrapVisibility(prevState) {
        const { isExpanded } = this.state;
        const { isExpanded: prevIsExpanded } = prevState;

        const isValueChanged = isExpanded !== prevIsExpanded;
        const el = this.filtersContentWrapRef.current;

        if (!isValueChanged) return;

        if (isExpanded) {
            animateExpand(el);
        } else {
            animateCollapse(el);
        }
    }

    toggleCustomizationCommentSidebar = () => {
        const { isCustomizationCommentAreaShown: isCommentShown } = this.state;

        if (isCommentShown) this.setState({ isCustomizationCommentSidebarShown: isCommentShown });

        if (!isCommentShown) {
            setTimeout(() => this.setState({ isCustomizationCommentSidebarShown: isCommentShown }), OVERLAY_DISSAPEARING_DELAY);
        }
    };

    toggleCustomizationCommentArea = (isShown) => () => {
        const { isCustomizationCommentAreaShown } = this.state;

        this.setState(
            { isCustomizationCommentAreaShown: isShown || !isCustomizationCommentAreaShown },
            () => this.toggleCustomizationCommentSidebar(),
        );
    };

    handleToggle = (isExpanded) => {
        this.setState({
            isExpanded,
        });
        if (isExpanded) {
            const { expandedSectionKeys } = this.state;
            const { filtersData: [{ key }] } = this.props;
            if (expandedSectionKeys.length === 0) this.handleToggleSection({ key, isExpanded: true });
        }

        if (isExpanded) {
            setTimeout(() => {
                this.setState({ isCommentExpanded: true });
            }, 100);
        } else {
            this.setState({ isCommentExpanded: false });
        }
    };

    handleToggleSection = ({ key, isExpanded }) => {
        const { expandedSectionKeys } = this.state;
        if (isExpanded) {
            this.setState({
                expandedSectionKeys: expandedSectionKeys.concat(key),
            });
        } else {
            const nextKeys = expandedSectionKeys.filter((k) => k !== key);
            if (nextKeys.length === 0) {
                this.setState({
                    isExpanded: false,
                    isCommentExpanded: false,
                });
            } else {
                this.setState({
                    expandedSectionKeys: nextKeys,
                });
            }
        }
    };

    render() {
        const {
            // isButtonSticky,
            isExpanded,
            isCommentExpanded,
            expandedSectionKeys,
            isCustomizationCommentAreaShown,
            isCustomizationCommentSidebarShown,
        } = this.state;

        const {
            canCustomizeMenu,
            isCommentEditEnabled,
            isCommentAnimationRunning,
            isResetButtonVisible,
            filtersData,
            onClickResetButton,
            locale,
        } = this.props;

        // Locale content data
        const {
            filterTitle,
            filterSubtitle,
            resetText,
        } = locales[locale];

        const isFirstFilterExpanded = expandedSectionKeys.includes('categories-list');
        const isCommentVisible = isCommentEditEnabled && isCommentExpanded && canCustomizeMenu;

        return (
            <FilterItem
                title={filterTitle}
                subtitle={() => filterSubtitle}
                Icon={FilterIcon}
                isExpanded={isExpanded}
                isArrowExpanded={isExpanded && isFirstFilterExpanded}
                onToggle={this.handleToggle}
                hideOnExpand
            >
                <div
                    styleName="filter-item__content-wrap"
                    ref={this.filtersContentWrapRef}
                >
                    <div styleName="filter-item__content">
                        {filtersData.map((filterDataItem, i) => (
                            <MenuFilterOptionList
                                key={filterDataItem.key}
                                expandKey={filterDataItem.key}
                                onClickExpand={this.handleToggleSection}
                                isExpanded={expandedSectionKeys.includes(filterDataItem.key)}
                                isArrowHidden={i === 0}
                                isExpandAnimationEnabled={expandedSectionKeys.length > 1}
                                isCollapseAnimationEnabled={expandedSectionKeys.length > 0}
                                {...filterDataItem}
                                locale={locale}
                            />
                        ))}
                    </div>
                    <div ref={this.resetButtonWrapRef} />
                    <Transition
                        in={isResetButtonVisible}
                        timeout={400}
                    >
                        {(transitonState) => {
                            const wrapClasses = classNames({
                                'filter-item__reset-button-wrap': true,
                                [transitonState]: transitonState,
                                // 'is-sticky': isButtonSticky,
                            });
                            return (
                                <div styleName={wrapClasses}>
                                    <button
                                        type="button"
                                        styleName="filter-item__reset-button"
                                        onClick={onClickResetButton}
                                    >
                                        {resetText}
                                    </button>
                                </div>
                            );
                        }}
                    </Transition>
                    {/* {isCommentVisible && (
                        <CustomizationCommentFilters
                            isAnimationRunning={isCommentAnimationRunning}
                            commentFormRoleButtonClampDelay={100}
                            isCommentAreaShown={isCustomizationCommentAreaShown}
                            isCommentSidebarShown={isCustomizationCommentSidebarShown}
                            locale={locale}
                            toggleCommentArea={this.toggleCustomizationCommentArea()}
                            onClosed={() => {}}
                            onOpened={() => {}}
                        />
                    )} */}
                </div>
            </FilterItem>
        );
    }
}

export default React.memo(MenuFilters);

MenuFilters.propTypes = {
    filtersData: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        }),
    ).isRequired,
    isCommentAnimationRunning: PropTypes.bool.isRequired,
    isCommentEditEnabled: PropTypes.bool.isRequired,
    canCustomizeMenu: PropTypes.bool.isRequired,
    isResetButtonVisible: PropTypes.bool.isRequired,
    onClickResetButton: PropTypes.func.isRequired,
    onExpandSection: PropTypes.func.isRequired,
};
