import flatten from 'lodash/flatten';

export const periods = [
    '2025-03-03',
    '2025-03-10',
    '2025-03-17',
    '2025-03-24',
];

export const enabledDishes = {
    main: [
        '4344',
        '4011',
    ],
    women: [
        '3941',
        '3948',
        '3947',
        '3943',
        '3945',
        '3942',
    ],
    guest: [
        '4317',
        '58',
        '3723',
        '4169',
        '4102',
    ],
};

export const availableBasketDishIds = flatten(Object.values(enabledDishes));
