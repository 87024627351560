import { gql } from '@apollo/client';


export const STORE_USER_DATA = gql`
    mutation storeUserData(
        $hasApplication: Boolean
        $gaClientId: String
        $cityAdsClickId: String
        $gaSessionId: String
        $participantId: String
    ) {
        storeUserData(
            hasApplication: $hasApplication
            gaClientId: $gaClientId
            cityAdsClickId: $cityAdsClickId
            gaSessionId: $gaSessionId
            participantId: $participantId
        ) {
            success
        }
    }
`;
