// const bannerClasses = classNames({

//     // [css.setBannerRowBackground]: true,
//     // [css.film]: false,
//     // [css.halloween]: false,
//     // [css.hp]: false,
//     // [css.maxim]: false,
//     // [css.ny]: false,
//     // [css.romantic]: false,
//     // [css.maslenitsa]: false,
//     // [css.marchgifts]: false,
//     // [css.march]: false,
//     [css[]]: true,
// });

// // const FilmSetLink = '/film/';
// // const HalloweenSetLink = '/halloween/';
// // const hpSetLink = '/hp/';
// // const maximSetLink = '/maximonline/?erid=2SDnjer5shV';
// // const nyLPLink = '/blyuda-dlya-novogodnego-stola/';
// // const romanticLink = '/romantic';
// // const maslenitsaLink = '/maslenitsa/';
// // const marchgiftsLink = '/marchgifts/';
// const womenLink = 'march';


export function useBurgerMenuBanner() {
    return { name: 'easter', href: '/easterbox' };
    // return { name: 'YaP', href: '/practicum.yandex/?erid=2SDnjcg1GtC' };
}
