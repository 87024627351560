/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { shape, func, string } from 'prop-types';
import { SUBSCRIPTION_TYPES, isSubscription } from 'app/const/subscription';
import { UIToggleSwitch } from 'app/components/ui';
import { analyticService } from 'global/services';
import './stepPaySubToggleSection.scss';

const StepPaySubToggleSection = (props) => {
    const {
        handleSelectPayment,
        subscriptionTypeQuery: {
            subscriptionType,
        },
        onClickSubscriptionInfoOpenWithContext,
    } = props;
    /* STATE */
    const [
        isChecked,
        setIsChecked,
    ] = useState(subscriptionType === 'subscription');

    useEffect(() => {
        setIsChecked(isSubscription(subscriptionType));
    }, [subscriptionType]);

    const toggleSubscriptionType = useCallback((event) => {
        const { target: { checked } } = event;
        const eventValue = checked ? 'Check_Select_Subscription' : 'Check_Unselect_Subscription';
        analyticService.push({ eventName: 'Check_Subscription_Toggle', eventValue });
        const nextType = isSubscription(subscriptionType)
            ? SUBSCRIPTION_TYPES.singlePay
            : SUBSCRIPTION_TYPES.subscription;

        handleSelectPayment('online', nextType);
    }, [subscriptionType]);

    return (
        <div styleName="sub-toggle-root">
            <div styleName="sub-toggle-background">
                <div styleName="sub-toggle-row-one">
                    <div styleName="sub-toggle-about">
                        Подписка + Скидка 10%
                    </div>
                    <div styleName="sub-toggle-control">
                        <UIToggleSwitch
                            onChanegHandler={toggleSubscriptionType}
                            isChecked={isChecked}
                            id="step-payment-subscription-toggle"
                        />
                    </div>
                </div>
                <div styleName="sub-toggle-row-two">
                    <button
                        styleName="sub-toggle-details"
                        onClick={onClickSubscriptionInfoOpenWithContext}
                        type="button"
                    >
                        <span styleName="link">
                            Еженедельная доставка на дом
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

StepPaySubToggleSection.propTypes = {
    handleSelectPayment: func.isRequired,
    onClickSubscriptionInfoOpenWithContext: func.isRequired,
    subscriptionTypeQuery: shape({
        subscriptionType: string,
    }).isRequired,
};

export default React.memo(StepPaySubToggleSection);
