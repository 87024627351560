export const dishes = [
    '24',
    '25',
    '228',
    '2929',
    '4187',
    '4779',
];

export const periods = [
    '2025-03-24',
    '2025-03-31',
    '2025-04-07',
    '2025-04-14',
];
