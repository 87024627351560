import React, { useState } from 'react';
import { gql, useFragment } from '@apollo/client';
import classNames from 'classnames';
import { VideoModal } from 'global/compoents/VideoModal/VideoModal';
import css from './marchDetails.module.scss';
import '../../dishDetailsWithCustom.scss';

import boxCard1 from './img/boxCard1.s.jpg';
import boxCard2 from './img/boxCard2.s.jpg';
import boxCard3 from './img/boxCard3.s.jpg';

import videoSliderBtn from './img/videoSliderButton.img.svg';

const video = {
    id: 'marchgifts',
    src: 'https://rutube.ru/play/embed/b403455afe71eb59ed24e33ad6b3ff17/?p=WYqyJ_j6SMNRSCUntd6P1w',
    bg: require('./img/marchgiftsVideo.jpg'),
};

const MarchInfo = () => (
    <div styleName="new-details-card-info romantic">
        <div styleName="new-details-card-info-line">
            <div styleName="new-details-card-info-line-one">Порция</div>
            <div styleName="new-details-card-info-line-two basket-with--dots" />
            <div styleName="new-details-card-info-line-three">
                <span styleName="new-details-card-info-digit-value">3</span>
                {' '}
                <span styleName="new-details-card-info-text">блюда на двоих</span>
            </div>
        </div>
        <div styleName="new-details-card-info-line">
            <div styleName="new-details-card-info-line-one">Приготовить</div>
            <div styleName="new-details-card-info-line-two basket-with--dots" />
            <div styleName="new-details-card-info-line-three">
                <span styleName="new-details-card-info-text">на</span>
                {' '}
                <span styleName="new-details-card-info-digit-value">1-3</span>
                {' '}
                <span styleName="new-details-card-info-text">день</span>
            </div>
        </div>
    </div>
);

const MarchSetDescription = ({ openVideo }) => {
    const cardWrapperClasses = classNames({
        [css.descriptionCardWrapper]: true,
        [css.marchgiftsCard]: true,
    });
    return (
        <div className={css.marchgiftsSetList}>
            <h2 className={css.mainCardTitle}>
                Что внутри
                <br />
                релакс-бокса
            </h2>
            <img className={css.descriptionCardImageMobile} src={boxCard1} alt="description card" />
            <div className={`${css.cardContent} ${css.cardContentMobile}`}>
                <div className={css.cardContentList}>
                    Огурцы, масло, соль и травы –
                    <br />
                    сегодня эти продукты не для салата,
                    <br />
                    а для лица, тела и ванны! Чтобы
                    <br />
                    не стоять у плиты, а сиять у зеркала
                </div>
            </div>
            <img className={css.descriptionCardImageMobile} src={boxCard2} alt="description card" />
            <div className={`${css.cardContent} ${css.cardContentMobile}`}>
                <div className={css.cardContentList}>
                    Всё для заботы о себе.
                    <br />
                    Рецепт: закрыться с табличкой,
                    <br />
                    как в отеле, зажечь свечу, надеть
                    <br />
                    шёлковую маску – и отдыхать
                </div>
            </div>
            <div className={cardWrapperClasses} data-item="4233" onClick={openVideo}>
                <img className={css.descriptionCardImageMobile} src={boxCard3} alt="description card" />
                <div className={css.videoSliderCardButton}>
                    <img src={videoSliderBtn} alt="open video button" />
                </div>
            </div>
            <div className={`${css.cardContent} ${css.cardContentMobile}`}>
                <div className={css.cardContentList}>
                    Набор в красивой упаковке, чтобы
                    <br />
                    подарить его той, о ком хотите
                    <br />
                    позаботиться (или себе!)
                </div>
            </div>
        </div>
    );
};

export const MarchDetails = () => {
    const [currentVideo, setCurrentVideo] = useState(null);
    const { data } = useFragment({
        fragment: gql`fragment DISH_TITLE on menuDish {
            title
            caption
        }`,
        from: {
            __ref: 'menuDish:4875',
        },
    });

    const openVideo = () => {
        setCurrentVideo(video);
    };

    const title = data?.title;
    const caption = data?.caption;
    return (
        <div className={css.marchDetailsRoot}>
            <div className={css.marchDishTitle}>
                {title}
                <br />
                {caption}
            </div>
            <div className={css.marchSetList}>
                <MarchSetDescription openVideo={openVideo} />
            </div>
            {currentVideo && (
                <VideoModal
                    video={currentVideo}
                    handleClose={() => setCurrentVideo(null)}
                    isSpa
                />
            )}
        </div>
    );
};
