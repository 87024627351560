export const BR1173EventsRecord = [
    {
        eventName: 'Video_Click',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName, sourceName } = data;
            return {
                ...data,
                source_name: sourceName,
                event: eventName,
            };
        },
    },
    /**
     * @event 'Romantic_Scroll'
     */
    {
        eventName: 'Romantic_Scroll',
        description: '',
        meta: [],
        scope: [],
        check() {
            return true;
        },
        prepare(data) {
            const { eventName, headerName } = data;
            return {
                ...data,
                event: eventName,
                header_name: headerName,
            };
        },
    },
];
