import {
    useState, useCallback, useMemo,
} from 'react';
import { useQuery } from '@apollo/client';
import { selectedPeriodVar } from 'app/apollo/reaction';

import {
    ReplacePopularCustomSetsEnum,
    TagsContentForABDialog,
    customizationTagsThrowingOutFromABTest,
} from 'app/views/Basket/enums/basket';
import { searchForCustomizationContradiction, findMostImportantContradictionTag } from '../utils/customizationContradictionUtils';

import CART_DATA_FOR_CONTRADICTION_QUERY from './graphql/cartDataForContradictionQuery.graphql';
import USER_CUSTOMIZATION_TAGS from './graphql/userCustomizationTagsQuery.graphql';


export const useCustomContradictionCheck = () => {
    const cartData = useQuery(CART_DATA_FOR_CONTRADICTION_QUERY, {
        variables: {
            period: selectedPeriodVar(),
        },
    }) || {};
    const userData = useQuery(USER_CUSTOMIZATION_TAGS, {
        variables: {
            period: selectedPeriodVar(),
        },
    }) || {};
    const cart = cartData?.data?.cart;
    const typeOfSet = cart?.typeOfSet;
    const userCustomizationTags = userData?.data?.user?.customizationTags;

    const [dialogIngridient, setDialogIngridient] = useState(null);
    const [isCustomizationDialogShown, setIsCustomizationDialogShown] = useState(false);

    const hasOtherTagsInAccount = useMemo(
        () => !userCustomizationTags?.every((tag) => customizationTagsThrowingOutFromABTest.includes(Number(tag))),
        [userCustomizationTags],
    );

    const checkIsСontraditionSearchNeeded = useCallback(() => {
        const isСontraditionSearchNeeded = Boolean(
            ReplacePopularCustomSetsEnum[typeOfSet] !== undefined
            && userCustomizationTags?.length
            && !hasOtherTagsInAccount,
        );
        return isСontraditionSearchNeeded;
    }, [typeOfSet, userCustomizationTags, hasOtherTagsInAccount]);

    const checkForCustomizationContradiction = useCallback(() => {
        const isСontraditionSearchNeeded = checkIsСontraditionSearchNeeded();

        if (!isСontraditionSearchNeeded) {
            sessionStorage.removeItem('with_customization');
            return;
        }

        const missingTags = searchForCustomizationContradiction(cart, userCustomizationTags);
        const hasCustomizationContradiction = Boolean(missingTags?.length);

        const mostImportantContradictionTag = findMostImportantContradictionTag(missingTags);
        const tagFromEnumExists = mostImportantContradictionTag !== null;

        if (hasCustomizationContradiction && (mostImportantContradictionTag in TagsContentForABDialog)) {
            setDialogIngridient(TagsContentForABDialog[mostImportantContradictionTag]);
        }

        const isShowContradictionDialogNeeded = Boolean(mostImportantContradictionTag) && tagFromEnumExists;

        if (isShowContradictionDialogNeeded) {
            setIsCustomizationDialogShown(true);
            return;
        }
        sessionStorage.removeItem('with_customization');
    }, [cart, userCustomizationTags, checkIsСontraditionSearchNeeded]);

    return {
        isCustomizationDialogShown,
        dialogIngridient,
        setIsCustomizationDialogShown,
        checkForCustomizationContradiction,
        checkIsСontraditionSearchNeeded,
    };
};
