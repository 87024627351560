import React, { FC } from 'react';
import { RomanticDetails } from '../RomanticDetails';
import { HalloweenDetails } from '../HalloweenDetails';
import { MarchDetails } from '../MarchDetails';
import { MiragoshaDetails } from '../MiragoshaDetails/MiragoshaDetails';
import { EasterDetails } from '../EasterDetails';

type BoxesComponents = 'romantic' | 'halloween' | 'march' | 'miragosha' | 'easterbox'

interface IBoxesDetailsProps {
    id: BoxesComponents
}

const BoxesDetailsMap: Record<BoxesComponents, FC> = {
    romantic: RomanticDetails,
    halloween: HalloweenDetails,
    march: MarchDetails,
    miragosha: MiragoshaDetails,
    easterbox: EasterDetails,
};

export const BoxesDetails: FC<IBoxesDetailsProps> = ({ id }: IBoxesDetailsProps) => {
    const CurrentComponent: any = BoxesDetailsMap[id];
    return <CurrentComponent id={id} />;
};
