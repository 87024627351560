export const TYPE_CHECKOUT_LOADING = 'TYPE_CHECKOUT_LOADING';
export const TYPE_CHECKOUT_STEP_BY_STEP = 'TYPE_CHECKOUT_STEP_BY_STEP';
export const TYPE_CHECKOUT_SINGLE_STEP = 'TYPE_CHECKOUT_SINGLE_STEP';

export const RENDER_CONTEXT_SINGLE_STEP = 'single-step-checkout';
export const RENDER_CONTEXT_STEP_BY_STEP = 'step-by-step-checkout';

export const STEP_PHONE = 'STEP_PHONE';
export const STEP_ADDRESS = 'STEP_ADDRESS';
export const STEP_DATETIME = 'STEP_DATETIME';
export const STEP_PAYMENT = 'STEP_PAYMENT';
export const STEP_CODE = 'STEP_CODE';


export const EVENTS = {
    [STEP_CODE]: {
        open: 'Checkout_Start_Phone',
        openCode: 'Checkout_Start_Code',
        close: 'Checkout_Close_Phone',
        closeCode: 'Checkout_Close_Code',
        submitCode: 'Submit_Validation_Code',
        backFromEditing: 'Checkout_Back_From_Editing_Phone',
    },
    [STEP_PHONE]: {
        open: 'Checkout_Start_Phone',
        openCode: 'Checkout_Start_Code',
        close: 'Checkout_Close_Phone',
        closeCode: 'Checkout_Close_Code',
        submitCode: 'Submit_Validation_Code',
        backFromEditing: 'Checkout_Back_From_Editing_Phone',
    },
    [STEP_ADDRESS]: {
        open: 'Checkout_Start_Address',
        close: 'Checkout_Close_Address',
        back: 'Checkout_Stepback_Address',
        backFromEditing: 'Checkout_Back_From_Editing_Address',
    },
    [STEP_DATETIME]: {
        open: 'Checkout_Start_Date',
        close: 'Checkout_Close_Date',
        back: 'Checkout_Stepback_Date',
        backFromEditing: 'Checkout_Back_From_Editing_Date',
    },
    [STEP_PAYMENT]: {
        open: 'Checkout_Start_Confirmation',
        close: 'Checkout_Close_Confirmation',
        back: 'Checkout_Stepback_Confirmation',
        backFromEditing: 'Checkout_Back_From_Editing_Confirmation',
    },
};
