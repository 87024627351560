/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';

import { DateSelectTag } from './BR1101/DateSelectTag';
import { CitySelectTag } from './BR1101/CitySelectTag';
import './header-tags.scss';


const HeaderTags = () => (
    <div styleName="headerTagsWrapper">
        <CitySelectTag />
        <DateSelectTag />
    </div>
);

export default React.memo(HeaderTags);


/* DEPRECATED */

// const isDeliveryMenu = (location) => location?.pathname.includes('/app/delivery');

/* В макетах одна цифра */
// const convertToTwoChars = num => String(num).padStart(2, '0');
// const convertToTwoChars = (num) => num;

// const isTagVisible = (tagEl) => {
//     const { innerWidth } = window;
//     const { left, width } = tagEl.getBoundingClientRect();

//     return innerWidth > (left + width);
// };

// const scrollTagToLeft = async (tagsContainerEl, liEl, tagScrollingAreaEl) => {
//     await Velocity(
//         liEl,
//         'scroll',
//         {
//             axis: 'x',
//             duration: 600,
//             container: tagsContainerEl,
//             offset: tagScrollingAreaEl.offsetLeft,
//         },
//     );
// };

// HeaderTagWrapper.propTypes = {
//     allDishes: arrayOf(shape(menuDishType)).isRequired,
//     basketDishes: arrayOf(shape(menuDishType)).isRequired,
//     selectedCategoryId: string,
//     updateBilling: func.isRequired,
//     addItemsToCartByPeriod: func.isRequired,
//     basketQuery: shape({
//         refetch: func,
//     }).isRequired,
//     location: shape({
//         pathname: string.isRequired,
//     }).isRequired,
// };

// const TagsLineFactory = (props) => {
//     const { id } = props;

//     if (id === 'citySelectTag') {
//         return (
//             <HeaderCityTag id={id} />
//         );
//     }

//     /**
//      * @description выбор даты доставки
//      */
//     if (id === 'deliveryDateTag') {
//         const {
//             deliveryTagProps: {
//                 title,
//                 disabled,
//                 onClick,
//                 onSelectPeriod,
//                 isSelectOpened,
//                 isFilterPeriodSelected,
//             },
//         } = props;
//         return (
//             <UIDeliveryTag
//                 id={id}
//                 title={title}
//                 disabled={disabled}
//                 onClick={onClick}
//                 onSelectPeriod={onSelectPeriod}
//                 isSelectOpened={isSelectOpened}
//                 isFilterPeriodSelected={isFilterPeriodSelected}
//             />
//         );
//     }

//     /**
//      * @description tag из menuSidebar
//      */
//     const {
//         filterTagProps: {
//             text,
//             isSelected,
//             disabled,
//             onChange,
//             tagRef,
//         },
//     } = props;

//     return (
//         <UITag
//             id={id}
//             text={text}
//             isSelected={isSelected}
//             disabled={disabled}
//             onChange={onChange}
//             tagRef={tagRef}
//         />
//     );
// };


// function HeaderTagWrapper(props) {
//     const {
//         isDeliveryDateTag,
//         isSelected,
//         isSelectOpened,
//         isFilterPeriodSelected,
//         canEditTags,

//         handleSetPeriod,
//         toggleSelect,
//         allDishes,
//         selectedTags,
//         basketDishes,

//         selectedCategoryId,
//         deliveryDateTag,
//         tag,
//         listContainerRef,
//     } = props;

//     const { locale } = useContext(LocaleContext);

//     const tagRef = useRef();
//     const tagScrollingAreaRef = useRef();

//     const menuDispatch = useContext(MenuDispatchContext);

//     const title = locales[locale][tag.id] || tag.title;

//     const onChange = async () => {
//         const tagEl = tagRef.current;

//         if (!isTagVisible(tagEl)) {
//             const tagsContainerEl = listContainerRef.current;
//             const liEl = tagEl.closest('li');
//             const tagScrollingAreaEl = tagScrollingAreaRef.current;

//             scrollTagToLeft(tagsContainerEl, liEl, tagScrollingAreaEl);
//         }
//         let nextTags = [];

//         if (isSelected) {
//             deleteTagReaction({
//                 tagId: tag.id,
//                 isUserChangesTags: true,
//             });
//             nextTags = selectedTags.filter((t) => t.id !== tag.id);
//         } else {
//             appendTagReaction({
//                 id: tag.id,
//                 isUserChangesTags: true,
//             });
//             nextTags = [...selectedTags, tag.id];
//         }

//         analyticService.push({
//             eventName: 'Change_Filter_Item',
//             itemKey: 'headerTag',
//             action: isSelected ? 'off' : 'on',
//             tagTitle: tag.title,
//         });

//         setTimeout(() => {
//             const categories = CATEGORIES_ORDER.map((id) => ({ id }));
//             const c = categories.find((cat) => cat.id === selectedCategoryId);
//             const count = getCategoryCount({
//                 id: c.id,
//                 menuDishes: allDishes,
//                 selectedTagIds: nextTags,
//                 basketDishes,
//             });

//             if (count === 0) {
//                 menuDispatch({
//                     type: 'scrollTo',
//                     payload: { target: FIRST_CATEGORY },
//                 });
//                 return;
//             }

//             menuDispatch({
//                 type: 'scrollTo',
//                 payload: { target: CATEGORY, value: selectedCategoryId },
//             });
//         }, 600);
//     };

//     const onToggleSelectHandler = () => {
//         if (!isSelectOpened) {
//             analyticService.push({
//                 eventName: 'Change_Filter_Item',
//                 itemKey: 'openWeek',
//             });
//         }

//         toggleSelect();
//     };

//     const itemClasses = classNames({
//         'header-tags__list-item': true,
//         'delivery-tag': isDeliveryDateTag,
//         'simple-tag': !isDeliveryDateTag,
//         'city-select-tag': tag.id === 'citySelectTag',
//     });

//     const deliveryTagProps = {
//         id: deliveryDateTag.id,
//         title: deliveryDateTag.title,
//         disabled: deliveryDateTag.disabled,
//         onClick: onToggleSelectHandler,
//         onSelectPeriod: handleSetPeriod,
//         isSelectOpened,
//         isFilterPeriodSelected,
//     };

//     const filterTagProps = {
//         id: tag.id,
//         text: title,
//         isSelected,
//         disabled: (tag.count === 0) || !canEditTags,
//         onChange,
//         tagRef,
//     };

//     return (
//         <li
//             styleName={itemClasses}
//             key={tag.id}
//             data-name={tag.title}
//         >
//             <div
//                 styleName="header-tags__list-item-scrolling-area"
//                 ref={tagScrollingAreaRef}
//             />

//             <TagsLineFactory
//                 deliveryTagProps={deliveryTagProps}
//                 filterTagProps={filterTagProps}
//                 id={tag.id}
//             />
//         </li>
//     );
// }

// HeaderTagWrapper.propTypes = {
//     allDishes: arrayOf(shape(menuDishType)).isRequired,
//     basketDishes: arrayOf(shape(menuDishType)).isRequired,
//     selectedTags: arrayOf(string).isRequired,
//     handleSetPeriod: func.isRequired,
//     toggleSelect: func.isRequired,
//     selectedCategoryId: string.isRequired,
//     canEditTags: bool.isRequired,
//     isDeliveryDateTag: bool.isRequired,
//     isFilterPeriodSelected: bool.isRequired,
//     isSelectOpened: bool.isRequired,
//     isSelected: bool.isRequired,
//     deliveryDateTag: shape({
//         disabled: bool,
//         id: string,
//         title: string,
//     }).isRequired,
//     tag: shape({
//         id: string,
//         title: string,
//         categoryId: number,
//         categoryName: string,
//         __typename: string,
//     }).isRequired,
//     listContainerRef: shape({
//         current: shape({}),
//     }).isRequired,
// };

// function HeaderTags({
//     basketQuery,
//     allDishes,
//     basketDishes,
//     selectedCategoryId = null,
//     location,
//     updateBilling,
// }) {
//     const [addItemsToCartByPeriod] = useMutation(ADD_ITEMS_TO_CART_BY_PERIOD);
//     const { locale } = useContext(LocaleContext);

//     const {
//         state: {
//             selectedDate,
//             filterPeriod,
//             isSelectedDateInEditableRange,
//             prevDateFilterStatus,
//         },
//     } = useContext(menuDatesState);

//     const { filterQuery: { menuFilter: { periods } } } = useContext(FiltersContext);
//     const { pushDialogOverlay, closeLastOverlays } = useContext(OverlayContextDispatch);

//     const {
//         selectedTags,
//         selectedPeriod,
//         selectedCategories,
//         prevSelectedPeriods,
//     } = useSelectedFilters();

//     const listContainerRef = useRef();
//     const listRootNodeRef = useRef();


//     const [isSelectOpened, setSelectOpened] = useState(false);

//     const toggleSelect = () => {
//         setSelectOpened(!isSelectOpened);
//     };

//     const { weekDayShortName } = getDateData(selectedDate, locale);

//     const isFilterPeriodSelected = filterPeriod === selectedPeriod;

//     const period = periods.find((p) => p.start === selectedPeriod);

//     const {
//         start: selectedPeriodStart,
//         end: selectedPeriodEndRaw,
//         tags,
//     } = period;

//     const selectedPeriodEnd = selectedPeriodStart === '2022-12-26' ? '2022-12-30' : selectedPeriodEndRaw;

//     const {
//         date: selectedPeriodStartDate,
//         monthShortNameForDate: selectedPeriodStartMonthNameForDate,
//     } = getDateData(selectedPeriodStart, locale);
//     const {
//         date: selectedPeriodEndDate,
//         monthShortNameForDate: selectedPeriodEndMonthNameForDate,
//     } = getDateData(selectedPeriodEnd, locale);

//     const selectedPeriodDates = {
//         startDay: convertToTwoChars(selectedPeriodStartDate),
//         startMonth: selectedPeriodStartMonthNameForDate === selectedPeriodEndMonthNameForDate ? '' : selectedPeriodStartMonthNameForDate,
//         endDay: convertToTwoChars(selectedPeriodEndDate),
//         endMonth: selectedPeriodEndMonthNameForDate,
//     };

//     const deliveryTexts = {
//         en: 'Delivery',
//         ru: 'Доставка',
//     };

//     const deliveryDateTagTitle = isFilterPeriodSelected
//         ? [deliveryTexts[locale], weekDayShortName].join(' ')
//         : [
//             deliveryTexts[locale],
//             selectedPeriodDates.startDay,
//             selectedPeriodDates.startMonth,
//             '—', // это какое-то тире, а не дефис!
//             selectedPeriodDates.endDay,
//             selectedPeriodDates.endMonth,
//         ].join(' ');

//     const deliveryDateTag = {
//         id: DELIVERY_DATE_TAG,
//         title: period.title ? period.title[locale] : deliveryDateTagTitle,
//         disabled: false,
//     };

//     const citySelectTag = {
//         id: 'citySelectTag',
//         title: '',
//         disabled: false,
//     };

//     const allTags = [citySelectTag, deliveryDateTag, ...tags];

//     const filteredTags = allTags.filter((tag) => {
//         if (tag.id === PREMIUM_FILTER_ID) {
//             return IS_PREMIUM_FILTER_ENABLED;
//         }
//         if (tag.id === NY_DISHES) {
//             return false;
//         }
//         return true;
//     });

//     /*
//         Сортировка тегов в порядке по задаче FRNT-1993
//     */
//     const sortedTags = sortTags(filteredTags)
//         .map((tag) => {
//             const extendedTag = extendTagByCount(tag, allDishes, selectedCategories, selectedTags, basketDishes);
//             if (extendedTag.id === FOR_CHILDREN_FILTER) {
//                 return {
//                     ...extendedTag,
//                     title: FOR_CHILDREN_FILTER_TITLE,
//                 };
//             }
//             if (extendedTag.id === PREMIUM_FILTER_ID) {
//                 return {
//                     ...extendedTag,
//                     title: PREMIUM_FILTER_TITLE,
//                 };
//             }
//             // if (extendedTag.id === VEGAN) {
//             //     return {
//             //         ...extendedTag,
//             //         title: 'Пост',
//             //     };
//             // }
//             return extendedTag;
//         });

//     const callSetPeriodMutations = async (start, isNeededClearBasket) => {
//         pickPeriodReaction({ periodStart: start });

//         analyticService.push({
//             eventName: 'Change_Filter_Item',
//             itemKey: 'period',
//             periodIndex: periods.findIndex((p) => p.start === start),
//         });

//         try {
//             await updateBilling();

//             if (!prevSelectedPeriods.includes(start) && !isNeededClearBasket) {
//                 await addItemsToCartByPeriod({
//                     variables: {
//                         period: start,
//                         fillByPeriod: selectedPeriod,
//                         tags: selectedTags,
//                     },
//                 });
//             }

//             await basketQuery.refetch({ clear: Boolean(isNeededClearBasket) });
//             await basketQuery.refetch({ clear: false });
//         } catch (e) {
//             Raven.captureException(e);
//         }
//     };

//     const handleSetPeriod = async (start) => {
//         // const currentPeriod = periods.find((p) => p.start === selectedPeriod);
//         const nextPeriod = periods.find((p) => p.start === start);

//         const isBasketEmpty = basketQuery.cart.sections.length === 0;
//         // const isNeededClearBasket = nextPeriod.clearAfterSwitchFrom === currentPeriod.start;
//         const isNeededClearBasket = nextPeriod.clearAfterSwitchFrom;

//         if (isNeededClearBasket && !isBasketEmpty) {
//             const dialogData = {
//                 ...clearAfterSwitchDialogTexts[locale],
//                 onConfirm: async () => {
//                     await callSetPeriodMutations(start, isNeededClearBasket);
//                     closeLastOverlays();
//                 },
//                 notifyOnly: false,
//                 oneRowButtons: true,
//             };

//             pushDialogOverlay('clear_basket_dialog_header_tags', dialogData);
//         } else {
//             await callSetPeriodMutations(start, isNeededClearBasket);
//         }
//     };

//     const canEditTags = isFilterPeriodSelected ? isSelectedDateInEditableRange : true;

//     /**
//      * @description Эффект сбрасывает выбранные фильтры в url для недоступных дат доставки (x+1, x+2)
//     */
//     useEffect(() => {
//         if (selectedTags.length && !canEditTags && +prevDateFilterStatus < +THREE_DAYS_IN_UTC_FORMAT) {
//             resetSelectedFiltersReaction();
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [selectedTags, selectedDate, canEditTags, prevDateFilterStatus]);


//     /**
//      * @scope IntersectionObserver instance
//      */
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     const defineVisibleTagNodes = (entries) => {
//         const TagInViewport = entries.filter((e) => e.isIntersecting);
//         if (TagInViewport.length === 1) {
//             const nextResult = TagInViewport.map((e) => e.target.dataset.name);
//             analyticService.push({ eventName: 'Observe_Tags_In_Viewport', nextResult });
//         }
//     };
//     /**
//      * @description Эффект отслеживает теги во viewport и отдает данные в аналитику
//     */
//     useEffect(() => {
//         const observerOne = new IntersectionObserver((entries) => defineVisibleTagNodes(entries), {
//             root: listContainerRef.current,
//             rootMargin: '20px',
//             threshold: 1,
//         });
//         [...listRootNodeRef.current.children].forEach((node) => observerOne.observe(node));
//     }, []);

//     const rootClasses = classNames({
//         'header-tags__root': true,
//     });

//     return (
//         <div styleName={rootClasses}>
//             <div styleName="header-tags__list-container" ref={listContainerRef}>
//                 <ul
//                     styleName="header-tags__list"
//                     ref={listRootNodeRef}
//                 >
//                     {sortedTags.map((tag) => {
//                         const isDeliveryDateTag = tag.id === DELIVERY_DATE_TAG;
//                         const isHiddenItem = isDeliveryDateTag && isDeliveryMenu(location);

//                         if (isHiddenItem) return null;

//                         return (
//                             <HeaderTagWrapper
//                                 key={tag.id}
//                                 isDeliveryDateTag={isDeliveryDateTag}
//                                 isSelected={selectedTags.includes(tag.id)}
//                                 isSelectOpened={isSelectOpened}
//                                 isFilterPeriodSelected={isFilterPeriodSelected}
//                                 canEditTags={canEditTags}
//                                 handleSetPeriod={handleSetPeriod}
//                                 toggleSelect={toggleSelect}
//                                 allDishes={allDishes}
//                                 selectedTags={selectedTags}
//                                 basketDishes={basketDishes}
//                                 selectedCategoryId={selectedCategoryId}
//                                 deliveryDateTag={deliveryDateTag}
//                                 tag={tag}
//                                 listContainerRef={listContainerRef}
//                             />
//                         );
//                     })}
//                 </ul>
//             </div>
//         </div>
//     );
// }
