import { isLoadingBasket, isBasketEmpty } from '../checkers';

/**
 * @description Определение пустой или еще загружающаейся корзины
 */
export const checkPrimarySign = (props, _set, _get) => {
    const { preparedAreas } = _get();
    switch (true) {
        case (isLoadingBasket(props)):
            _set({ type: 'loading_basket', needDeepCheck: false, preparedAreas });
            break;
        case (isBasketEmpty(props)):
            _set({ type: 'empty_basket', needDeepCheck: false, preparedAreas });
            break;
        default:
            _set({ type: 'not_empty_basket', needDeepCheck: false, preparedAreas });
            break;
    }
};
