/**
* TODO: частичная копипаста Auth
*/

import React, { useEffect, useMemo } from 'react';
// import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import ScrollLock from 'react-scrolllock';
import queryString from 'query-string';
import Raven from 'raven-js';
import compose from 'lodash/flowRight';
import { graphql } from '@apollo/client/react/hoc';
// import { Helmet } from 'react-helmet';
import { analyticService } from 'global/services';

import BILLING_MUTATION from 'app/graphql/network/billing';

// import connect from 'app/connect';
import { PHONE_VERIFICATION, AUTH_BY_PHONE, AUTH_BY_HASH } from 'app/graphql/network/auth/mutations';

import { withAuthContext } from 'app/containers/AuthContainer';

// import { isIOS, isIOS11 } from 'app/utils/browser';
// import { getCachedInvoiceData } from 'app/utils/invoice';
import {
    normalizePhone, formatPhone, getDigitsFromPhone,
    chachedPhoneAdapder,
} from 'app/utils/phone';
import { delay } from 'app/utils/common';

import EnterPhone from 'app/components/Authorization/EnterPhone';
import Confirm from 'app/components/Authorization/Confirm';

// import styles from './auth.scss';
import './auth-checkout.scss';
import { useMutation } from '@apollo/client';
import { isAsNewbieAuthedVar, selectedPeriodVar } from 'app/apollo/reaction';
import { onError } from '@apollo/client/link/error';
import { usePincodeValidation } from './usePincodeValidation';

const IN_PROCESS = 'in process';
// const SUCCESS = 'success';
const FAILED = 'failed';

const ROOT_CONTAINER_ID = 'mobile-auth';

// const setBodyStylesForSafari = () => {
//     if (isIOS && isIOS11) {
//         document.body.classList.add(styles.iosBugFixCaret);
//     }
// };

// const removeBodyStylesForSafari = () => document.body.classList.remove(styles.iosBugFixCaret);

/**
 * @description Отпровляет данные об ошибке в sentry
 * @param {Object} env дополнительные данные об ошибке
 * @returns onError gql callback
 */
const reportError = (env) => (error) => {
    const { title, payload = null } = env;
    Raven.captureMessage(title, {
        extra: {
            error,
            scope: 'auth.js',
            payload,
        },
        tags: {
            type: 'Auth error',
        },
    });
};

const getSplitedAuthState = (frame, renderContext, authState) => {
    if (renderContext !== 'step-by-step-checkout') return authState;

    if (renderContext === 'step-by-step-checkout' && frame === 'phone') {
        return 'telephone';
    }

    if (renderContext === 'step-by-step-checkout' && frame === 'code') {
        return 'pincode';
    }

    return authState;
};


class Auth extends React.Component {
    pincodeInputRef = null;

    mobileScrollContainerRef = React.createRef();

    constructor(props) {
        super(props);

        const telephone = chachedPhoneAdapder(sessionStorage.getItem('phone')) || '+7';

        this.state = {
            resetInputKey: 0,
            timeoutId: null,
            timerSeconds: 30,
            telephone,
            pincode: '',
            canResendPin: false,
            telephoneValidation: '',
            animation: '',
            hashValidation: '',
            hashValidationState: '',
            pincodeTransition: 'default',
        };
    }

    async componentDidMount() {
        const {
            setAuthState,
            authState: { authState },
        } = this.props;

        const isPhoneEntered = sessionStorage.getItem('phone');

        const { hash, sendCode } = queryString.parse(window.location.search);

        if (hash) {
            this.handleAuthByHash(hash);
        }

        if (isPhoneEntered && sendCode) {
            this.setState({ animation: 'to pincode' });
            await setAuthState({ variables: { value: 'pincode' } });
        }
    }

    componentDidUpdate() {
        const {
            authState: { authState },
        } = this.props;

        if (authState === 'pincode') {
            this.focusPincodeInputRef();
        }
    }

    setPincodeInputRef = (element) => {
        this.pincodeInputRef = element;
    };

    focusPincodeInputRef = () => {
        const { isMobileNavigationOpen } = this.props;

        const codeInput = this.pincodeInputRef;
        const codeInputIsFocused = document.activeElement === codeInput;

        if (codeInput) {
            if (isMobileNavigationOpen) {
                codeInput.blur();
            } else if (!codeInputIsFocused) {
                codeInput.focus();
            }
        }
    };

    handleBackToTelephone = async () => {
        const {
            setAuthState,
            handleGoToFirstStep,
            setPincodeValidation,
        } = this.props;
        await setAuthState({ variables: { value: 'telephone' } });
        setPincodeValidation('default');
        handleGoToFirstStep();
    };

    handleChangeTelephone = (e) => {
        const {
            authState: { authState },
            setAuthState,
            setPincodeValidation,
        } = this.props;

        if (authState === 'authResult') {
            setAuthState({ variables: { value: 'telephone' } });
            this.setState({ pincode: '' });
            setPincodeValidation('default');
        }

        const { target: { value } } = e;

        const normalaizedPhone = normalizePhone(value);
        this.setState({ telephone: normalaizedPhone });

        if (normalaizedPhone.length >= 12) {
            this.setState({ telephoneValidation: 'loading' });
            setTimeout(() => {
                this.setState({ telephoneValidation: 'correct' });
            }, 500);
        } else {
            this.setState({ telephoneValidation: 'wrong' });
        }
    };

    handleChangePincode = (e) => {
        const { target: { value } } = e;
        /**
        * @note prevValue переименован для консистентности. При каждом новом вводе,
        * pincode всегда равен value из прошлой итерации
        */
        const { pincode: prevValue, timeoutId } = this.state;
        const { setPincodeValidation } = this.props;

        /**
        * @description Блок отменяет запрос на сервер если пользователь ввел
        *  неправильные 4 цифры и редактирует pincode.
        * */
        if (prevValue.length === 4) {
            setPincodeValidation('default');
            clearTimeout(timeoutId);
            this.setState({
                timeoutId: null,
            });
        }
        /**
        * @description Блок отрабатывает, когда пользователь ввел 4 цифры пинкода.
        * Запрос уходить на сервер через 600 млс. Это сделано для того, чтобы
        * пользователь мог редактировать ввод если ошибся пинкодом,  что
        * избавляет от лишних запросов на сервер.
        * */
        if (value.length === 4) {
            const nextTimeoutId = setTimeout(() => {
                // NOTE: Для совместимости с легаси
                if (this.pincodeInputRef) {
                    this.pincodeInputRef.blur();
                }
                this.handleAuthByPhone();
            }, 600);
            this.setState({
                timeoutId: nextTimeoutId,
            });
        }

        this.setState({
            pincode: value,
        });
    };

    validatePincode = () => {
        const { pincode } = this.state;
        const { setPincodeValidation } = this.props;
        const numRegex = /\d+/;

        if (!Number(pincode) && numRegex.test(pincode)) {
            setPincodeValidation('short');
        }
    };

    handleSubmitPhone = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        const {
            pushLead,
            sumbitPhoneSource,
            onPhoneSubmitted,
            mutateBilling,
            setAuthState,
            handleOpenNextStep,
        } = this.props;

        const { telephone, gettingPincode } = this.state;

        const phoneOnlyNumbers = getDigitsFromPhone(telephone);

        if (phoneOnlyNumbers.length <= 10 || gettingPincode) return;

        onPhoneSubmitted();

        mutateBilling({
            variables: {
                phone: phoneOnlyNumbers,
            },
            onCompleted: () => {
                sessionStorage.setItem('phone', telephone);
                setAuthState({ variables: { value: 'pincode' } });
                handleOpenNextStep();
                // setAuthState({ variables: { value: 'authResult' } });
            },
        });

        try {
            const resp = await pushLead({
                variables: {
                    phone: phoneOnlyNumbers,
                    location: 'popup',
                },
            });

            const { data: { pushLead: leadData } } = resp;

            analyticService.push({
                eventName: 'Submit_Phone_Number',
                source: sumbitPhoneSource,
                userType: leadData.isNewbie ? 'new' : 'old',
            });
        } catch (error) {
            Raven.captureException(error);
        }
    };

    handleGetPincode = async () => {
        const { telephone, gettingPincode } = this.state;
        const {
            setAuthState,
            mutateSessionSubmitPhone,
        } = this.props;
        const phoneOnlyNumbers = telephone.replace(/\D+/g, '');

        if (phoneOnlyNumbers.length <= 10 || gettingPincode) return;

        this.setState({ animation: 'to pincode', gettingPincode: true });

        mutateSessionSubmitPhone({
            variables: { phone: phoneOnlyNumbers },
            onCompleted: (data) => {
                const { phoneVerification: { interval } } = data;
                this.setState({
                    animation: '',
                    gettingPincode: false,
                    canResendPin: false,
                    timerSeconds: interval,
                });
                setAuthState({ variables: { value: 'pincode' } });
            },
            onError: (error) => {
                this.setState({
                    animation: '',
                    gettingPincode: false,
                });
                reportError(
                    { title: '[Auth]: handleAuthByPhone error', payload: { phone: phoneOnlyNumbers } },
                )(error);
            },
        });
    };

    handleGetAnotherPincode = async () => {
        const { resetInputKey } = this.state;
        const { setPincodeValidation } = this.props;
        this.setState({ resetInputKey: resetInputKey + 1 });
        setPincodeValidation('default');
        this.handleChangePincode({ target: { value: '' } });
        await this.handleGetPincode();
    };

    handleAuthByPhone = async () => {
        const {
            authData: { onUserAuthed, refetchUser },
            handleUserAuthed,
            handleUserEnteredCode,
            mutateSessionSubmitPincode,
            handleOpenNextStep,
            setPincodeValidation,
        } = this.props;

        const { pincode } = this.state;

        setPincodeValidation('loading');

        const authResponse = null;

        mutateSessionSubmitPincode({
            variables: { code: pincode },
            onCompleted: async () => {
                await delay(200);
                handleUserEnteredCode('success');

                try {
                    const userResponse = await refetchUser();
                    setPincodeValidation('unmount');
                    const { data: { user: { phone, email, name } } } = userResponse;
                    analyticService.push({
                        eventName: 'Flocktory_Login',
                        eventParams: {
                            name,
                            email: email || `${phone}@unknown.email`,
                        },
                    });

                    setTimeout(async () => {
                        setPincodeValidation('correct');

                        // TODO: можно объединить в один внешний вызов
                        onUserAuthed(authResponse);
                        handleUserAuthed({ authResponse, userResponse });
                        handleOpenNextStep();
                        isAsNewbieAuthedVar(false);
                    }, 500);
                } catch (error) {
                    Raven.captureException(error);
                }
            },
            onError: (error) => {
                const { message, networkError } = error;
                if (message === 'Phone or code are incorrect') {
                    handleUserEnteredCode('failure');
                    setPincodeValidation('wrong');
                } else {
                    setPincodeValidation('default');
                }
            },
        });
    };

    handleAuthByHash = async (hash) => {
        const {
            authData: { onUserAuthed, refetchUser },
            authByHash,
            pushLead,
        } = this.props;

        this.setState({ hashValidationState: IN_PROCESS });

        let authResponse;

        try {
            const { data } = await authByHash({ variables: { hash } });
            authResponse = data.authByHash;
        } catch (e) {
            this.setState({ hashValidation: 'wrong' });
            this.setState({ hashValidationState: FAILED });
        }

        const {
            data: {
                user: {
                    phone, email, id, name,
                },
            },
        } = await refetchUser();
        this.setState({ hashValidation: 'correct' });

        pushLead({
            variables: {
                phone,
                location: 'popup',
            },
        });

        analyticService.push({ eventName: 'setUserId', userId: id });
        analyticService.push({
            eventName: 'Track_Login',
            isFirstAuth: authResponse.isFirstAuth,
        });
        analyticService.push({
            eventName: 'Flocktory_Login',
            eventParams: {
                name,
                email: email || `${phone}@unknown.email`,
            },
        });

        onUserAuthed(authResponse);
    };

    handleTimerTick = () => {
        this.setState(({ timerSeconds }) => {
            const next = timerSeconds - 1;
            return {
                timerSeconds: next,
                canResendPin: next === 0,
            };
        });
    };

    renderEnterTelephone = (isNeedDisplayPlate) => {
        const {
            renderContext,
            phoneTitle,
            authState: { authState },
            phoneAutoFocus,
            buttonDisabledColor,
            isAuthDataLoading,
        } = this.props;

        const {
            telephone, telephoneValidation, animation, hashValidation,
        } = this.state;
        const phoneOnlyNumbers = telephone.replace(/\D+/g, '');
        const isDisabled = (phoneOnlyNumbers.length <= 10) || isAuthDataLoading;

        let errorText;

        if (hashValidation === 'wrong') {
            errorText = 'Некорректный URL-адрес. Пожалуйста, введите номер телефона';
        }
        if (telephone !== '') {
            errorText = null;
        }

        const hasError = Boolean(errorText);

        const isPhoneConfirmed = authState === 'authResult';

        return (
            <EnterPhone
                renderContext={renderContext}
                title={phoneTitle}
                disabled={isDisabled}
                telephone={telephone}
                telephoneValidation={telephoneValidation}
                animation={animation}
                onSubmit={this.handleSubmitPhone}
                onEnterPhone={this.handleSubmitPhone}
                onChangePhone={this.handleChangeTelephone}
                onBlurPhone={this.handleSubmitPhone}
                needDisplayPlate={isNeedDisplayPlate}
                errorText={errorText}
                hasError={hasError}
                isPhoneConfirmed={isPhoneConfirmed}
                autoFocus={phoneAutoFocus}
                buttonDisabledColor={buttonDisabledColor}
            />
        );
    };

    renderEnterPinCode = (isNeedDisplayPlate) => {
        const {
            renderContext,
            codeErrorText,
            pincodeValidation,
        } = this.props;

        const {
            telephone, pincode, canResendPin, timerSeconds,
            pincodeTransition, resetInputKey,
        } = this.state;

        const phoneOnlyNumbers = telephone.replace(/\D+/g, '');
        const formattedPhone = formatPhone(phoneOnlyNumbers);

        return (
            <Confirm
                renderContext={renderContext}
                title="Введите код"
                resendingText="Отправить еще раз"
                resetInputKey={resetInputKey}
                timerSeconds={timerSeconds}
                onTimerTick={this.handleTimerTick}
                errorText={codeErrorText}
                phone={formattedPhone}
                confirmationCode={pincode}
                canResendPin={canResendPin}
                pincodeValidation={pincodeValidation}
                onChangeCode={this.handleChangePincode}
                onBlurCode={this.validatePincode}
                onClickBack={this.handleGetPincode}
                handleGetAnotherPincode={this.handleGetAnotherPincode}
                onClickChangePhone={this.handleBackToTelephone}
                setCodeInputRef={this.setPincodeInputRef}
                needDisplayPlate={isNeedDisplayPlate}
                pincodeTransition={pincodeTransition}
            />
        );
    };

    renderStatus = {
        telephone: (isNeedDisplayPlate) => this.renderEnterTelephone(isNeedDisplayPlate),
        pincode: (isNeedDisplayPlate) => this.renderEnterPinCode(isNeedDisplayPlate),
        authResult: (isNeedDisplayPlate) => this.renderEnterTelephone(isNeedDisplayPlate),
    };

    render() {
        const {
            authState: { authState },
            frame,
            renderContext,
        } = this.props;

        const splitAuthState = getSplitedAuthState(frame, renderContext, authState);

        // console.log('splitAuthState', splitAuthState);

        const { sendCode } = queryString.parse(window.location.search);
        const { hashValidationState } = this.state;

        const authClasses = classNames({
            'mobile-auth': true,
            // 'header-is-hidden': sendCode,
            [authState]: true,
            blur: hashValidationState === IN_PROCESS,
        });

        return (
            // eslint-disable-next-line
            <div
                styleName={authClasses}
                id={ROOT_CONTAINER_ID}
                ref={this.mobileScrollContainerRef}
            >
                {/* <Helmet>
                    <link rel="canonical" href="https://elementaree.ru/auth/" />
                </Helmet> */}
                {this.renderStatus[splitAuthState](Boolean(sendCode))}
                {/* {this.mobileScrollContainerRef.current && (
                    <ScrollLock
                        touchScrollTarget={this.mobileScrollContainerRef.current}
                    />
                )} */}
            </div>
        );
    }
}

Auth.propTypes = {
    renderContext: PropTypes.string.isRequired,

    authData: PropTypes.shape({
        isAuthed: PropTypes.bool,
        onUserAuthed: PropTypes.func,
        refetchUser: PropTypes.func.isRequired,
    }).isRequired,
    isMobileNavigationOpen: PropTypes.bool,

    pushLead: PropTypes.func.isRequired,

    authByHash: PropTypes.func.isRequired,

    authState: PropTypes.shape({
        authState: PropTypes.string.isRequired,
    }).isRequired,

    userQuery: PropTypes.shape({
        user: PropTypes.shape({
            deliveries: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        refetch: PropTypes.func,
    }).isRequired,
    setAuthState: PropTypes.func.isRequired,

    onPhoneSubmitted: PropTypes.func,

    phoneTitle: PropTypes.string,
    phoneAutoFocus: PropTypes.bool,

    handleUserEnteredCode: PropTypes.func.isRequired,
    handleUserAuthed: PropTypes.func.isRequired,

    sumbitPhoneSource: PropTypes.string.isRequired,
    enteredPhone: PropTypes.string,

    buttonDisabledColor: PropTypes.string,
};

const AuthDAC = (props) => {
    const {
        phoneTitle = 'Войти',
        isMobileNavigationOpen = false,
        phoneAutoFocus = true,
        enteredPhone = '',
        buttonDisabledColor = null,
        onPhoneSubmitted = () => {},
        handleOpenNextStep,
        frame,
    } = props;

    const period = selectedPeriodVar();

    const {
        pincodeValidation,
        setPincodeValidation,
        codeErrorText,
    } = usePincodeValidation();

    /* DA */
    const [mutateBilling,
        { loading: isPhoneMutatationLoading },
    ] = useMutation(BILLING_MUTATION, {
        variables: {
            period,
        },
    });

    const [mutateSessionSubmitPhone,
        { loading: isPhoneMutationLoading },
    ] = useMutation(PHONE_VERIFICATION);

    const [mutateSessionGetPincode, // Мутация получения pincode'а
        { loading: isPincodeMutationLoading },
    ] = useMutation(PHONE_VERIFICATION);

    const [mutateSessionSubmitPincode,
        { loading: isSubmitMutationLoading },
    ] = useMutation(AUTH_BY_PHONE);

    const [mutateSessionSubmitHash,
        { loading: isHashMutationLoading },
    ] = useMutation(AUTH_BY_HASH);

    /* EFFECT */
    useEffect(() => {
        if (frame === 'code') {
            mutateSessionGetPincode();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frame]);

    /* SELECTORS */
    const isAuthDataLoading = useMemo(
        () => (
            isPhoneMutatationLoading
            || isPincodeMutationLoading
            || isSubmitMutationLoading
            || isHashMutationLoading
            || isPhoneMutationLoading
        ),
        [isPhoneMutatationLoading, isPincodeMutationLoading, isSubmitMutationLoading, isHashMutationLoading, isPhoneMutationLoading],
    );

    /* RENDER */
    return (
        <Auth
            {...props}
            phoneTitle={phoneTitle}
            isMobileNavigationOpen={isMobileNavigationOpen}
            phoneAutoFocus={phoneAutoFocus}
            enteredPhone={enteredPhone}
            buttonDisabledColor={buttonDisabledColor}
            onPhoneSubmitted={onPhoneSubmitted}
            mutateBilling={mutateBilling}
            handleOpenNextStep={handleOpenNextStep}
            isAuthDataLoading={isAuthDataLoading}
            mutateSessionSubmitPincode={mutateSessionSubmitPincode}
            mutateSessionSubmitPhone={mutateSessionSubmitPhone}
            mutateSessionSubmitHash={mutateSessionSubmitHash}
            pincodeValidation={pincodeValidation}
            setPincodeValidation={setPincodeValidation}
            codeErrorText={codeErrorText}
        />
    );
};

export default compose(
    withAuthContext,
    /*
        TODO: Подумать над тем как отправлять "событие авторизации" в аналитику,
        в методе onComplited
    */
    graphql(AUTH_BY_HASH,
        {
            name: 'authByHash',
            onCompleted: (data) => {
                analyticService.push({
                    eventName: 'Track_Login',
                    isFirstAuth: data.authByHash.isFirstAuth,
                });
            },
        }),
)(AuthDAC);
