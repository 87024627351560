import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { analyticService } from 'global/services';

import { UIBanner, UIHeading } from 'app/components/ui';
import { isStandaloneApp } from 'app/utils/browser';
import { DishesSelectionContext } from 'app/containers/contexts/dishesSelection.context';
import { AvailableLinksContext } from 'app/containers/contexts/availableLinks.context';
import { useSelectedFilters } from 'app/connect/connectToSelectedFilters';

import { useDishDetails } from 'app/components/MobileDishDetails';
import { getSelectionRecord } from './selections.record';

import './menuSelectionsMobileSlider.scss';


const TARGET_REALM = 'target_realm';
const MENU_REALM = 'menu_realm';
const BASKET_REALM = 'basket_realm';


const analyticsSource = {
    [MENU_REALM]: 'menu',
    [BASKET_REALM]: 'upsell',
    [TARGET_REALM]: 'unknown',
};


const MenuSelectionsTitle = () => (
    <UIHeading level="2.1" styleName="menu-selections-title">
        А мы кое-что
        <br />
        приготовили для вас
    </UIHeading>
);

const MenuSelectionsItemContent = (props) => {
    const { bannerTitle } = props;
    return (
        <div styleName="menu-selections-slider-item--indent-wrapper">
            <p styleName="menu-selections-slider-item__title">{bannerTitle}</p>
        </div>
    );
};
MenuSelectionsItemContent.propTypes = {
    bannerTitle: PropTypes.node.isRequired,
};

export const SelectionsMobileSlider = ({
    realm,
    closeHandler = () => { },
}) => {
    const { selectedPeriod } = useSelectedFilters();
    const availableLinks = useContext(AvailableLinksContext);

    const dishesSelection = useContext(DishesSelectionContext);
    const { openDishDetails } = useDishDetails();

    /* SELECTORS */

    const selectionBanners = getSelectionRecord({
        selectedPeriod,
        availableLinks,
    });

    const openMenuSecetionsSideBar = (event) => {
        event.stopPropagation();

        const { currentTarget: { name, dataset: { id, eventId } } } = event;
        const bannerId = id || eventId;

        const banner = selectionBanners.find((b) => b.id === bannerId);

        analyticService.push({
            eventName: 'Collection_Click',
            banner_name: bannerId,
            source_name: analyticsSource[realm],
        });

        if (banner.type === 'action/close') {
            closeHandler();
        } else {
            dishesSelection.onOpenSelection({ tag: name, targetRealm: realm });
        }
    };

    const handleClickLink = (event) => {
        event.preventDefault();
        const { currentTarget: { name, dataset: { href } } } = event;

        analyticService.push({
            eventName: 'Collection_Click',
            banner_name: name,
            source_name: analyticsSource[realm],
        });

        const isApp = isStandaloneApp();

        if (isApp) {
            window.location.href = href;
            return;
        }

        window.open(href, '_blank');
    };

    const handleOpenDishDetail = (id) => {
        const dishId = id === 'romantic' ? '4331' : null;
        if (dishId) {
            openDishDetails(dishId);
        }
    };

    /*
        Предзагрузка картинок для подборок
        Когда откроется подборка, картинка будет уже скачана в этом эффекте
    */
    useEffect(() => {
        selectionBanners.forEach((data) => {
            const img = new Image();
            img.src = data.preloadImage;
        });
    }, [selectionBanners]);

    return (
        <div
            styleName="menu-selections-slider__list"
            onClick={() => { }}
            role="none"
        >
            {selectionBanners.map((e) => {
                const handlersByTypeMap = {
                    link: handleClickLink,
                    detail: () => handleOpenDishDetail(e.id),
                };
                const handleClick = handlersByTypeMap[e.type] || openMenuSecetionsSideBar;

                return (
                    <button
                        type="button"
                        onClick={handleClick}
                        styleName="menu-selections-slider__list-item"
                        key={e.id}
                        name={e.tag}
                        data-id={e.id}
                        data-href={e.href}
                    >
                        <UIBanner backgroundImage={e.backgroundImagePath}>
                            {e.title && (
                                <MenuSelectionsItemContent bannerTitle={e.title} />
                            )}
                        </UIBanner>
                    </button>
                );
            })}
        </div>
    );
};

SelectionsMobileSlider.propTypes = {
    realm: PropTypes.oneOf([MENU_REALM, BASKET_REALM]).isRequired,
    closeHandler: PropTypes.func,
};


export const MenuSelectionsMobileSlider = (props) => {
    const {
        realm = TARGET_REALM,
    } = props;
    return (
        <>
            <MenuSelectionsTitle />
            <div styleName="menu-selections-slider__root">
                <div styleName="menu-selections-slider__list-container">
                    <SelectionsMobileSlider realm={realm} />
                </div>
            </div>
        </>
    );
};

MenuSelectionsMobileSlider.propTypes = {
    realm: PropTypes.oneOf([MENU_REALM, BASKET_REALM]),
};

