
export const periods = [
    '2025-02-24',
];
export const dishes = [
    '2960',
    '2166',
    '431',
    '899',
    '4371',
];
