import React, {
    FC, useState, useEffect, useCallback, useId, useMemo,
} from 'react';
import {
    useQuery, useMutation, useFragment, gql,
} from '@apollo/client';
import { useCartMutationInBasket } from 'app/views/Basket/hooks';
import { useHistory } from 'react-router';
import { Portal } from 'react-portal';
import BASKET_QUERY from 'app/graphql/network/basket/basketQuery';
import { ADD_ITEM_TO_CART } from 'app/graphql/network/basket/basketMutations';

import { UIToggleSwitch } from 'app/components/ui';

import { isDesktop } from 'app/utils/resolution';
import { DialogStatic } from 'app/components/Dialog/BR1156/DialogStatic';
import { makePrimetiveSection } from 'app/views/Basket/BasketListArea';
import { selectedPeriodVar } from 'app/apollo/reaction';

import css from './GiftPackRow.module.scss';

import GiftPackRowIcon from './img/GiftPackRowIcon.component.svg';

interface IProps {
    realm: 'basket' | 'checkout',
}

export const tableSettingDishId = '4847';

export const GiftPackRow: FC = ({
    realm,
}: IProps) => {
    const searchParams = new URLSearchParams(window.location.search);
    const paymentMethod = searchParams.get('payment');
    const history = useHistory();
    const { isCartUpdating, mutateItemInCart } = useCartMutationInBasket();

    const [isDialogOpenned, setIsDialogOpenned] = useState<boolean>(false);
    const [isDesktopResolution, setIsDesktopResolution] = useState<boolean>(isDesktop());
    // const { locale } = useContext(LocaleContext);
    const labelId = useId();

    const { data } = useFragment({
        fragment: gql`
            fragment TBL_DISH_PRICE on menuDish {
                id
                price
                title
        }`,
        from: {
            __ref: `menuDish:${tableSettingDishId}`,
        },
    }) || { data: {} };

    const { data: { cart: { sections } }, refetch } = useQuery(
        BASKET_QUERY,
        {
            variables: {
                period: selectedPeriodVar(),
            },
        },
    );

    const [addTableSetting] = useMutation(ADD_ITEM_TO_CART, {
        variables: {
            dish_id: tableSettingDishId,
            period: selectedPeriodVar(),
            portions: 1,
        },
    });

    const primetiveSection = useMemo(
        () => makePrimetiveSection(sections),
        [sections],
    );

    const hasTableSettingDishInBasket = useMemo(
        () => primetiveSection
            .some((dishWithType: any) => dishWithType
                .includes(tableSettingDishId)),
        [primetiveSection],
    );

    const [isChecked, setIsChecked] = useState<boolean>(hasTableSettingDishInBasket);

    const toggleTableSettingSwitch = useCallback(
        () => {
            if (hasTableSettingDishInBasket) {
                mutateItemInCart(
                    {
                        dishes: [{
                            dish_id: tableSettingDishId,
                            portions: 0,
                        }],
                    },
                );
                setIsChecked(false);
                return;
            }
            addTableSetting().then(() => {
                setIsChecked(true);
                refetch();
            });
        },
        [
            hasTableSettingDishInBasket,
            addTableSetting,
            mutateItemInCart,
            refetch,
        ],
    );

    const checkDesktopResolution = useCallback(
        () => setIsDesktopResolution(isDesktop()),
        [],
    );

    const onReject = useCallback(
        () => {
            setIsDialogOpenned(false);
            history.push('/menu');
        },
        [history],
    );
    const onConfirm = useCallback(
        () => {
            if (hasTableSettingDishInBasket) {
                setIsDialogOpenned(false);
            } else {
                addTableSetting().then(() => {
                    setIsChecked(true);
                    refetch();
                });
            }
            setIsDialogOpenned(false);
        },
        [
            hasTableSettingDishInBasket,
            refetch, addTableSetting,
        ],
    );

    useEffect(
        () => {
            window.addEventListener(
                'resize',
                checkDesktopResolution,
            );
            return () => {
                window.removeEventListener('resize', checkDesktopResolution);
            };
        },
        [checkDesktopResolution],
    );


    useEffect(
        () => {
            setIsChecked(hasTableSettingDishInBasket);
        },
        [hasTableSettingDishInBasket],
    );

    const price = useMemo(() => {
        const dv = paymentMethod === 'recurrent' ? 10 : 0;
        // eslint-disable-next-line no-unsafe-optional-chaining, no-mixed-operators
        const discount = data?.price / 100 * dv;
        // eslint-disable-next-line no-unsafe-optional-chaining
        return data?.price - discount;
    }, [data?.price, paymentMethod]);

    const confirmText = useMemo(
        () => `Добавить за ${price} ₽`,
        [price],
    );

    return (
        <div className={css.tableSettingRowRoot}>
            <div className={css.tableSettingRowFlexContainer}>
                <div className={css.tableSettingRowContent}>
                    <div className={css.tableSettingRowContentIcon}>
                        <GiftPackRowIcon />
                    </div>
                    <div className={css.tableSettingRowContentText}>
                        Сервировка
                        <br />
                        <span
                            role="none"
                            onClick={() => setIsDialogOpenned(true)}
                            className={css.tableSettingRowTextDecoration}
                        >
                            для свидания
                        </span>
                    </div>
                </div>
                <div className={css.tableSettingRowToggleWrapper}>
                    <div className={css.tableSettingPriceRow}>
                        +
                        {price}
                        {' '}
                        ₽
                    </div>
                    <UIToggleSwitch
                        onChanegHandler={toggleTableSettingSwitch}
                        isChecked={isChecked}
                        id={labelId}
                        isDisabled={isCartUpdating}
                    />
                </div>
                <Portal>
                    <DialogStatic
                        rejectText=""
                        confirmText={confirmText}
                        isOpen={isDialogOpenned}
                        onConfirm={onConfirm}
                        onReject={onReject}
                        textAlign="start"
                        // @ts-ignore
                        responisve={!isDesktopResolution}
                        notifyOnly
                        isProfileLayout
                        isCloseConfirmAction
                        isNewIrresponsiveLayout
                        oneRowButtons
                        buttonsReversed
                    >
                        <>
                            <div className={css.tableSettingModalTitle}>
                                {isDesktopResolution ? (
                                    <>
                                        Всё для романтического вечера
                                    </>
                                ) : (
                                    <>
                                        Всё
                                        <br />
                                        для романтического вечера
                                        {' '}
                                    </>
                                )}
                            </div>
                            <img
                                className={css.tableSettingDialogImage}
                                src={require('./img/romanticPackage.jpg')}
                                alt="table setting"
                            />
                            <ul className={css.tableSettingModalList}>
                                <li>
                                    мини-игра, чтобы узнать друг друга получше
                                </li>
                                <li>
                                    салфетки-сердечки для сервировки
                                </li>
                                <li>
                                    бенгальские огни
                                </li>
                            </ul>
                        </>
                    </DialogStatic>
                </Portal>
            </div>
        </div>
    );
};
