import { useReactiveVar, gql, useMutation } from '@apollo/client';
/* eslint-disable camelcase */
import { promocodeVar, selectedPeriodVar, subscriptionTypeVar } from 'app/apollo/reaction';

/* NETWORK */
import BASKET_QUERY from 'app/graphql/network/basket/basketQuery';
// import { useBillingState } from './useBiilingState';

const MUTATE_BILLING = gql`
    mutation MUTATE_BILLING(
        $promocode: String,
        $period: String!,
        $payment_method: paymentMethod,
        $phone: String,
    ) {
        billing(
            promocode: $promocode,
            period: $period,
            payment_method: $payment_method,
            phone: $phone,
        ) {
            promocode
            payment_method
        }
    }
`;

/**
 * @description Ставит query парамерт payment и promo_code
 */
// const setInitialPromoAndPaymentAsQueryParam = (params: any) => {
//     const searchParams = new URLSearchParams(window.location.search);
//     params.forEach((param: any) => {
//         const [value] = Object.values(param);
//         const [key] = Object.keys(param);
//         // @ts-ignore
//         searchParams.set(key, value);
//     });
//     window.history.replaceState(null, '', `?${searchParams.toString()}`);
// };

export const useBillingMutation = () => {
    const period = useReactiveVar(selectedPeriodVar);
    const promocode = useReactiveVar(promocodeVar);

    const [mutate, { loading: billingLoading }] = useMutation(MUTATE_BILLING, {
        onError: (err) => {
            console.log(err);
            // Raven.captureException(e);
        },
        onCompleted: ({ billing }, option) => {
            // console.log(billing, option);
            // const { payment_method, promocode } = billing;
            // // @ts-expect-error тут есть!
            // const { variables: { phone } } = option;
            // setBillingStateVar({ payment_method, promocode, phone });
            // const type = ['recurrent'].includes(payment_method) ? 'subscription' : 'singlePay';
            // subscriptionTypeVar(type)
            // setInitialPromoAndPaymentAsQueryParam([{ payment: payment_method }, { promo_code: promocode }]);
        },
        variables: {
            period,
        },
        context: {
            message: 'global:mutate:useBillingMutation',
        },
        refetchQueries: [
            {
                query: BASKET_QUERY,
                context: { message: 'global:refetch:useBillingMutation' },
                variables: { period },
            },
        ],
    });

    // const notEqual = (prev: any, next: any) => JSON.stringify(prev) !== JSON.stringify(next);

    // eslint-disable-next-line no-unused-vars
    const mutateBilling = ({ variables }: any, caller?: string) => {
        // // console.log(caller);
        const combained = { period, promocode, ...variables };
        // if (notEqual(combained, state)) {
        // }
        mutate({ variables: { ...combained } });
    };

    return { mutateBilling, billingLoading };
};
