import React, { useCallback, useContext, useMemo } from 'react';
import { abTestDataContext } from 'app/containers/contexts/abTest.context';

import { UIParagraph } from 'app/components/ui';
import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';
import { BonusesPopup } from 'app/components/Bonuses';
import BonusesIcon from 'app/components/Bonuses/components/BonusesNotifier/img/bonuses.component.svg';
import css from '../../../../../components/Bonuses/components/BonusesNotifier/BonusesNotifier.module.scss';


import './basket-check-rows.scss';


export const PayByBonuses = (props) => {
    const { basket } = props;
    const { cashback_new_users } = useContext(abTestDataContext);

    const { pushOverlay } = useContext(OverlayContextDispatch);
    const openBonusPopup = useCallback(() => {
        // @ts-ignore
        pushOverlay({
            id: 'bonuses_tooltip',
            template: 'dialog',
            overlayProps: { zIndex: 31000 },
            children: (
                <BonusesPopup />
            ),
        });
    }, [pushOverlay]);

    const isCashbackTestGroup = useMemo(
        () => (cashback_new_users === 'with_cashback'),
        [cashback_new_users],
    );


    const cashback = useMemo(
        () => {
            if (isCashbackTestGroup) {
                // eslint-disable-next-line react/prop-types
                return basket?.totals?.cashback_amount;
            }
            // eslint-disable-next-line react/prop-types
            return basket?.totals?.referral_amount;
        },
        // eslint-disable-next-line react/prop-types
        [basket?.totals?.referral_amount, basket?.totals?.cashback_amount, isCashbackTestGroup],
    );

    return (
        <button
            type="button"
            styleName="basket-check__row"
        >
            <div>
                <UIParagraph level="4.2" styleName="basket-check__row-text">
                    <span>
                        Оплата
                        {' '}
                        <span
                            styleName="link"
                            onClick={openBonusPopup}
                            role="none"
                        >
                            бонусами
                        </span>
                    </span>
                </UIParagraph>
            </div>
            <div>
                <div className={css.bonusesAmountCont}>
                    <span className={css.bonusesAmount}>
                        {` -${cashback}`}
                    </span>
                    <BonusesIcon className={css.bonusesIconSmall} />
                </div>
            </div>
        </button>
    );
};
