/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { Desktop, Mobile } from 'app/components/Responsive';
import { ingredientTypeTextRecord } from './customIngredientsSelectDishRow.records';
import { DesktopCustomIngredientsSelect } from './Desktop';
import { MobileCustomIngredientsSelect } from './Mobile';
import { ICustomIngredientsSelectDishRowCommonProps } from './CustomIngredientsSelectDishRow.types';


const CustomIngredientsSelectDishRow = (props: ICustomIngredientsSelectDishRowCommonProps) => {
    const {
        id,
        selectedDishId,
        ingredientsList,
        onNextIdSelected, // ex setSelectedDishId
        environmentContext,
        disabled,
        onSubmitHandler,
    } = props;

    const checkIsSelected = (I: string, II: string) => (I === II ? 'selected' : '');

    const client = useApolloClient();

    const { secondDishPart, replaceableIngredient } = client.readFragment({
        id: `menuDish:${selectedDishId}`,
        fragment: gql`
            fragment DISH_CUSTOM_INGREDIENTS_FRAGMENT on menuDish {
                replaceableIngredient,
                secondDishPart,
                parentId,
                category_id,
            }`,
    });

    /* SELECTORS */
    const ingredientTypeText = useMemo(
        // @ts-ignore
        () => ingredientTypeTextRecord[secondDishPart],
        [secondDishPart],
    );

    return (
        <>
            <Desktop>
                {environmentContext !== 'basket' ? (
                    <DesktopCustomIngredientsSelect
                        disabled={disabled}
                        id={id}
                        selectedDishId={selectedDishId}
                        secondDishPart={secondDishPart}
                        ingredientsList={ingredientsList}
                        checkIsSelected={checkIsSelected}
                        onNextIdSelected={onNextIdSelected}
                        ingredientTypeText={ingredientTypeText}
                        replaceableIngredient={replaceableIngredient}
                    />
                ) : (
                    <MobileCustomIngredientsSelect
                        disabled={disabled}
                        id={id}
                        selectedDishId={selectedDishId}
                        secondDishPart={secondDishPart}
                        ingredientsList={ingredientsList}
                        checkIsSelected={checkIsSelected}
                        onNextIdSelected={onNextIdSelected}
                        ingredientTypeText={ingredientTypeText}
                        replaceableIngredient={replaceableIngredient}
                        onSubmitHandler={onSubmitHandler}
                        environmentContext={environmentContext}
                    />
                )}
            </Desktop>
            <Mobile>
                <MobileCustomIngredientsSelect
                    disabled={disabled}
                    id={id}
                    selectedDishId={selectedDishId}
                    secondDishPart={secondDishPart}
                    ingredientsList={ingredientsList}
                    checkIsSelected={checkIsSelected}
                    onNextIdSelected={onNextIdSelected}
                    ingredientTypeText={ingredientTypeText}
                    replaceableIngredient={replaceableIngredient}
                    onSubmitHandler={onSubmitHandler}
                    environmentContext={environmentContext}
                />
            </Mobile>
        </>
    );
};

export { CustomIngredientsSelectDishRow };
