/* eslint-disable camelcase */
/**
 *
 * @param {promocodeConditions} promocodeResponse res Query checkPromocodeConditions
 * @param {string} inputValue
 * @return UIState для UIInput
 *
 * multiple | single | onlyFirst - значенияsingle - это разовыйonlyFirst - это только первый заказ
 * TODO: TODO!
 */

export const validatePromocode = (promocodeConditions, inputValue, locale, defaultLabel, showConfirmedLabel) => {
    const {
        isPromoCodeExists,
        isPromocodeCombineWithAccount,
        isPromocodeCombineWithDiscount,
        isSubscriptionActivated,
        discountDuration,
        promocode_status,
        differenceBetweenPromocodeAndOrder,
        isCertificate,
        isPromoCodeActivated,
        promoCodeApplyingErrorMessage,
    } = promocodeConditions;

    const subscriptionError = !isPromocodeCombineWithDiscount && isSubscriptionActivated;

    const referrerError = Boolean(promoCodeApplyingErrorMessage);

    const durationError = isPromoCodeExists
        && discountDuration === 'onlyFirst'
        && promocode_status === 'inactive'
        && differenceBetweenPromocodeAndOrder <= 0;

    const isCertificateError = isPromoCodeExists
        && isCertificate
        && differenceBetweenPromocodeAndOrder <= 0
        && !isPromoCodeActivated;

    const confirmedLabel = showConfirmedLabel ? defaultLabel : '';

    switch (true) {
        case !inputValue:
            return { uiState: 'default', label: defaultLabel, errorType: '' };
        case Boolean(referrerError):
            return { uiState: 'error', label: promoCodeApplyingErrorMessage, errorType: 'referrerError' };
        case !isPromoCodeExists && isCertificate:
            return { uiState: 'error', label: locale.certificateExistError, errorType: 'notFoundError' };
        case !isPromoCodeExists:
            return { uiState: 'error', label: locale.promocodeError, errorType: 'notFoundError' };
        case subscriptionError:
            return { uiState: 'error', label: locale.promocodeErrorSubscr, errorType: 'subscriptionError' };
        case durationError:
            return { uiState: 'error', label: locale.promocodeErrorSecond, errorType: 'durationError' };
        case !isPromocodeCombineWithAccount:
            return { uiState: 'error', label: locale.promocodeError, errorType: 'notFoundError' };
        case isCertificateError:
            return { uiState: 'error', label: locale.certificateError, errorType: 'certificateError' };
        default:
            return { uiState: 'confirmed', label: confirmedLabel, errorType: '' };
    }
};
