import { metaLabelField } from './fields/metaLabel.field';

const checkIsSoldOout = (id) => {
    try {
        return ['431', '2166', '2960'].includes(id) && window.location.pathname.includes('maslenitsa');
    } catch {
        return false;
    }
};

// [431, 2166, 2960]
export const menuDishPolicies = {
    metaLabel: metaLabelField,
    visibleStatus: {
        read(data, client) {
            const id = client.readField('id');
            const isSoldOut = checkIsSoldOout(id);
            return isSoldOut ? 2 : data;
        },
    },
};
