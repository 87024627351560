import React, { useEffect, useState } from 'react';

type TPincodeValue = 'default' | 'short' | 'loading' | 'correct'
| 'wrong'

type TErrorText = 'Код должен содержать 4 символа' | 'Неверный код' | ''

export function usePincodeValidation() {
    const [
        pincodeValidation,
        setPincodeValidation,
    ] = useState<TPincodeValue>('default');

    const [
        codeErrorText,
        setCodeErrorText,
    ] = useState<TErrorText>('');

    useEffect(
        () => {
            if (pincodeValidation === 'short') {
                setCodeErrorText('Код должен содержать 4 символа');
            } else if (pincodeValidation === 'wrong') {
                setCodeErrorText('Неверный код');
            } else {
                setCodeErrorText('');
            }
        },
        [pincodeValidation],
    );

    return { pincodeValidation, setPincodeValidation, codeErrorText };
}
