/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';

import { selectedPeriodVar } from 'app/apollo/reaction';
import { toUTS } from 'global/utils';

interface IBanner {
    id: string;
    title: string;
    iconPath: string;
    selectionTag: string;
    type: 'selection' | 'link';
}

interface IDynamicBanner extends IBanner {
    start: number
    end: number
}

// Default
const hits = {
    id: 'hits',
    title: 'Хиты вне времени',
    iconPath: 'hits',
    selectionTag: 'hits',
    type: 'selection',
};
const kids = {
    id: 'kids',
    title: 'Детское меню',
    iconPath: 'kids',
    selectionTag: 'kids',
    type: 'selection',
};
const sweet = {
    id: 'sweets',
    title: 'Печем вместе',
    iconPath: 'sweet',
    selectionTag: 'sweets',
    type: 'selection',
};

// Dynamic
const FirstRowList: IDynamicBanner[] = [
    {
        id: '2121',
        title: 'Пост',
        iconPath: 'fast',
        selectionTag: '2121',
        type: 'selection',
        start: toUTS('2025-03-03'),
        end: toUTS('2025-04-14'),
    },
];

const secondRowList: IDynamicBanner[] = [
    // {
    //     id: 'romantic',
    //     title: 'Время для свиданий',
    //     iconPath: 'romantic',
    //     selectionTag: 'romantic',
    //     type: 'link',
    //     href: '/romantic/',
    //     start: toUTS('2025-02-24'),
    //     end: toUTS('2025-03-10'),
    // },
    // {
    //     id: 'march',
    //     title: 'Великие женщины',
    //     iconPath: 'march',
    //     selectionTag: 'march',
    //     type: 'selection',
    //     start: toUTS('2025-03-03'),
    //     end: toUTS('2025-03-10'),
    // },

    // /practicum.yandex
    {
        id: 'practicum',
        title: 'Время для учёбы',
        iconPath: 'YaP',
        selectionTag: 'YaP',
        type: 'selection',
        start: toUTS('2025-03-24'),
        end: toUTS('2025-04-14'),
    },
];
const ThirdRowList: IDynamicBanner[] = [
    {
        id: 'march',
        title: 'Великие женщины',
        iconPath: 'march',
        selectionTag: 'march',
        type: 'selection',
        start: toUTS('2025-03-03'),
        end: toUTS('2025-03-10'),
    },
];

const getBannerByPeriod = (banner: IDynamicBanner, period: string) => {
    const { start, end } = banner;
    return toUTS(period) >= start && toUTS(period) < end;
};

export const useDynamicUpsellSelections = (setName = 'green') => {
    const period = useReactiveVar(selectedPeriodVar);

    const getBanner = (list: any[], regular = {}) => {
        try {
            const sprcific = list.find(
                (item: IDynamicBanner) => getBannerByPeriod(item, period),
            );
            return sprcific || regular;
        } catch (err) {
            console.error(err);
            return regular;
        }
    };

    const candidate = useMemo(
        () => [
            getBanner(FirstRowList, hits), /* 1st row */
            getBanner(secondRowList, kids), /* 2nd row */
            getBanner(ThirdRowList, sweet), /* 3rd row */
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [period, setName],
    );

    return candidate;
};


/*


// const upsellMlstsSelections: ISelection[] = [
    // {
    //     id: 'green',
    //     title: 'Green',
    //     iconPath: 'green',
    //     selectionTag: 'green',
    //     type: 'selection',
    // },
    // {
    //     id: 'romantic',
    //     title: 'Время для свиданий',
    //     iconPath: 'romantic',
    //     selectionTag: 'romantic',
    //     type: 'link',
    //     href: '/romantic/',
    // },
    // {
    //     id: 'sweets',
    //     title: 'Печем вместе',
    //     iconPath: 'sweet',
    //     selectionTag: 'sweets',
    //     type: 'selection',
    // },
    // {
    //     id: 'hits',
    //     title: 'Хиты вне времени',
    //     iconPath: 'hits',
    //     selectionTag: 'hits',
    //     type: 'selection',
    // },
    // {
    //     id: 'kids',
    //     title: 'Детское меню',
    //     iconPath: 'kids',
    //     selectionTag: 'kids',
    //     type: 'selection',
    // },

    // {
    //     id: 'harry',
    //     title: 'Меню из Хогвартса',
    //     iconPath: 'harry',
    //     selectionTag: 'hp',
    //     type: 'selection',
    // },
    // {
    //     id: 'green',
    //     title: 'Green',
    //     iconPath: 'green',
    //     selectionTag: 'green',
    //     type: 'selection',
    // },
    // {
    //     id: 'maxim',
    //     title: 'Вечер с любимыми',
    //     iconPath: 'maxim',
    //     selectionTag: 'maxim',
    //     type: 'selection',
    // },
    // {
    //     id: 'ny',
    //     title: 'Новый год',
    //     iconPath: 'ny',
    //     selectionTag: 'ny',
    //     type: 'link',
    //     href: '/blyuda-dlya-novogodnego-stola?period=2024-12-23',
    // },
    // {
    //     id: 'film',
    //     title: 'Дома, как в кино',
    //     iconPath: 'film',
    //     selectionTag: 'film',
    //     type: 'selection',
    // },
    // {
    //     id: 'dacha',
    //     title: 'На дачу',
    //     iconPath: 'dacha',
    //     selectionTag: 'dacha',
    //     type: 'link',
    //     href: '/dacha',
    // },
// ];


// const upsellMarchgifts = [];

// const upsellFast = [
//     {
//         id: '2121',
//         title: 'Пост',
//         iconPath: 'fast',
//         selectionTag: '2121',
//         type: 'selection',
//     },
//     {
//         id: 'kids',
//         title: 'Детское меню',
//         iconPath: 'kids',
//         selectionTag: 'kids',
//         type: 'selection',
//     },
//     {
//         id: 'sweets',
//         title: 'Печем вместе',
//         iconPath: 'sweet',
//         selectionTag: 'sweets',
//         type: 'selection',
//     },
// ];


// const upsellSelections = useMemo(() => {
//     if (setName === 'maslenitsa') {
//         return upsellMlstsSelections;
//     }

//     if (setName === 'marchgifts') {
//         return upsellMarchgifts;
//     }

//     if (setName === 'march') {
//         return upsellFast;
//     }

//     return upsellDefault;
// }, [setName]);


*/
