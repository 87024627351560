import React from 'react';
import { selectedPeriodVar } from 'app/apollo/reaction';
import { toUTS } from 'global/utils';
import { useReactiveVar } from '@apollo/client';
import { TCurrentSetType } from './components/BasketSetsSelection/BasketSetsSelection';

export const useBigSetBannerByPeriod = (): TCurrentSetType => {
    const period = toUTS(useReactiveVar(selectedPeriodVar));

    if (period >= toUTS('2025-02-24') && period < toUTS('2025-03-03')) {
        return 'maslenitsa';
    }

    if (period >= toUTS('2025-03-03') && period < toUTS('2025-03-10')) {
        return 'marchgifts';
    }

    if (period >= toUTS('2025-03-10') && period < toUTS('2025-03-24')) {
        return 'march';
    }

    if (period >= toUTS('2025-03-31') && period < toUTS('2025-04-21')) {
        return 'easter';
    }

    return 'green';
};
