import { gql } from '@apollo/client';

export const PHONE_VERIFICATION = gql`
    mutation phoneVerification {
        phoneVerification {
            success
            interval
        }
    }
`;

export const AUTH_BY_PHONE = gql`
    mutation authByPhone($code: String!) {
        authByPhone(code: $code) {
            token
            isAuthed
            isFirstAuth
        }
    }
`;

export const AUTH_BY_HASH = gql`
    mutation authByHash($hash: String!) {
        authByHash(hash: $hash) {
            token
            isAuthed
            isFirstAuth
        }
    }
`;
