/* eslint-disable import/order */
import landingPages from 'app/const/links/landingPages';
import infoPages from 'app/const/links/infoPages';
import externalLinks from 'app/const/links/externalLinks';
import spaLinks from './spaLinks';

import { EVERYDAY_SET } from 'app/const/links/everydaySet';
import { FEEDBACK_SET } from 'app/const/links/feedbackSet';
import { PREMIUM_SET, PREMIUM_SET_GREEN_VERSION } from 'app/const/links/premiumSet';
import { OUR_SET } from 'app/const/links/ourSet';
import {
    TRIAL_FIRST_ORDER_SET,
    TRIAL_FIRST_ORDER_SET_SHORTENED,
    TRIAL_FIRST_ORDER_SET_33,
    TINKOFF_TRIAL_SET,
    BEELINE_TRIAL_SET,
    BESPLATNO_TRIAL_SET,
    HARRY_TRIAL_SET,
    MIRAGOSHA_TRIAL_SET,
} from 'app/const/links/trialFirstOrderSet';
import { TRIAL_PREMIUM_SET } from 'app/const/links/trialPremiumSet';

import { SPB_DISHES_PAGE } from './spbDishesPage';

export default {
    ...landingPages,
    ...infoPages,
    ...spaLinks,
    ...externalLinks,

    EVERYDAY_SET,
    FEEDBACK_SET,
    PREMIUM_SET,
    PREMIUM_SET_GREEN_VERSION,
    OUR_SET,

    TRIAL_FIRST_ORDER_SET,
    TRIAL_FIRST_ORDER_SET_SHORTENED,
    TRIAL_FIRST_ORDER_SET_33,
    TRIAL_PREMIUM_SET,
    TINKOFF_TRIAL_SET,
    BEELINE_TRIAL_SET,
    BESPLATNO_TRIAL_SET,
    HARRY_TRIAL_SET,
    MIRAGOSHA_TRIAL_SET,

    SPB_DISHES_PAGE,
};

