import React, { useState, useEffect, useRef } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { Portal } from 'react-portal';
import cn from 'classnames';

import { UIButton } from 'app/components/ui';
import { Mobile } from 'app/components/Responsive';

import closeIcon from './close-acievements.img.svg';
import css from './achievementsBannerBig.module.scss';


type AchievementsBannerBigProps = {
    onClick: () => void,
};

const isSeenSaved = localStorage.getItem('elementaree-achievements-banner') === 'true';

const saveSeenState = () => {
    localStorage.setItem('elementaree-achievements-banner', 'true');
};


export function AchievementsBannerBig({
    onClick,
}: AchievementsBannerBigProps) {
    const [isSeen, setIsSeen] = useState(true);

    const containerRef = useRef(null);

    const handleClose = () => {
        setIsSeen(true);
        saveSeenState();
        clearAllBodyScrollLocks();
    };

    const handleClick = () => {
        handleClose();
        onClick();
    };


    useEffect(() => {
        setIsSeen(isSeenSaved);

        if (!isSeenSaved && containerRef.current) {
            window.scrollTo(0, 0);
            disableBodyScroll(containerRef.current);
        }
    }, []);

    const containerClasses = cn({
        [css.achievementsBannerBig]: true,
        [css.achievementsBannerBigVisible]: !isSeen,
    });

    return (
        <Portal>
            <Mobile>
                <div className={containerClasses} ref={containerRef}>
                    <div className={css.achievementsBannerBackdrop} />
                    <div className={css.achievementsBannerContent}>
                        <button
                            type="button"
                            className={css.achievementsBannerClose}
                            onClick={handleClose}
                        >
                            <img src={closeIcon} alt="close" />
                        </button>
                        <p className={css.achievementsBannerTitle}>
                            Итоги
                            <br />
                            вашего
                            <br />
                            года
                        </p>
                        <p className={css.achievementsBannerText}>
                            Узнайте какой статус присвоил вам Elementaree и&nbsp;поделитесь им с&nbsp;друзьями.
                            А&nbsp;еще внутри вас ждет увлекательная статистика!
                        </p>
                        <div className={css.achievementsBannerButton}>
                            <UIButton onClick={handleClick}>
                                Перейти к итогам года
                            </UIButton>
                        </div>
                    </div>
                </div>
            </Mobile>
        </Portal>
    );
}
