import React, { useContext } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { createSelector } from 'reselect';
import { Transition } from 'react-transition-group';
import { useHistory, withRouter } from 'react-router';

import {
    CATEGORIES_ORDER,
    CATEGORY_MAIN,
    CATEGORY_MOST_INTRESTING,
    CATEGORY_MOST_INTRESTING_TAG_ID,
    CATEGORY_TITLES,

    mostInterestingCategoryTitleForABTest,
} from 'app/const/categories';

import { selectDishes } from 'app/utils/dish';

/* CONTEXTS */
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { MenuDispatchContext } from 'app/containers/contexts/menu.context';
import {
    abTestDataContext, AB_MENU, COLORFUL_CATEGORIES_IN_MENU, COLORFUL_CATEGORIES_IN_MENU_TEST,
} from 'app/containers/contexts/abTest.context';
import { LocaleContext } from 'app/containers/LocaleProvider';
import { AuthContext } from 'app/containers/AuthContainer';
import { DesignContext } from 'app/containers/DesignProvider';
import { Mobile, Desktop } from 'app/components/Responsive';
import BonduelleDesktop from 'app/components/banners/BonduelleDesktop';
import BestSetsMobileSlider from 'app/components/SetsMobileSlider/BestSetsMobileSlider';
// import BestSetsDesktop from 'app/components/banners/BestSetsDesktop/BestSetsDesktop';
import './menu-category-list.scss';
import {
    isDesktop,
} from 'app/utils/resolution';
import { piecewiseBezier } from 'app/utils/smoothScrollAnimationUtils';
import { convertArrayIntoLinkedList } from 'app/utils/covertArrayIntoLinkedList';
import { LocationContext } from 'app/containers/LocationProvider';
import { SetsMobileSlider } from '../SetsMobileSlider';
import MenuCategoryAdapter from '../MenuCategory/MenuCategory';


const viewportPaddingSelector = createSelector(
    (headerHeight) => headerHeight,
    (headerHeight) => ({ top: headerHeight + 400, bottom: 300 }),
);

const APPEARING_TIMEOUT = 150;

const LAST_STEP_SMOOTH_ANIMATION_DURATION = 650;
const BEZIER_ANIMATION_POINTS = [0, 0.80, 0.85, 0.90, 0.95, 0.96, 0.97, 0.98, 0.99, 0.99, 1];


function getCategoriesLinkedList() {
    const categoriesNodes = document.querySelectorAll('.mainCategoryRoot');

    if (!categoriesNodes) return [];

    const categories = Array.from(categoriesNodes);
    const categoriesLinkedList = convertArrayIntoLinkedList(categories);
    return categoriesLinkedList;
}

class MenuCategoryList extends React.Component {
    constructor(props) {
        super(props);

        const { menuDishesQuery, abTestDataContext } = props;

        this.state = {
            isDesktopBannerVisible: true,
            menuDishesQuery,
        };

        this.isProgrammaticScroll = false;
        this.isObserverInited = false;
        this.isMainDishesRenderedTriggered = false;
        this.isScrolledByUser = false;
        this.activeCategoryIndex = 0;
        this.activeCategoryId = null;
        this.dishesContainerRef = React.createRef();
        this.mobileBestSetsRef = React.createRef();

        // BR-1125
        this.isUserInABTest = abTestDataContext[COLORFUL_CATEGORIES_IN_MENU] === COLORFUL_CATEGORIES_IN_MENU_TEST
            && !isDesktop();
    }

    componentDidMount() {
        const {
            menuDishesQuery,
            history,
        } = this.props;
        this.setState({
            menuDishesQuery,
        });

        this.resolveMainDishesLoadedState();

        this.initObserver();
        document.addEventListener('categoryChangeFromSlider', this.handleCategoryChange);
        document.addEventListener('scroll', this.handleUserScrolling);

        const currentSearch = history.location.search || window.location.search;
        const params = queryString.parse(currentSearch);
        const menuCategory = params?.mc;

        if (menuCategory) {
            this.activeCategoryId = menuCategory;
            this.replaceCategoryUrlState(menuCategory);
            const customEvent = new CustomEvent('categoryChangeFromSlider', {
                bubbles: true,
                detail: {
                    activeCategoryId: menuCategory,
                },
            });
            document.dispatchEvent(customEvent);
        }
    }

    componentWillUnmount() {
        this.observer?.disconnect();
        document.removeEventListener('categoryChangeFromSlider', this.handleCategoryChange);
    }

    initObserver() {
        this.observer = new IntersectionObserver(this.handleCategoryIntersection, {
            rootMargin: `-${window.innerHeight / 2 - 20 / 2}px 0px`,
            threshold: 0,
        });
        this.observeCategoriesScrolling();
    }

    handleUserScrolling() {
        if (!this.isScrolledByUser) {
            this.isScrolledByUser = true;
        }
    }

    observeCategoriesScrolling() {
        // В DeliveryMenu didMount срабатывает раньше, поэтому не удается получить категории сразу
        const { isDelivery } = this.props;
        const addListenersToNodes = () => {
            const categoriesNodes = document.querySelectorAll('.mainCategoryRoot');
            if (categoriesNodes) {
                Array.from(categoriesNodes).forEach((c) => this.observer.observe(c));
            }
            if (categoriesNodes.length) {
                this.isObserverInited = true;
            }
        };

        if (!isDelivery) {
            addListenersToNodes();
            return;
        }
        const interval = setInterval(
            () => {
                if (!this.isObserverInited) {
                    addListenersToNodes();
                    console.log('observer not inited');
                } else {
                    console.log('observer inited');
                    clearInterval(interval);
                }
            },
            500,
        );
    }

    handleCategoryIntersection = (entries) => {
        const {
            menuDispatchContext,
        } = this.props;
        entries.forEach((entry) => {
            const isIntersectionDetecting = !this.isProgrammaticScroll && entry.isIntersecting;
            if (!isIntersectionDetecting) return;

            const categoryId = entry.target.dataset.category_id;
            if (!categoryId) return;
            this.replaceCategoryUrlState(categoryId);

            menuDispatchContext({ type: 'intersectCategory', payload: { categoryId } });
            const categories = getCategoriesLinkedList();
            const targetCategory = categories.find((e) => e.value.dataset.category_id === categoryId);
            this.activeCategoryIndex = targetCategory.index;
        });
    };

    replaceCategoryUrlState = () => {
        if (isDesktop() && this.isScrolledByUser) return;
        const { history } = this.props;
        const currentParams = queryString.parse(history.location.search);

        delete currentParams.mc;
        const newSearch = queryString.stringify(currentParams);

        const detailPageRegex = /dish\/\d+\//;
        const isDetailPageOpened = detailPageRegex.test(window.location.pathname);
        const isMenu = window.location.pathname.includes('menu');
        if (history && !isDetailPageOpened && isMenu) {
            history?.push(`/menu/?${newSearch}`);
        }
    };

    handleCategoryChange = (event) => {
        const { activeCategoryId } = event.detail;

        this.isProgrammaticScroll = true;
        sessionStorage.setItem('isProgrammaticScroll', 'true');
        const isProgrammaticScrollWaitingTimeout = 800;

        const categoriesLinkedList = getCategoriesLinkedList();
        const targetCategory = categoriesLinkedList.find((category) => category.value.dataset.category_id === activeCategoryId);

        if (targetCategory) {
            this.dispatchScrollingChainAnimation(targetCategory);
            setTimeout(() => {
                this.isProgrammaticScroll = false;
                sessionStorage.setItem('isProgrammaticScroll', 'false');
            }, isProgrammaticScrollWaitingTimeout);
        }
    };

    dispatchScrollingChainAnimation(targetCategory) {
        const { menuDispatchContext } = this.props;
        const isDesktopResolution = isDesktop();

        if (!isDesktopResolution) {
            this.mobileScrollCategoryToTop(targetCategory);
        } else {
            menuDispatchContext({
                type: 'scrollTo',
                payload: {
                    value: this.activeCategoryId,
                    target: 'category',
                },
            });
        }
        this.activeCategoryIndex = targetCategory.index;
    }

    mobileScrollCategoryToTop(targetCategory) {
        const scrollDirection = targetCategory.index > this.activeCategoryIndex
            ? 'down'
            : 'up';

        const mobileOffsetPadding = 120;
        const nextTopOffset = targetCategory.next;
        const prevTopOffset = targetCategory.prev;
        const topOffset = targetCategory.value.getBoundingClientRect().top
            + window.pageYOffset
            - mobileOffsetPadding;

        const currentNeighbour = scrollDirection === 'up'
            ? nextTopOffset
            : prevTopOffset;
        const scrollingToNeighbourDistance = currentNeighbour.value
            ? currentNeighbour.value.getBoundingClientRect().top + window.pageYOffset
            : null;

        if (scrollingToNeighbourDistance) {
            window.scrollTo(0, scrollingToNeighbourDistance);
        }
        this.smoothScrollTo(
            topOffset,
            LAST_STEP_SMOOTH_ANIMATION_DURATION,
            null,
        );
        this.handleUserScrolling();
    }

    smoothScrollTo(y, duration, callback) {
        const startY = window.pageYOffset;
        const diff = y - startY;
        const start = performance.now();

        function step(timestamp) {
            const time = (timestamp - start) / duration;
            const t = Math.min(time, 1);

            const easedT = piecewiseBezier(
                t,
                BEZIER_ANIMATION_POINTS,
            );

            window.scrollTo({ top: startY + diff * easedT });

            if (t < 1) {
                window.requestAnimationFrame(step);
            } else if (callback) {
                callback();
            }
        }

        window.requestAnimationFrame(step);
    }

    /*
    isMobileMenuDishesVisible == false, когда открыты мобильные фильтры
    Все ререндеры при нажатиях на фильтры произойдут после нажатия на "Показать блюда"
*/
    shouldComponentUpdate(nextProps) {
        const { isMobileMenuDishesVisible } = nextProps;

        return isMobileMenuDishesVisible;
    }

    componentDidUpdate(prevProps) {
        this.updateDishesAnimationState(prevProps);
        this.resolveMainDishesLoadedState();
    }

    // eslint-disable-next-line
    resolveMainDishesLoadedState() {
        const {
            menuDishesQuery: { allDishes },
            onMainDishesRendered,
        } = this.props;

        if (this.isMainDishesRenderedTriggered) return;

        const mainDishes = selectDishes(allDishes, null, [], [CATEGORY_MAIN]);

        if (mainDishes.length === 0) {
            onMainDishesRendered(0);
        }

        this.isMainDishesRenderedTriggered = true;

        const availableDishesCount = mainDishes.filter((d) => d.visibleStatus === 1).length;
        onMainDishesRendered(availableDishesCount);
    }

    handleCloseDesktopBanner = () => {
        this.setState({
            isDesktopBannerVisible: false,
        });
    };

    handleCategoryViewportPositionChange = ({ categoryId, viewportState }) => {
        const { onCategoryEntered } = this.props;
        if (viewportState.state === 'entered') onCategoryEntered(categoryId);
    };

    updateDishesAnimationState(prevProps) {
        const {
            isMobileMenuDishesVisible,
            menuDishesQuery,
            setDishesUpdatingState,
            selectedFilters: { selectedPeriod },
        } = this.props;
        const {
            isMobileMenuDishesVisible: prevIsMobileMenuDishesVisible,
            menuDishesQuery: prevMenuDishesQuery,
            selectedFilters: { selectedPeriod: prevSelectedPeriod },
        } = prevProps;

        const isMenuDishesChanged = !isEqual(menuDishesQuery.menuDishes, prevMenuDishesQuery.menuDishes);
        const dishesIds = new Set(menuDishesQuery.filtredDishes.map((d) => d.id));
        const prevDishesIds = new Set(prevMenuDishesQuery.filtredDishes.map((d) => d.id));
        const isMenuDishesFiltred = !isEqual(dishesIds, prevDishesIds);

        const { loading } = menuDishesQuery;
        const { loading: prevIsLoading } = prevMenuDishesQuery;

        /*
        При смене периода сурово перезаписываем стейт
    */
        if (selectedPeriod !== prevSelectedPeriod) {
            this.setState({ menuDishesQuery });
            return;
        }
        if (!loading && prevIsLoading) {
            this.setState({ menuDishesQuery });
            return;
        }

        /*
        При закрытии мобильных фильтров показываем новые блюда без анимаций
    */
        if (isMobileMenuDishesVisible !== prevIsMobileMenuDishesVisible) {
            this.setState({ menuDishesQuery });
            return;
        }

        if (loading) return;

        if (isMenuDishesFiltred) {
            setDishesUpdatingState(true, () => {
                setTimeout(() => {
                    this.setState({ menuDishesQuery });
                    setTimeout(() => {
                        setDishesUpdatingState(false);
                    }, 400);
                }, 400);
            });
            return;
        }

        if (isMenuDishesChanged) {
            this.setState({
                menuDishesQuery,
            });
        }
    }

    renderDesktopBanner() {
        const {
            selectedFilters: { selectedPeriod },
            isMenuPromoShown,
            isDelivery,
            openDishDetails,
        } = this.props;

        const {
            isDesktopBannerVisible,
        } = this.state;

        const isShownDesktopNewsBanner = isDesktopBannerVisible && !isDelivery && !isMenuPromoShown;

        if (!isShownDesktopNewsBanner) return null;

        // return (
        //     <NYBannerDesktop onClickClose={this.handleCloseDesktopBanner} />
        // );

        return (
            <BonduelleDesktop
                onClick={openDishDetails}
                onClickClose={this.handleCloseDesktopBanner}
                period={selectedPeriod}
            />
        );
    }

    renderMenuCategoryList(transitionState) {
        const {
            designContext: { headerHeight },

            selectedFilters: {
                selectedCategories,
                selectedCategory,
                selectedTags,
                selectedPeriod,
            },
            basketQuery,
            isDishesUpdating,
            abTestDataContext,
            localeContext: { locale },
            userQuery: { user },
            history,
            setTemporaryBasketPeriod,
            onSetPeriod,
            isDelivery,
            isSelectedDateInEditableRange,
            filterPeriod,
            openBasket,
            selectedDate,
        } = this.props;

        const {
            menuDishesQuery,
        } = this.state;

        // TODO: правильно передавать props для Dishes
        const dishesProps = {
            ...omit(this.props, [
                'basketQuery',
                'filterQuery',
                'isFiltersOpen',
                'selectedFilters',
                'userQuery',
                'authData',
                'selectedFilterPeriod',
            ]),
            useLazyLoading: true,
        };


        const isFilterPeriodSelected = filterPeriod === selectedPeriod;
        const canEditTags = isFilterPeriodSelected ? isSelectedDateInEditableRange : true;

        const enabledCategories = CATEGORIES_ORDER.filter((c) => selectedCategories.includes(c));

        if (!menuDishesQuery.menuDishes || !basketQuery.cart) return null;

        const { sections } = basketQuery.cart;

        const isBasketEmpty = !sections.length;

        const { loading, error, menuDishes: allDishes } = menuDishesQuery;

        const listClasses = classNames({
            'menu-category-list': true,
            [transitionState]: true,
        });
        const dishesContainerClasses = classNames({
            'menu-category-container': true,
            with_new_categories: abTestDataContext[COLORFUL_CATEGORIES_IN_MENU] === COLORFUL_CATEGORIES_IN_MENU_TEST,
            'is-dishes-updating': isDishesUpdating,
        });

        const bannerClasses = classNames({
            'menu-category-list__banner': true,
            list: abTestDataContext[AB_MENU] === ('old' || null),
            slider: abTestDataContext[AB_MENU] === 'new',
        });

        // const canShowSelections = !isDelivery && selectedTags.length === 0 && canEditTags && selectedPeriod !== '2021-12-29';
        const canShowSelections = !isDelivery && canEditTags && selectedPeriod !== '2021-12-29';

        const mostIntrestingDishesData = {
            loading,
            error,
            menuDishes: selectDishes(
                allDishes,
                null,
                [CATEGORY_MOST_INTRESTING_TAG_ID, ...selectedTags],
                selectedCategories,
            ),
        };

        const isMostIntrestingCategoryShown = CATEGORIES_ORDER.includes(CATEGORY_MOST_INTRESTING)
            && selectedCategories.includes(CATEGORY_MOST_INTRESTING)
            && mostIntrestingDishesData.menuDishes.length > 0;

        const currentCategoryTitlesCollection = this.isUserInABTest
            ? mostInterestingCategoryTitleForABTest
            : CATEGORY_TITLES;

        return (
            <div styleName={listClasses}>
                <div
                    styleName={dishesContainerClasses}
                    ref={this.dishesContainerRef}
                >
                    {!isDelivery && (
                        <Mobile>
                            {/* list or slider */}
                            <div styleName={bannerClasses} ref={this.mobileBestSetsRef}>
                                <SetsMobileSlider
                                    showTrialSet
                                    isBasketEmpty={isBasketEmpty}
                                    history={history}
                                    setTemporaryBasketPeriod={setTemporaryBasketPeriod}
                                    onSetPeriod={onSetPeriod}
                                    basketQuery={basketQuery}
                                    openBasket={openBasket}
                                    selectedPeriod={selectedPeriod}
                                    isMenuEmpty={false}
                                />
                            </div>
                        </Mobile>
                    )}
                    {isMostIntrestingCategoryShown && (
                        <div className="mainCategoryRoot" data-category_id={CATEGORY_MOST_INTRESTING}>
                            <MenuCategoryAdapter
                                key={CATEGORY_MOST_INTRESTING}
                                index={1}
                                {...dishesProps}
                                headerHeight={headerHeight}
                                menuDishesQuery={mostIntrestingDishesData}
                                categoryId={CATEGORY_MOST_INTRESTING}
                                isCatgoryActive={CATEGORY_MOST_INTRESTING === selectedCategory}
                                isUserInABTest={this.isUserInABTest}
                                title={currentCategoryTitlesCollection[locale][CATEGORY_MOST_INTRESTING]}
                                viewportPadding={viewportPaddingSelector(headerHeight)}
                                onViewportPositionChange={this.handleCategoryViewportPositionChange}
                                isBasketLoading={basketQuery.loading}
                                basketQuery={basketQuery}
                                isDishesUpdating={isDishesUpdating}
                                locale={locale}
                                isDishesSliderNeeded={canShowSelections}
                            />
                        </div>
                    )}
                    {enabledCategories.map((categoryId, index) => {
                        const menuDishes = allDishes
                            .filter((d) => String(d.categoryId) === categoryId);

                        if (!menuDishes.length) return null;

                        const title = CATEGORY_TITLES[locale][categoryId];

                        const dishesData = {
                            loading,
                            error,
                            menuDishes,
                        };
                        const isDishesSliderNeeded = (
                            categoryId === CATEGORY_MAIN
                            && !isMostIntrestingCategoryShown
                            && canShowSelections
                        );

                        return (
                            <div className="mainCategoryRoot" data-category_id={categoryId} key={categoryId}>
                                <MenuCategoryAdapter
                                    key={categoryId}
                                    index={isMostIntrestingCategoryShown ? index + 1 : index}
                                    {...dishesProps}
                                    headerHeight={headerHeight}
                                    menuDishesQuery={dishesData}
                                    categoryId={categoryId}
                                    isCatgoryActive={categoryId === selectedCategory}
                                    isUserInABTest={this.isUserInABTest}
                                    title={title}
                                    viewportPadding={viewportPaddingSelector(headerHeight)}
                                    onViewportPositionChange={this.handleCategoryViewportPositionChange}
                                    isBasketLoading={basketQuery.loading}
                                    isDishesUpdating={isDishesUpdating}
                                    basketQuery={basketQuery}
                                    locale={locale}
                                    isDishesSliderNeeded={isDishesSliderNeeded}
                                />
                            </div>
                        );
                    })}
                    <Desktop>
                        <div styleName="menu-category-list__banner">
                            {this.renderDesktopBanner()}
                        </div>
                    </Desktop>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Transition
                in
                appear
                timeout={APPEARING_TIMEOUT}
            >
                {(transitionState) => this.renderMenuCategoryList(transitionState)}
            </Transition>
        );
    }
}

const MenuCategoryListAdapter = (props) => {
    const {
        openBasket = null,
        isFiltersOpen = false,
        isMenuPromoShown = false,
        isDelivery = false,
        onMainDishesRendered = () => { },
    } = props;

    const {
        state: {
            eeAvailableDates, firstVisibleDates, filterPeriod, prevSelectedFilterPeriod,
            selectedFilterPeriod,
            isSelectedDateInEditableRange,
            selectedDate,
        },
    } = useContext(menuDatesState);
    const history = useHistory();
    const menuDispatchContext = useContext(MenuDispatchContext);
    const localeContext = useContext(LocaleContext);
    const abTestData = useContext(abTestDataContext);
    const designContextValue = useContext(DesignContext);
    const authData = useContext(AuthContext);
    const locationContext = useContext(LocationContext);

    return (
        <MenuCategoryList
            {...props}
            locationContext={locationContext}
            history={history}
            localeContext={localeContext}
            abTestDataContext={abTestData}
            menuDispatchContext={menuDispatchContext}
            designContext={designContextValue}
            authData={authData}
            eeAvailableDates={eeAvailableDates}
            firstVisibleDates={firstVisibleDates}
            filterPeriod={filterPeriod}
            selectedDate={selectedDate}
            prevSelectedFilterPeriod={prevSelectedFilterPeriod}
            selectedFilterPeriod={selectedFilterPeriod}
            isSelectedDateInEditableRange={isSelectedDateInEditableRange}
            openBasket={openBasket}
            isFiltersOpen={isFiltersOpen}
            isMenuPromoShown={isMenuPromoShown}
            isDelivery={isDelivery}
            onMainDishesRendered={onMainDishesRendered}
        />
    );
};

export default withRouter(MenuCategoryListAdapter);


MenuCategoryList.propTypes = {
    designContext: PropTypes.shape({
        headerHeight: PropTypes.number.isRequired,
    }).isRequired,

    localeContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,

    history: PropTypes.shape({}).isRequired,

    isMenuPromoShown: PropTypes.bool,
    isFiltersOpen: PropTypes.bool,
    eeAvailableDates: PropTypes.bool,

    selectedFilterPeriod: PropTypes.string,
    filterPeriod: PropTypes.string.isRequired,

    selectedFilters: PropTypes.shape({
        selectedCategory: PropTypes.string,
        selectedPeriod: PropTypes.string,
        selectedTags: PropTypes.arrayOf(PropTypes.string),
        selectedCategories: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    filterQuery: PropTypes.shape({
        menuFilter: PropTypes.shape({
            periods: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }).isRequired,

    menuDishesQuery: PropTypes.shape({
        loading: PropTypes.bool,
        error: PropTypes.shape({}),
        menuDishes: PropTypes.arrayOf(PropTypes.shape({})),
        allDishes: PropTypes.arrayOf(PropTypes.shape({})),
        filtredDishes: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    basketQuery: PropTypes.shape({
        cart: PropTypes.shape({
            sections: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        loading: PropTypes.bool.isRequired,
    }).isRequired,
    authData: PropTypes.shape({
        isAuthed: PropTypes.bool,
    }).isRequired,
    userQuery: PropTypes.shape({
        user: PropTypes.shape({}),
    }).isRequired,

    onCategoryEntered: PropTypes.func.isRequired,
    openBasket: PropTypes.func,
    openDishDetails: PropTypes.func.isRequired,

    isDelivery: PropTypes.bool,
    onMainDishesRendered: PropTypes.func,

    setDishesUpdatingState: PropTypes.func.isRequired,
    setTemporaryBasketPeriod: PropTypes.func.isRequired,
    onSetPeriod: PropTypes.func.isRequired,
    isDishesUpdating: PropTypes.bool.isRequired,
    abTestDataContext: PropTypes.shape({
        [AB_MENU]: PropTypes.oneOf(['new', 'old', null]),
    }).isRequired,

    firstVisibleDates: PropTypes.shape({
        eeAvailableDates: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    isSelectedDateInEditableRange: PropTypes.bool.isRequired,

    isMobileMenuDishesVisible: PropTypes.bool.isRequired,
};
