import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { analyticService } from 'global/services';

import classNames from 'classnames';
import { useReactiveVar } from '@apollo/client';
import { isAsNewbieAuthedVar } from 'app/apollo/reaction';
import avatarIcon from '../images/avatar.img.svg';

import css from './spaHeader.module.scss';


const SigninButton = () => {
    const title = 'Войти';
    return (
        <div className="header-sighin-button">
            <p className="header-sighin-button__item">
                {title}
            </p>
        </div>
    );
};

const ProfileIcon = () => {
    const isProfileRoute = window.location.pathname.includes('/profile/');
    if (isProfileRoute) return null;
    return (
        <div className={css.newSpaHeaderAvatarIcon}>
            <img src={avatarIcon} alt="профиль" />
        </div>
    );
};


interface SighInSPAButtonContainerProps {
    isAuthed?: boolean,
    history?: RouteComponentProps['history'] | undefined,
}

export const SignInButtonContainer = ({
    isAuthed = false,
    history = undefined,
}: SighInSPAButtonContainerProps) => {
    const isAsNewbieAuthed = useReactiveVar(isAsNewbieAuthedVar);
    const trackLoginClick = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
        const nextLocation = isAuthed || isAsNewbieAuthed ? '/profile/' : '/auth/';
        analyticService.push({
            eventName: 'Header_Click',
            button_name: isAuthed
                ? 'Profile'
                : 'Login',
        });

        if (history) {
            history.push(nextLocation);
        } else {
            window.location.pathname = nextLocation;
        }
    }, [history, isAuthed, isAsNewbieAuthed]);

    const isLoginButtonDisabled = window.location.pathname.includes('/profile/');

    return (
        <button
            title="Личный кабинет"
            onClick={trackLoginClick}
            type="button"
            disabled={isLoginButtonDisabled}
            className={css.newSpaHeaderButtonWrapper}
        >
            {isAuthed || isAsNewbieAuthed ? <ProfileIcon /> : <SigninButton />}
        </button>
    );
};
