import React, {
    useState, useRef, useContext, useCallback, useMemo, useEffect,
} from 'react';
import { Portal } from 'react-portal';
import classNames from 'classnames';


import { citiesLocales as cities } from 'app/const/location';
import { analyticService } from 'global/services';

import { useResize } from 'app/utils/hooks/useResize';
import useOutsideClick from 'app/utils/hooks/useOutsideClick';

import { LocationContext } from 'app/containers/LocationProvider';
import { MobileCitySelectModal } from '../MobileCitySelectModal/MobileCitySelectModal';
import ArrowIcon from './img/arrow.component.svg';
import css from './CitySelectV2.module.scss';

export const CitySelectV2 = () => {
    const {
        mutateAddreessHandler,
        locationKey,
    } = useContext(LocationContext);

    const [activeLocationKey, setActiveLocationKey] = useState(locationKey);
    const [isSelectOpenned, setIsSelectOpenned] = useState<boolean>(false);
    const [isMobileSelectOpenned, setIsMobileSelectOpenned] = useState<boolean>(false);
    const selectRef = useRef(null);

    const { isDesktop } = useResize();
    useOutsideClick({
        ref: selectRef,
        callback: () => setIsSelectOpenned(false),
    });

    const selectedCity = useMemo(
        () => cities.find((city) => city.id === activeLocationKey),
        [activeLocationKey],
    );

    const onSelectClickHandler = () => {
        analyticService.push({ eventName: 'Header_Click', button_name: 'City' });
        if (isDesktop) {
            setIsSelectOpenned((prev) => !prev);
            return;
        }
        setIsMobileSelectOpenned((prev) => !prev);
    };

    const handleCityClick = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const cityId = event?.currentTarget?.id;

        if (cityId !== activeLocationKey) {
            const addressString = {
                MSK: 'г Москва',
                SPB: 'г Санкт-Петербург',
                NIZ: 'г Нижний Новгород',
                KZN: 'г Казань',
            };
            // @ts-ignore
            mutateAddreessHandler({ address: addressString[cityId] }, () => setActiveLocationKey(cityId));

            analyticService.push({
                eventName: 'Select_Region',
                result: cityId,
                source_name: 'selected_by_user',
            });
        }
        setIsSelectOpenned(false);
        setIsMobileSelectOpenned(false);
    }, [activeLocationKey, mutateAddreessHandler]);

    useEffect(
        () => {
            setActiveLocationKey(locationKey);
        },
        [locationKey],
    );
    const isDesktopSelect = isSelectOpenned && isDesktop;
    const isProfileRoute = window.location.pathname.includes('/profile');

    const rootClasses = classNames({
        [css.root]: true,
        [css.rootScaling]: isProfileRoute,
    });
    const arrowClasses = classNames({
        [css.arrowIconWrapper]: true,
        [css.rotated]: isSelectOpenned,
    });

    return (
        <div className={rootClasses} ref={selectRef}>
            <div
                className={css.selectedCityFlexContainer}
                onClick={onSelectClickHandler}
                role="none"
            >
                <div className={css.selectedCity}>
                    {isDesktop
                        ? selectedCity?.desktopTitle
                        : selectedCity?.mobileTitle}
                </div>
                <div className={arrowClasses}>
                    <ArrowIcon
                        className={css.arrowIcon}
                        fill={
                            isDesktop
                                ? '#747474'
                                : '#9C9C9C'
                        }
                    />
                </div>
            </div>
            {isDesktopSelect && (
                <div className={css.selectOptionsContainer}>
                    {cities.map((e) => (
                        <div
                            id={e.id}
                            key={e.id}
                            className={css.cityOption}
                            onClick={handleCityClick}
                            role="none"
                        >
                            {e.value}
                        </div>
                    ))}
                </div>
            )}
            <Portal>
                <MobileCitySelectModal
                    isOpen={isMobileSelectOpenned}
                    setIsOpen={setIsMobileSelectOpenned}
                    setCityCallback={setActiveLocationKey}
                    cities={cities}
                />
            </Portal>
        </div>
    );
};
