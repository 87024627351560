import React, { useEffect, useCallback, useState } from 'react';
import { Portal } from 'react-portal';
import { Transition } from 'react-transition-group';
import { useQuery } from '@apollo/client';
import cn from 'classnames';

import { AchievementsUserQuery } from '../graphql/achievementsUserQuery.graphql';
import { AchievementsPopup } from '../AchievementsPopup/AchievementsPopup';

import css from './achievementsOpener.module.scss';


type AchievementsOpenerProps = {
    // eslint-disable-next-line no-unused-vars
    children: (props: { togglePopup: () => void }) => React.ReactNode;
}

const container = document.querySelector('#root') || document.body;


export function AchievementsOpener({
    children,
}: AchievementsOpenerProps) {
    const { data } = useQuery(AchievementsUserQuery);

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = useCallback(() => {
        setIsPopupOpen((isOpen) => !isOpen);
    }, []);

    useEffect(() => {
        container.classList.add(css.achievementsBodyAnimation);
        return () => {
            container.classList.remove(css.achievementsBodyAnimation);
            container.classList.remove(css.achievementsBodyAnimationOpen);
        };
    }, []);

    useEffect(() => {
        if (isPopupOpen) {
            container.classList.add(css.achievementsBodyAnimationOpen);
        } else {
            container.classList.remove(css.achievementsBodyAnimationOpen);
        }
    }, [isPopupOpen]);

    if (!data?.user?.achievement) return null;

    return (
        <>
            {children({ togglePopup })}
            <Portal>
                <Transition
                    in={isPopupOpen}
                    timeout={500}
                >
                    {(transitionState) => {
                        const containerClasses = cn({
                            [css.achievementsPopupTransition]: true,
                            [css[transitionState]]: true,
                        });

                        const backdropClasses = cn({
                            [css.achievementsPopupBackdrop]: true,
                            [css[transitionState]]: true,
                        });

                        return (
                            <>
                                <div className={backdropClasses} />
                                <div className={containerClasses}>
                                    <AchievementsPopup
                                        achievement={data.user.achievement}
                                        transitionState={transitionState}
                                        onClose={togglePopup}
                                    />
                                </div>
                            </>
                        );
                    }}
                </Transition>
            </Portal>
        </>
    );
}
