export const BOXES_IDS_COLLECTION = ['4695', '4744', '4331', '4371', '4875', '3993', '4443'];

export const BOXES_WITHOUT_DETAILS_COMPOSITION = ['4331', '4875', '4443'];

export const dishesWithDetailsMap = {
    4331: 'romantic',
    4875: 'march',
    3993: 'miragosha',
    4443: 'easterbox',
};

// в resolveOverrideImage можно добавить изображение с клиента для царь-карточки
