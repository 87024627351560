import React from 'react';
import { isStandaloneApp } from 'app/utils/browser';
import css from './miragoshaDetails.module.scss';
import '../../dishDetailsWithCustom.scss';

import videoLinkCover from './img/videoLinkCover.jpg';


export const MiragoshaDetails = () => (
    <div className={css.miragoshaDetailsRoot}>
        <img
            src={videoLinkCover}
            className={css.miragoshaVideoLinkCover}
            alt="video link cover"
        />
        <a
            className={css.miragoshaDetaisVideoLink}
            href="https://elmntr.ee/miragosha-pirog-episode"
            target={isStandaloneApp() ? undefined : '_blank'}
            rel="noreferrer"
        >
            <div className={css.miragoshaDetaisVideoLinkTitle}>Смотреть новую серию</div>
        </a>
    </div>
);
