/* eslint-disable global-require */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
/* COMPOENT */
import { AbstractDishShield } from './imgV2/Shield';

/* STYLES */
import s from './dish-image-shield.scss';

const dishWithShield = new Map([
    ['3784', { name: 'monica', type: 'combined' }],
    ['3791', { name: 'theory', type: 'combined' }],
    ['3789', { name: 'avengers', type: 'combined' }],
    ['3790', { name: 'pulp', type: 'combined' }],
    ['3782', { name: 'ameli', type: 'combined' }],
    ['4640', { name: 'peaks', type: 'combined' }],
    ['4178', { name: 'housewives', type: 'combined' }],
    ['3865', { name: 'twilight', type: 'combined' }],
    ['4644', { name: 'moon', type: 'combined' }],
    ['4645', { name: 'cards', type: 'combined' }],
    ['4574', { name: 'olya', type: 'combined' }],
    ['4581', { name: 'olya', type: 'combined' }],
    ['4556', { name: 'olya', type: 'combined' }],
    ['4557', { name: 'olya', type: 'combined' }],
    ['4575', { name: 'olya', type: 'combined' }],

    ['4344', { name: '_helena', type: 'text', isShownOnLangings: true }],
    ['4011', { name: '_gertruda', type: 'text', isShownOnLangings: true }],
    ['3941', { name: '_frida', type: 'text', isShownOnLangings: true }],
    ['3948', { name: '_chanel', type: 'text', isShownOnLangings: true }],
    ['3943', { name: '_carolina', type: 'text', isShownOnLangings: true }],
    ['3945', { name: '_agata', type: 'text', isShownOnLangings: true }],
    ['3942', { name: '_curie', type: 'text', isShownOnLangings: true }],
    ['3947', { name: '_clara', type: 'text', isShownOnLangings: true }],

    ['3993', { name: 'miragosha', type: 'combined', isShownOnLangings: false }],
    ['4417', { name: 'miragosha', type: 'combined', isShownOnLangings: false }],

    ['24', { name: 'yapracticum', type: 'combined', isShownOnLangings: false }],
    ['25', { name: 'yapracticum', type: 'combined', isShownOnLangings: false }],
    ['32', { name: 'yapracticum', type: 'combined', isShownOnLangings: false }],
    ['228', { name: 'yapracticum', type: 'combined', isShownOnLangings: false }],
    ['2929', { name: 'yapracticum', type: 'combined', isShownOnLangings: false }],
    ['4187', { name: 'yapracticum', type: 'combined', isShownOnLangings: false }],
    ['4779', { name: 'yapracticum', type: 'combined', isShownOnLangings: false }],
    ['2677', { name: 'yapracticum', type: 'combined', isShownOnLangings: false }],
    ['4236', { name: 'yapracticum', type: 'combined', isShownOnLangings: false }],
]);


export const DishImageShield = React.memo(({
    dishId,
    isSoldOut = false,
    realm = false,
    tagsString = '',
}) => {
    const {
        name, type, isShownOnLangings, lpOnly,
    } = useMemo(() => {
        if (isSoldOut) {
            dishWithShield.set(dishId, { name: 'soldout', type: 'text', isShownOnLangings: true });
        }

        if (tagsString) {
            const isFastShield = tagsString.includes('2121');
            if (isFastShield) {
                return { name: 'fast', type: 'text', isShownOnLangings: false };
            }
        }

        const shieldData = dishWithShield.get(dishId);
        return shieldData || { name: '', type: '' };
    }, [dishId, isSoldOut, tagsString]);

    if (!name || !type) return null;

    if (realm === 'lp' && !isShownOnLangings) return null;
    if (realm !== 'lp' && lpOnly) return null;


    return (
        <div className={s['dish-image-shield__root']}>
            <AbstractDishShield name={name} type={type} />
        </div>
    );
});


DishImageShield.propTypes = {
    dishId: PropTypes.string.isRequired,
    isSoldOut: PropTypes.bool,
    realm: PropTypes.string,
};

/* DEPRECATED */

// Ahmad

// ['3677', { name: 'ahmad', type: 'combined' }],
// ['3833', { name: 'ahmad', type: 'combined' }],

// Kozel

// ['4663', { name: 'kozel', type: 'upper', isShownOnLangings: false }],
// ['4662', { name: 'kozel', type: 'upper', isShownOnLangings: false }],
// ['4664', { name: 'kozel', type: 'upper', isShownOnLangings: false }],
// ['4658', { name: 'kozel', type: 'upper', isShownOnLangings: false }],

// // NY 2025

// ['1257', {
//     name: 'favorite', type: 'upperText', isShownOnLangings: true, lpOnly: true,
// }],
// ['3839', {
//     name: 'fifteenminutes', type: 'upperText', isShownOnLangings: true, lpOnly: true,
// }],
// ['4697', {
//     name: 'new', type: 'upperText', isShownOnLangings: true, lpOnly: true,
// }],
// ['4698', {
//     name: 'new', type: 'upperText', isShownOnLangings: true, lpOnly: true,
// }],
// ['4701', {
//     name: 'new', type: 'upperText', isShownOnLangings: true, lpOnly: true,
// }],
// ['4700', {
//     name: 'new', type: 'upperText', isShownOnLangings: true, lpOnly: true,
// }],
// ['4699', {
//     name: 'new', type: 'upperText', isShownOnLangings: true, lpOnly: true,
// }],
