import {
    customizationTagsForABTest,
} from 'app/views/Basket/enums/basket';

const checkAllDishesHaveTargetTag = (targetId, targetCollection) => {
    const tagsExistence = {};
    Object.keys(targetCollection).forEach((key) => {
        const tags = targetCollection[key];
        tagsExistence[key] = tags.some((tag) => tag.id === targetId);
    });

    const tagIsMissingInAnyDish = Object.values(tagsExistence).includes(false);
    return !tagIsMissingInAnyDish;
};

export const findMissingTag = (commentIds, sections) => {
    // Раскрываем структуру закешированного объекта по рефам, собираем в коллекцию по id диша
    const dishesTagsCollection = sections
        .flatMap((section) => section.items)
        .flatMap((item) => item.dish)
        .reduce((acc, dish) => {
            acc[dish.id] = dish.tags;
            return acc;
        }, {});

    const missingTags = [];
    commentIds?.forEach((tagId) => {
        const allDishesHaveTargetTag = checkAllDishesHaveTargetTag(tagId, dishesTagsCollection);
        if (!allDishesHaveTargetTag) {
            missingTags.push(tagId);
        }
    });
    return missingTags;
};

export const searchForCustomizationContradiction = (cart, userCustomizationTags) => {
    const sections = cart?.sections ?? [];
    const missingTags = findMissingTag(userCustomizationTags, sections);
    return missingTags;
};

export const findMostImportantContradictionTag = (missingTags) => {
    const missingTagsFromABTestEnum = [];
    missingTags.forEach((tag) => {
        if (customizationTagsForABTest.includes(Number(tag))) {
            missingTagsFromABTestEnum.push(tag);
        }
    });
    if (missingTagsFromABTestEnum) {
        // Пока берем просто первый
        return missingTagsFromABTestEnum[0];
    }
    return null;
};
