import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';
import { UIHeading, UIParagraph } from 'app/components/ui';
import ClosePopup from 'app/components/ui/ClosePopup';

import './select.scss';


/*
Item = {
    id!: string // используется, как key
    buttonProps!: object // объект с пропсами для элемента кнопки (onClick, disabled, ...)
    TextCmp!: string | ReactElement // текст элемента в списке
}

Section = {
    id!: string // используется, как key
    header: string | ReactElement // текст подзаголовка секции (необязательный)
    items!: Item[] // список опций
}

Props = {
    headerText!: string // текст заголовка селекта
    onClose: function // хэндлер кнопки закрытия. Если не передан, то использется closeLastOverlays
    style: 'center' // стиль для отображения списка: по центру (дефолт)
        | 'left-align' // выровненный по левой стороне
        | 'center-left-align' // текст в центре, но выровнены по левой стороне
    sections!: Section[] // массив секций, которые содержат список опций для выбора
}
*/
function SelectMobile(props) {
    const { closeLastOverlays: defaultHandleClose } = useContext(OverlayContextDispatch);
    const {
        onClose = defaultHandleClose,
        style = 'center',
        headerText,
        sections,
    } = props;

    const defaultItemTextContainerClasses = {
        'mobile-select__list-item__title-container': true,
        [style]: true,
    };


    const itemTextContainerClasses = classNames({
        ...defaultItemTextContainerClasses,
    });

    return (
        <div styleName="mobile-select">
            <div styleName="mobile-select__close-row">
                <div styleName="mobile-select__title-container">
                    <UIHeading level="3.2">
                        {headerText}
                    </UIHeading>
                </div>

                <div styleName="mobile-select__close-container">
                    <ClosePopup onClick={onClose} />
                </div>
            </div>
            <div styleName="mobile-select__list--wrapper">
                <ul styleName="mobile-select__list" id="touch-scrolllock-select">
                    {sections.map(({ id: sectionId, header, items }) => (
                        <React.Fragment key={sectionId}>
                            {!!header && (
                                <li styleName="mobile-select__list__header-container" key={sectionId}>
                                    <div styleName={itemTextContainerClasses}>
                                        <p styleName="mobile-select__list__header-text">
                                            {header}
                                        </p>
                                    </div>
                                </li>
                            )}

                            {items.map(({
                                id: itemId, buttonProps, TextCmp, selected,
                            }) => {
                                const itemClasses = classNames({
                                    'mobile-select__list-item': true,
                                    selected,
                                });
                                const selectedItemTextContainerClasses = classNames({
                                    ...defaultItemTextContainerClasses,
                                    selected,
                                });

                                return (
                                    <li styleName={itemClasses} key={itemId}>
                                        {buttonProps.type === 'link' ? (
                                            <a
                                                styleName="mobile-select__list-item__button"
                                                {...buttonProps}
                                            >
                                                <div styleName={selectedItemTextContainerClasses}>
                                                    <UIParagraph level="2.2" inheritColors>
                                                        <span styleName="mobile-select__list-item--wrapper">
                                                            {TextCmp}
                                                        </span>
                                                    </UIParagraph>
                                                </div>
                                            </a>
                                        ) : (
                                            <button
                                                styleName="mobile-select__list-item__button"
                                                {...buttonProps}
                                                type="button"
                                            >
                                                <div styleName={selectedItemTextContainerClasses}>
                                                    <UIParagraph level="2.2" inheritColors>
                                                        <span styleName="mobile-select__list-item--wrapper">
                                                            {TextCmp}
                                                        </span>
                                                    </UIParagraph>
                                                </div>
                                            </button>
                                        )}
                                    </li>
                                );
                            })}
                        </React.Fragment>
                    ))}
                </ul>
            </div>
        </div>
    );
}


export default SelectMobile;

SelectMobile.propTypes = {
    headerText: PropTypes.string.isRequired,
    sections: PropTypes.array.isRequired,
    onClose: PropTypes.func,
    style: PropTypes.oneOf(['center', 'left-align', 'center-left-align']),
};

