import gingerbreadCookieMobile from './staticImages/4744.jpg';
import romanticBoxMobile from './staticImages/4331.jpg';
import maslenitsaBoxMobile from './staticImages/4371.jpg';
import miragoshaBoxMobile from './staticImages/3993.jpg';
import marchBoxMobile from './staticImages/4875.jpg';
import easterBoxMobile from './staticImages/4443.jpg';

const overrideImagesMap = {
    4744: gingerbreadCookieMobile,
    4331: romanticBoxMobile,
    4371: maslenitsaBoxMobile,
    3993: miragoshaBoxMobile,
    4875: marchBoxMobile,
    4443: easterBoxMobile,
};

export const resolveOverrideImage = (id) => (
    id in overrideImagesMap
        ? overrideImagesMap[id]
        : null
);
