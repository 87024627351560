import omit from 'lodash/omit';

import { enabledDishes as nyEnabledDishes } from './ny';


export const periods = [
    '2025-01-06',
];

const enabledDishes = omit(nyEnabledDishes, 'set', 'gifts');
export const dishes = Object.values(enabledDishes).flat();

