/* eslint-disable react/sort-comp */
/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import PropTypes, { shape } from 'prop-types';
import classNames from 'classnames';
import compose from 'lodash/flowRight';
import throttle from 'lodash/throttle';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import flatten from 'lodash/flatten';
import Raven from 'raven-js';
import queryString from 'query-string';
import addDays from 'date-fns/add_days';
import { Transition } from 'react-transition-group';
import { useMutation } from '@apollo/client';

import { analyticService } from 'global/services';
import { paymentService } from 'global/services/paymentService/paymentService';

import paymentDataQuery from 'app/graphql/network/payment';
import { ADD_ITEMS_TO_CART_BY_PERIOD } from 'app/graphql/network/basket/basketMutations';

import { PERIOD_IS_DISABLED_ERROR_MESSAGE } from 'app/const/basket-errors';
import { PAYMENT_PARAM_CASH } from 'app/const/billing-payment-methods';

import {
    getTrialScenarioFromUrl,
} from 'app/utils/trial-scenario';
import { isDesktop } from 'app/utils/resolution';
import {
    cacheInvoiceData,
} from 'app/utils/invoice';
import { formatIntervals } from 'app/utils/date';
import { getDigitsFromPhone } from 'app/utils/phone';
import { requestSuggestions, checkSuggestion } from 'app/utils/suggestions';
import isEmail from 'app/utils/email';
import { getDishesFromBasketSections } from 'app/utils/dish';
import { getUTMParams } from 'app/utils/utmParams';
import { checkIsJSONComment, formatCommentForCustomizers } from 'app/utils/customizationComment';
import { paymentMethodVar, promocodeVar, subscriptionTypeVar } from 'app/apollo/reaction';

import { SUBSCRIPTION_TYPES, isSubscription } from 'app/const/subscription';
import {
    MAX_BELTWAY_DISTANCE,
} from 'app/const/address';

import ErrorContent from 'app/components/ErrorContent';
import { UIButton } from 'app/components/ui';

import LINKS from 'app/const/links';

import { withDispatchOverlay } from 'app/containers/contexts/overlay.context';

import { pickPeriodReaction } from 'app/apollo/reactions/filters';

import client from 'app/apollo/client';
import { StepByStepCheckout, SingleStepCheckout } from './StepByStepCheckout';
import {
    TYPE_CHECKOUT_STEP_BY_STEP,
    TYPE_CHECKOUT_LOADING,
    TYPE_CHECKOUT_SINGLE_STEP,
} from './StepByStepCheckout/stepByStepCheckoutConst';

import { locales } from './checkout.locales';
import {
    checkIsRecurrentPaymentHidden,
    getInitialStreetFormValue,
    // isAddressesInSameSubdivision,
} from './checkout.utils';

import {
    CUSTOMIZATION_COMMENT_FRAGMENT, MUTATE_CUSTOMIZATION_COMMENT,
} from './graphql/checkout.graphql';

import './checkout.scss';


const {
    SPA_THANKS,
} = LINKS;


const DEBOUNCE_TIME = 1000;

// eslint-disable-next-line
/**
 * @param {Array} dates  - массив доступных дат доставки
 * @param subdivision - регион, Москва или Питер
 * @param address
 */
const parseAvailableDates = (dates, subdivision, address) => {
    const isTrial = getTrialScenarioFromUrl();

    const chernogolovkaKladrId = '5000003500000';
    const isChernogolovka = address.data.city_kladr_id === chernogolovkaKladrId;

    if (isTrial) {
        const distance = address.data.beltway_distance;
        if (distance >= 31) {
            if (isChernogolovka) {
                return dates.filter((e) => {
                    return true;
                });
            }
            return dates.filter((d) => {
                const date = new Date(d.date);
                if (date.getDay() === 6) return false;
                return true;
            });
        }
    }

    return dates;
};

const getNextDate = (date) => {
    const d = new Date(date);
    const next = new Date(d.getTime());
    next.setDate(next.getDate() + 1);
    return next;
};


/**
 * @description выполняет проверку заполнения полей: телефон, email, адрес, номер квартиры.
 * @scope firebase аналитика мобильного приложения
 * @returns {boolean} isChecked as boolean
 * @todo telephone always true
 */
const getAllFormFieldChecked = (state = {}) => {
    const {
        telephone, email, street: { value }, apartment,
    } = state;
    return ![telephone, email, value, apartment].map((e) => !!e).some((e) => !e);
};


const getBasketDishesIds = (cart) => {
    const dishes = flatten(cart.sections.map((s) => s.items));
    return dishes.map(({ dish_id: dishId }) => String(dishId));
};


class Checkout extends React.Component {
    static getDerivedStateFromProps(props, state) {
        const {
            paymentMethod, street, email,
        } = state;

        const {
            userQuery: { user },
            subscriptionTypeQuery: {
                subscriptionType,
            },
            checkoutData,
        } = props;

        if (!user) return null;

        const nextState = { paymentMethod, street };

        const hasUserEmail = user && user.email;

        const hasCachedEmail = checkoutData && checkoutData.email;

        if (hasUserEmail && email === null && !hasCachedEmail) {
            nextState.email = user.email;
            nextState.emailValid = true;
        }

        if (isSubscription(subscriptionType)) {
            nextState.paymentMethod = 'online';
        }

        return nextState;
    }

    state = {
        timeIntervalsInsideBetlwayTitle: 'Москва',
        timeIntervalsOutsideBetlwayTitle: 'За МКАД',
        currentView: '', // onlyPaymentButton
        paymentInfo: null, // нужно для повторного открытия виджета оплаты
        street: {
            value: null, // ''
            data: {},
        },
        subdivision: 'RU-MOS',
        isStreetEditing: false,
        suggestions: [],
        apartment: '',
        entrance: '',
        floor: '',
        email: '',
        /*
            telephone, stree.value, email
            null как initial value нужен для того, чтобы в getDerivedStateFromProps можно было установить
            значение из профиля пользователя при первом рендере и не путать с пустой строкой,
            если пользователь очистит инпут
        */
        printed_recipes: false,
        table_setting: false,
        telephone: null,
        day: null,

        // TODO Удалить! Оставил на случай если где то остался старый код который использует это поле.
        mkad: 'init',

        isTimeChange: false,
        errors: null,
        showDateTooltip: false,
        periodForDay: null,
        isCheckOpen: false,
        emailValid: null,
        hiddenElements: {},
        isAddresIncompleteNotificationOpen: false,
        isContactlessDeliveryActivated: false,

        isFoodCardFormShown: false,

        // NOTE: Костыль / значение для синхронизации addressValue из checkout с delivery zone popup
        isAddressInited: false,
    };

    isInitiatePurchaseEventSent = false;

    isAnalyticDataResived = false;

    isAppAnalyticDataResived = false;

    componentDidMount() {
        const { userId, setIsCustomizationIgnored } = this.props;

        this.fillStateFromCache();

        this.setState({
            telephone: sessionStorage.getItem('phone'),
        }, () => {
            this.handleBlurTelephone();
        });

        /**
         * @description кейс устанавливает данные адреса по дефолту если user уже есть
         * @note если при логине из checkout'а отрабатывает componentDidUpdate
        */
        if (userId) {
            const { deliveryAddress, lastDeliveredAddress, checkoutDispatch } = this.props;

            const initialAddressData = getInitialStreetFormValue(
                deliveryAddress?.address,
                lastDeliveredAddress,
            );

            this.checkStreet({ value: initialAddressData.street.value });
            this.setState({ ...initialAddressData });
        }

        const { authData } = this.props;
        const { userData: user } = authData;

        const addPaperRecipeFromBasket = sessionStorage.getItem('addPaperRecipe');
        const paperRecipeFromUser = user ? user?.printedRecipes : false;
        const paperRecipeInitialState = addPaperRecipeFromBasket !== null
            ? JSON.parse(addPaperRecipeFromBasket)
            : paperRecipeFromUser;
        this.setState({ printed_recipes: paperRecipeInitialState });
        sessionStorage.setItem('addPaperRecipe', paperRecipeInitialState);

        const {
            match: { path },
        } = this.props;

        if (path.includes('/pay')) {
            this.setState({
                currentView: 'paymentPage',
            }, this.handlePaymentPage);
        }

        const withCustomization = sessionStorage.getItem('with_customization');
        const isCustomizationEdited = sessionStorage.getItem('is_customization_edited') !== null;
        if (withCustomization !== null && !isCustomizationEdited) {
            setIsCustomizationIgnored(true);
        }
    }

    componentDidUpdate(prevProps) {
        try {
            const {
                dispatchOverlayContext: { closeLastOverlays },
                basketQuery: { cart },
                userQuery: { user },
                paymentMethod: { paymentMethod },
                deliveryAddress: { address: deliveryAddress },
                checkoutDateAndTimeState,
                checkoutDateAndTimeState: {
                    isNeedToShowDialog,
                    selectedDeliveryDate,
                    isNeedToShowAddressDialog,
                },
                checkoutDispatch,
                userId,
            } = this.props;

            const {
                basketQuery: { cart: prevCart },
                userId: prevUserId,
                deliveryAddress: prevDeliveryAddress,
            } = prevProps;

            /**
             * @description кейс устанавливает данные адреса по дефолту если user логинится из checkout'а
             * @note если юзер уже есть от отрабатывает componentDidMount
            */
            if (userId && prevUserId !== userId) {
                const { lastDeliveredAddress, deliveryAddress } = this.props;

                const initialAddressData = getInitialStreetFormValue(
                    deliveryAddress?.address,
                    lastDeliveredAddress,
                );

                this.checkStreet({ value: initialAddressData.street.value });
                this.setState({ ...initialAddressData, isAddressInited: true });
            }

            if (cart && cart.sections && !this.isAnalyticDataResived) {
                analyticService.push({
                    eventName: 'Track_Navigate',
                    step: 2,
                    sections: cart.sections,
                    totals: cart.totals,
                    typeOfSet: cart.typeOfSet,
                });
                this.isAnalyticDataResived = true;
            }

            const isAllFieldsChecked = getAllFormFieldChecked(this.state);

            if (cart && cart.sections && isAllFieldsChecked && !this.isAppAnalyticDataResived) {
                analyticService.push({
                    eventName: 'Track_Navigate',
                    step: 4,
                    sections: cart.sections,
                    totals: cart.totals,
                    typeOfSet: cart.typeOfSet,
                });
                this.isAppAnalyticDataResived = true;
            }

            this.trackInitiatePurchaseEvent();

            /**
             * @description case открывает диалог если даты доставки в другом периоде
             */
            if (
                checkoutDateAndTimeState?.regionId
                && isNeedToShowDialog
            ) {
                const isNotifyOnly = true;
                const handler = () => {
                    closeLastOverlays();
                    this.handleChangeDateConfirm(selectedDeliveryDate);
                    this.handleSelectDay(selectedDeliveryDate);
                    checkoutDispatch({
                        type: 'SET_DATE_DIALOG_FLAG',
                        payload: { isNeedToShowDialog: false },
                    });
                };
                this.displayDatesConfirmDialog(handler, isNotifyOnly);
            }

            // TODO: Убрать в дерикторию hooks
            if (
                deliveryAddress.street
                && isNeedToShowAddressDialog
            ) {
                this.displayDatesAddressDialog();
            }

            if (cart) {
                const { totals: { static_common_price: price } } = cart;
                if (price === 0 && paymentMethod !== 'cash') {
                    this.handleSelectPayment('cash', SUBSCRIPTION_TYPES.singlePay);
                }
            }

            if (cart && prevCart) {
                const { totals: { total_discount_price: prevTotal } } = prevCart;
                const { totals: { total_discount_price: total } } = cart;
                if (total !== prevTotal) {
                    // eslint-disable-next-line react/no-did-update-set-state
                    this.setState({ isPriceChanged: true });
                }
            }

            if (this.state.isAddressInited) {
                if (prevDeliveryAddress.address.addressValue !== deliveryAddress.addressValue) {
                    this.setState({
                        // eslint-disable-next-line react/destructuring-assignment
                        street: {
                            value: deliveryAddress.addressValue,
                            data: {
                                ...this.props.deliveryAddress.address,
                            },
                        },
                    });
                }
            }

            const { telephone } = this.state;
            const sessionPhone = sessionStorage.getItem('phone');

            if (user && !telephone && sessionPhone) {
                this.setState({
                    telephone: sessionPhone,
                }, () => {
                    this.handleBlurTelephone();
                });
            }
        } catch (e) {
            Raven.captureException(e);
        }
    }

    componentWillUnmount() {
        const { onWillUnmount } = this.props;
        const cacheData = pick(this.state, [
            'telephone', 'email', 'day', 'timeIndex',
            'timeIntervalsInsideBetlwayTitle', 'timeIntervalsOutsideBetlwayTitle', 'subdivision',
            'isContactlessDeliveryActivated',
        ]);
        const telephone = cacheData.telephone ? getDigitsFromPhone(cacheData.telephone) : null;

        onWillUnmount({
            ...cacheData,
            telephone,
        });
    }

    trackInitiatePurchaseEvent() {
        const {
            basketQuery: { cart },
            /* eslint-disable-next-line */
        } = this.props;

        if (this.isInitiatePurchaseEventSent) return;
        if (!cart) return;

        this.isInitiatePurchaseEventSent = true;
        analyticService.push({
            eventName: 'Click_To_Checkout_Button',
            price: cart.totals.total_common_price,
            dishes: getDishesFromBasketSections(cart.sections),
        });
    }

    handlePaymentPage = async () => {
        const {
            client,
            history,
        } = this.props;

        const { uid } = queryString.parse(history.location.search);

        const { data: { paymentData } } = await client.query({
            query: paymentDataQuery,
            variables: { uid },
            fetchPolicy: 'network-only',
        });

        const {
            uid: invoiceId,
            price,
            cp_account_id: accountId,
        } = paymentData;

        const paymentInfo = {
            price,
            invoiceId,
            accountId,
        };

        // const paymentInfo = {
        //     price: 1000,
        //     invoiceId: Math.ceil(Math.random() * 10000000),
        //     accountId: Math.ceil(Math.random() * 10000000),
        // };

        this.setState({ paymentInfo }, this.handleOpenPaymentWidgetForPage);
    };

    handleSelectPayment = async (paymentMethod, nextType) => {
        const {
            updateBilling,
            basketQuery,
        } = this.props;

        const { telephone } = this.state;
        const phoneOnlyNumbers = telephone ? getDigitsFromPhone(telephone) : '';

        paymentMethodVar(paymentMethod);
        subscriptionTypeVar(nextType);

        try {
            await updateBilling({
                variables: {
                    payment_method: isSubscription(nextType) ? 'recurrent' : paymentMethod,
                    phone: phoneOnlyNumbers,
                },
            });
            await basketQuery.refetch();
        } catch (e) {
            Raven.captureException(e);
        }
    };

    handleSelectStreet = (street) => {
        this.setState({
            street,
            isStreetEditing: true,
        }, async () => {
            await this.checkStreet(street);
        });
    };

    handleBlurStreet = () => {
        const res = this.validateStreet();
        const result = res?.streetErrorText ? 'failure' : 'success';
        if (isDesktop()) {
            analyticService.push({
                eventName: 'Checkout_Close_Address_Popup',
                eventLocation: '/checkout',
                result,
            });
        }
    };

    checkStreet = async (street) => {
        if (street && street.data && !street.data.house) {
            this.validateStreet();
        }

        const { suggestions } = await checkSuggestion({
            query: street.value,
        });


        if (suggestions.length > 0) {
            this.changeAddressNotificationState(suggestions[0].data);
            await this.setShippingAddress();
        } else {
            this.validateStreet();
        }
    };

    changeAddressNotificationState = (addressData) => {
        const {
            house_kladr_id: houseKladrId,
            house_fias_id: houseFiasId,
            region_iso_code: regionIsoCode,
        } = addressData;

        /* FRNT-1589 */
        const isSPBRegion = regionIsoCode === 'RU-SPE' || regionIsoCode === 'RU-LEN';

        if (isSPBRegion) {
            this.setState({ isAddresIncompleteNotificationOpen: false });
            return;
        }

        if (!houseFiasId || !houseKladrId) {
            this.setState({ isAddresIncompleteNotificationOpen: true });
            sessionStorage.setItem('isAddressCorrect', 'failure');
        } else {
            sessionStorage.setItem('isAddressCorrect', 'success');
            this.setState(({ isAddresIncompleteNotificationOpen }) => {
                if (isAddresIncompleteNotificationOpen) return { isAddresIncompleteNotificationOpen: false };
                return null;
            });
        }
    };

    setShippingAddress = async () => {
        // TODO: Исправить валидацию адреса
        const {
            street: address,
            apartment,
            entrance,
            floor,
        } = this.state;

        const {
            updateBilling,
            shipping,
            basketQuery,
            selectedFilters: { selectedPeriod },
        } = this.props;

        try {
            const variables = {
                period: selectedPeriod,
                address: {
                    address: address.value || '',
                    entrance,
                    flat: apartment,
                    floor,
                    comment: this.props.deliveryAddress.address.comment,
                },
            };

            await shipping({ variables });
            await updateBilling();

            await basketQuery.refetch();

            this.setState({
                isStreetEditing: false,
            });
        } catch (e) {
            Raven.captureException(e);
        }
    };

    getSuggestions = async (value) => {
        const suggestions = await requestSuggestions({ query: value });

        this.setState({
            suggestions: suggestions.suggestions,
        });
    };

    // eslint-disable-next-line react/sort-comp
    debouncedGetSuggestion = throttle(this.getSuggestions, DEBOUNCE_TIME);

    switchToSingleStepCheckout = (checkoutType) => {
        const { switchToSingleStepCheckout } = this.props;
        switchToSingleStepCheckout(checkoutType);
    };

    handleChangeStreet = (value) => {
        this.setState((prevState) => ({
            ...prevState,
            isStreetEditing: true,
            street: {
                value,
                data: {}, // reset data
            },
            errors: null, // TODO: test
        }), () => this.debouncedGetSuggestion(value));
    };


    handleChangeApartment = (e) => {
        const value = typeof e === 'string' ? e : e.target.value;

        const regex = /([^\d\wa-я])+/gi; // рег для поиска всех НЕ чисел и букв
        const processedValue = value.replace(regex, '');

        this.setState({
            apartment: processedValue,
        }, async () => {
            // TODO: вызывать только на blur из инпута
            await this.setShippingAddress();
        });
    };

    handleChangeEntrance = (e) => {
        const value = typeof e === 'string' ? e : e.target.value;

        const regex = /([^\d\wa-я])+/gi; // рег для поиска всех НЕ чисел и букв
        const processedValue = value.replace(regex, '');

        this.setState({
            entrance: processedValue,
        }, async () => {
            // TODO: вызывать только на blur из инпута
            await this.setShippingAddress();
        });
    };

    handleChangeFloor = (e) => {
        const value = typeof e === 'string' ? e : e.target.value;
        if (!Number(value) && value !== '') {
            return;
        }
        this.setState({
            floor: value,
        }, async () => {
            // TODO: вызывать только на blur из инпута
            await this.setShippingAddress();
        });
    };

    handleChangeEmail = (e) => {
        const { emailValid } = this.state;
        const { target: { value } } = e;

        this.setState({
            email: value,
        }, () => {
            if (emailValid !== null && !emailValid) {
                this.validateEmail();
            }
        });
    };

    handleBlurEmail = () => {
        this.validateEmail();
    };

    applyPromocodeHandler = async ({ nextPromocode }) => {
        const {
            updateBilling,
            basketQuery,
        } = this.props;

        promocodeVar(nextPromocode);
        await updateBilling({
            variables: {
                promocode: nextPromocode,
            },
        });

        const { data: { cart } } = await basketQuery.refetch();
        const { discount_conditions: discountConditions } = cart;

        const {
            promocode,
            isPromocodeCombineWithDiscount,
            isPromoCodeExists,
            isSubscriptionActivated,
        } = discountConditions;


        analyticService.push({
            isPromoCodeExists,
            isPromocodeCombineWithDiscount,
            isSubscriptionActivated,
            promo: promocode,
            eventName: 'Check_Send_Promo',
            sourceName: 'checkout',
        });

        return {
            cart,
            promocodeConditions: discountConditions,
        };
    };

    handleBlurTelephone = async () => {
        const {
            history,
            location,
            updateBilling,
            basketQuery,
            basketQuery: { cart },
            paymentMethod: { paymentMethod },
            subscriptionTypeQuery: { subscriptionType },
        } = this.props;

        const { telephone } = this.state;
        this.validateTelephone();
        if (cart && cart?.sections) {
            analyticService.push({
                eventName: 'Track_Navigate',
                step: 3,
                sections: cart.sections,
                typeOfSet: cart.typeOfSet,
            });
        }

        const phoneOnlyNumbers = telephone ? getDigitsFromPhone(telephone) : '';
        const hasPhone = phoneOnlyNumbers.length === 11;
        if (hasPhone) {
            history.replace({
                ...location,
                hash: 'setPhoneNumber', // используется для триггера события в аналитике
            });

            try {
                await updateBilling({
                    variables: {
                        payment_method: isSubscription(subscriptionType) ? 'recurrent' : paymentMethod,
                        phone: phoneOnlyNumbers,
                    },
                });
                await basketQuery.refetch();

                const resp = await this.pushLead();
                const { data: { pushLead: leadData } } = resp;

                analyticService.push({
                    eventName: 'Submit_Phone_Number',
                    source: 'checkout_onepager',
                    userType: leadData.isNewbie ? 'new' : 'old',
                });
            } catch (e) {
                Raven.captureException(e);
            }
        }
    };

    /**
        @param selectOptions.triggerSelect - нужен для того, чтобы отличать выбор даты,
            сделанный пользователем, от вызова handleSelectDay из кода
        @param selectOptiions.skipRefetching
    */
    handleSelectDay = async (day, selectOptions = { triggerSelect: true, skipRefetching: false }) => {
        const {
            filterQuery: {
                menuFilter: {
                    periods,
                },
            },
            selectedFilters: {
                selectedPeriod,
            },
            onSelectDeliveryDate,

            updateBilling,
            basketQuery,

            checkoutDispatch,
            fetchTimeIntervalWithDateData,
            closeAllOverlays,
            pushNoIntervalDateDialog,
            setIsCustomizationIgnored,
        } = this.props;

        checkoutDispatch({
            type: 'SELECT_DELIVERY_DATE',
            payload: { day },
        });

        const { street } = this.state;

        const nextState = { isDayChangedAnimation: true };

        const selectedDate = new Date(day);

        // note: тут запрашиваются интервалы
        const { data } = await fetchTimeIntervalWithDateData({ variables: { date: day } });
        /**
          * @case доставка growfood на этот день недоступна
          */
        if (
            !data?.deliveryIntervals?.has_evening_deliveries
            && !data?.deliveryIntervals?.has_morning_deliveries
        ) {
            pushNoIntervalDateDialog();
        } else {
            closeAllOverlays();
            const periodForDay = periods.find(({ start, end }) => {
                const startDate = new Date(start);
                const endDate = new Date(end);
                return selectedDate >= startDate && selectedDate <= endDate;
            });

            if (!periodForDay) {
                /**
                 * @depr Выбрана дата доставки больше, чем максимальный период
                 * Выбранная дата доставки в мезозойском периоде.
                 * Тут надо бы кидать @exception, но во времена динозавров это было сложно сделать
                 */
                nextState.periodForDay = null;
            } else {
                /* Выбранная дата доставки */
                const periodStart = periodForDay.start;

                if (periodStart === selectedPeriod) {
                    /* Выбранная дата доставки в текущем периоде */
                    nextState.periodForDay = null;
                } else {
                    /* Выбранная дата доставки в следующем периоде */
                    nextState.periodForDay = periodStart;
                    setIsCustomizationIgnored(false);
                }
            }
            this.setState(nextState);

            this.checkStreet(street);

            if (!selectOptions.skipRefetching) {
                try {
                    await updateBilling();
                    await basketQuery.refetch();
                    await onSelectDeliveryDate(day);
                } catch (e) {
                    Raven.captureException(e);
                }
            }
        }
    };

    // TODO: Убрать в директорию hooks
    displayDatesConfirmDialog = (onConfirm, isNotifyOnly = false) => {
        const {
            dispatchOverlayContext: { pushDialogOverlay },
        } = this.props;

        const handleConfirm = () => {
            onConfirm();
        };

        const basicConfirmText = 'Да';
        const notifyOnlyConfirmText = 'Ok, понятно';
        const confirmText = isNotifyOnly ? notifyOnlyConfirmText : basicConfirmText;

        const dialogData = {
            notifyOnly: isNotifyOnly,
            strongText: 'На этой неделе другое меню! Заполним корзину по аналогии.',
            regularText: isNotifyOnly ? '' : 'Точно выбрать эту дату?',
            confirmText,
            rejectText: 'Нет',
            onConfirm: handleConfirm,
        };

        pushDialogOverlay('dates_confirm_dialog_checkout', dialogData);
    };

    // TODO: Убрать в дерикторию hooks
    displayDatesAddressDialog = () => {
        const {
            checkoutDispatch,
            dispatchOverlayContext: { pushDialogOverlay, closeLastOverlays },
        } = this.props;

        const dialogData = {
            notifyOnly: true,
            strongText: (
                <>
                    Для&nbsp;введенного адреса нет&nbsp;доступных дат&nbsp;доставки
                </>
            ),
            confirmText: 'Изменить адрес',
            onConfirm: () => {
                this.setState({
                    street: {
                        value: '', // ''
                        data: {},
                    },
                });
                checkoutDispatch({
                    type: 'SET_ADDRESS_DIALOG_FLAG',
                    payload: { isNeedToShowDialog: false },
                });
                closeLastOverlays();
                this.switchToSingleStepCheckout(TYPE_CHECKOUT_STEP_BY_STEP);
            },
        };

        pushDialogOverlay('address_confirm_dialog_checkout', dialogData);
    };

    handleChangeDateConfirm = async (day) => {
        const {
            addItemsToCartByPeriod,
            filterQuery: {
                menuFilter: {
                    periods,
                },
            },
            selectedFilters: {
                selectedPeriod,
                selectedTags,
            },
            basketQuery,
            fetchDeliveryDaysData,
            updateBilling,
        } = this.props;

        const selectedDate = new Date(day);
        const periodForDay = periods.find(({ start, end }) => {
            const startDate = new Date(start);
            const endDate = new Date(end);
            return selectedDate >= startDate && selectedDate <= endDate;
        });

        if (periodForDay) {
            const periodStart = periodForDay.start;

            try {
                await updateBilling();

                await addItemsToCartByPeriod({
                    variables: {
                        period: periodStart,
                        fillByPeriod: selectedPeriod,
                        tags: selectedTags,
                    },
                });

                pickPeriodReaction({ periodStart });

                await basketQuery.refetch();
                await fetchDeliveryDaysData();
            } catch (e) {
                Raven.captureException(e);
            }
        }
    };

    handleToggleContactlessDelivery = () => {
        this.setState(({ isContactlessDeliveryActivated }) => {
            const next = !isContactlessDeliveryActivated;
            return { isContactlessDeliveryActivated: next };
        });
    };

    getValidationDataFromState = () => {
        const {
            telephone,
            street,
            errors,
        } = this.state;

        const streetError = errors && errors.street;
        const streetHasData = street.value && street.data.house;
        const streetIsValid = streetHasData && !streetError;

        const phoneOnlyNumbers = telephone ? getDigitsFromPhone(telephone) : '';
        const phoneIsValid = phoneOnlyNumbers.length === 11;

        return {
            isValid: streetIsValid && phoneIsValid,
            errors: {
                street: !streetIsValid,
                phone: !phoneIsValid,
            },
        };
    };

    validateTelephone = (options = {}) => {
        const { forceErrorState } = options;
        const { telephone } = this.state;
        if (!forceErrorState && (telephone === null || telephone === '+7 ___ ___-__-__')) {
            return;
        }
        const phoneOnlyNumbers = telephone ? getDigitsFromPhone(telephone) : '';
        const phoneIsValid = phoneOnlyNumbers.length === 11;

        if (phoneIsValid) {
            this.setState((prevState) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    phone: false,
                },
            }));
        } else {
            this.setState((prevState) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    phone: true,
                },
            }));
        }
    };

    validateEmail = () => {
        const { email } = this.state;
        if (email === null || email === '') {
            return;
        }
        const emailIsValid = email !== '' && isEmail(email);

        if (emailIsValid) {
            this.setState({ emailValid: true });
        } else {
            this.setState({ emailValid: false });
        }
    };

    /**
     * @note на случайно если коммент приходит c времен A/B теста в виде JSON
     * @task BR-1042
     */
    nomalizeAndMutateComment = async () => {
        const {
            basketQuery: { cart },
        } = this.props;

        const { comment } = client.readFragment({
            id: 'viewCustomizationCommentType:session_customization_comment',
            fragment: CUSTOMIZATION_COMMENT_FRAGMENT,
        });

        if (checkIsJSONComment(comment)) {
            const formatted = formatCommentForCustomizers(comment, cart);
            await client.mutate({
                mutation: MUTATE_CUSTOMIZATION_COMMENT,
                variables: {
                    comment: formatted,
                },
            });

            return { isJSONComment: true };
        }

        return { isJSONComment: false };
    };

    handlePay = async () => {
        const {
            isStreetEditing,
        } = this.state;

        const { isValid } = this.getValidationDataFromState();

        if (!isValid) {
            this.validateTelephone({ forceErrorState: true });
            this.validateStreet({ forceErrorState: true });
        }
        if (isValid && !isStreetEditing) {
            const { isJSONComment } = await this.nomalizeAndMutateComment();
            sessionStorage.removeItem('is_customization_edited');
            this.createInvoice({ isJSONComment });
        }
    };

    createInvoice = async ({ isJSONComment }) => {
        const {
            email,
            street,
            apartment,
            floor,
            entrance,
            telephone,
            subdivision,
            isContactlessDeliveryActivated,
            printed_recipes,
            table_setting,
        } = this.state;

        const {
            deliveryDates,
            deliveryIntervals,
            shipping,
            updateBilling,
            createInvoice,
            basketQuery,
            selectedFilters: { selectedPeriod, selectedTags },
            subscriptionTypeQuery: {
                subscriptionType,
            },
            paymentMethod: { paymentMethod },

            userQuery,
            sessionCommentQuery,
            deliveryAddress: { address: { comment } },
            checkoutDateAndTimeState: {
                selectedDeliveryDate,
                regionId,
                timeIndex,
            },
            isCustomizationIgnored,
        } = this.props;

        analyticService.push({ eventName: 'Payment_Button_Click' });

        let variables;
        let phoneOnlyNumbers;
        let deliveryDate;
        let invoicePaymentMethod;
        let isSubscriptionEnabled;

        try {
            phoneOnlyNumbers = getDigitsFromPhone(telephone);
            deliveryDate = selectedDeliveryDate || parseAvailableDates(deliveryDates, subdivision, street)[0];

            const activeTime = deliveryIntervals[timeIndex];
            const hourTo = activeTime.minute_to === 59 ? activeTime.hour_to + 1 : activeTime.hour_to;

            const address = {
                city: street.data.city,
                street: street.value,
                house: street.data.house,
                flat: apartment,
                entrance,
            };
            isSubscriptionEnabled = isSubscription(subscriptionType);
            // TODO: move recurrent to consts
            invoicePaymentMethod = isSubscriptionEnabled ? 'recurrent' : paymentMethod;

            const utmParams = getUTMParams();

            const basketDishesIds = getBasketDishesIds(basketQuery.cart);

            variables = {
                phone: phoneOnlyNumbers,
                period: selectedPeriod,
                tags: selectedTags,
                delivery_date: deliveryDate,
                delivery_time: {
                    hour_from: activeTime.hour_from,
                    hour_to: hourTo,
                },
                delivery_address: address,
                payment_method: invoicePaymentMethod,
                contactless_delivery: isContactlessDeliveryActivated,
                utm: utmParams,
                printed_recipes,
                table_setting,
                delivery_region_name: regionId,
                with_customization: !isCustomizationIgnored,
                apply_comment_to_delivery: isJSONComment,
            };
        } catch (error) {
            Raven.captureMessage('createInvoice preparation error', {
                extra: {
                    error,
                    deliveryIntervals,
                    timeIndex,
                },
            });
            analyticService.push({ eventName: 'Payment_Button_Click_Error' });
        }

        const shippingVars = {
            period: selectedPeriod,
            address: {
                address: street.value || '',
                entrance,
                flat: apartment,
                floor,
                comment,
            },
        };

        await shipping({ variables: shippingVars });
        await updateBilling();

        try {
            const {
                data: {
                    createInvoice: {
                        price,
                        uid: invoiceId,
                        cp_account_id: accountId,
                        confirm_state: invoiceState,
                        label,
                        isNewbie,
                    },
                },
            } = await createInvoice({ variables });

            const leadData = await this.pushLead();
            const isFirstLead = (leadData.data && leadData.data.pushLead)
                ? leadData.data.pushLead.isFirstLead
                : false;

            const userType = isNewbie ? 'new' : 'old';

            const dishesData = getDishesFromBasketSections(basketQuery.cart.sections);

            const trackData = {
                invoiceData: {
                    uid: invoiceId,
                    price,
                    shipping: basketQuery.cart.totals.delivery_price,
                    coupon: basketQuery.cart.promocode,
                    userData: userQuery.user,
                    userType,
                    trialScenario: getTrialScenarioFromUrl(),
                    phone: phoneOnlyNumbers,
                    email,
                    isFirstLead,
                },
                typeOfSet: basketQuery.cart.typeOfSet,
                dishes: dishesData,
                external_subscription_id: label,
                user_id: userQuery.user.id,
            };

            const flocktoryTrackData = {
                user: {
                    name: userQuery.user ? userQuery.user.name : null,
                    email: email || `${phoneOnlyNumbers}@unknown.email`,
                    user_id: userQuery.user.id,
                },
                order: {
                    id: invoiceId,
                    price,
                    items: dishesData.map((d) => {
                        const {
                            id, title, caption, itemPrice, previewImage, portion,
                        } = d;
                        return {
                            id,
                            title: `${title} ${caption}`,
                            price: itemPrice,
                            image: previewImage,
                            count: portion,
                        };
                    }),
                },
                spot: window.location.pathname,
            };

            analyticService
                .push({ eventName: 'setUserId', userId: accountId })
                .push({ eventName: 'Invoice_Created', invoiceId })
                .push({ eventName: 'Track_Checkout_User_TYP', userType })
                .push({ eventName: 'Checkout_Sent', ...trackData })
                .push({ eventName: 'Checkout_First_Payment', ...trackData });

            sessionCommentQuery.refetch();
            if (userQuery.user) {
                await userQuery.refetch();
            }
            /**
             * @yields отправка события purchase => переход на страницу /thx <navigateToThxPage>
             */
            if (paymentMethod === 'sbp') {
                cacheInvoiceData({
                    uid: invoiceId,
                    phone: phoneOnlyNumbers,
                    invoiceState,
                    deliveryDate,
                    invoicePaymentMethod,
                    orderDate: (new Date()).toISOString(),
                    // eslint-disable-next-line react/prop-types
                    lastOrderCashbackReward: basketQuery?.data?.cart?.totals?.cashbackReward || 0,
                });
                /**
                 * @note Отправка события purchase при оплате налом или фудкартой
                 */
                analyticService
                    .push({ eventName: 'Track_Purchase', ...trackData })
                    .push({ eventName: 'Flocktory_Postcheckout', eventParams: flocktoryTrackData })
                    .push({ eventName: 'CityAds_Purchase', ...trackData });

                if (isDesktop()) {
                    this.navigateToThxPage();
                } else {
                    this.props.history.push('/profile');
                }
            } else {
                paymentService.showWidget({
                    paymentMethod: isSubscriptionEnabled ? 'recurrent' : 'online',
                    invoiceId,
                    price,
                    accountId,
                    email,
                    needСreateSubscription: isSubscriptionEnabled,
                    startDate: getNextDate(deliveryDate),
                    onSuccess: async () => {
                        cacheInvoiceData({
                            uid: invoiceId,
                            phone: phoneOnlyNumbers,
                            invoiceState,
                            deliveryDate,
                            invoicePaymentMethod,
                            orderDate: (new Date()).toISOString(),
                            // eslint-disable-next-line react/prop-types
                            lastOrderCashbackReward: basketQuery?.data?.cart?.totals?.cashbackReward || 0,
                        });
                        /**
                         * @note Отправка события purchase при оплате через cloudPayments
                         */
                        analyticService
                            .push({ eventName: 'Track_Purchase', ...trackData })
                            .push({ eventName: 'Flocktory_Postcheckout', eventParams: flocktoryTrackData })
                            .push({ eventName: 'CityAds_Purchase', ...trackData });

                        // this.navigateToThxPage();
                        basketQuery.refetch();
                        isDesktop() ? this.navigateToThxPage() : this.props.history.push('/profile');
                    },
                    onFail: () => {
                        const paymentInfo = {
                            price,
                            invoiceId,
                            accountId,
                            isSubscriptionEnabled,
                            deliveryDate,
                        };
                        this.setState({ currentView: 'onlyPaymentButton', paymentInfo });
                        basketQuery.refetch();
                    },
                });
            }
        } catch (e) {
            if (e.message === 'Total price in cart cannot be 0') {
                const { pushBasketErrorDialog } = this.props;
                pushBasketErrorDialog();
            }
            Raven.captureException(e);
            Raven.captureMessage('createInvoice error', {
                extra: { error: e },
            });
        }
    };

    togglePaperRecipeSwitch = (payload) => {
        const { setIsPaperRecipeChanged } = this.props;
        this.setState({ printed_recipes: payload });
        sessionStorage.setItem('addPaperRecipe', payload);
        setIsPaperRecipeChanged(true);
    };

    toggleTableSettingSwitch = (payload) => {
        this.setState({ table_setting: payload });
    };

    handleOpenPaymentWidget = () => {
        const { paymentInfo, email } = this.state;
        const {
            price,
            invoiceId,
            accountId,
            isSubscriptionEnabled,
            deliveryDate,
        } = paymentInfo;

        paymentService.showWidget({
            paymentMethod: isSubscriptionEnabled ? 'recurrent' : 'online',
            invoiceId,
            price,
            accountId,
            email,
            needСreateSubscription: isSubscriptionEnabled,
            startDate: getNextDate(deliveryDate),
            onSuccess: () => {
                this.navigateToThxPage();
            },
            onFail: () => { },
        });
    };

    handleOpenPaymentWidgetForPage = () => {
        const { subscriptionTypeQuery: { subscriptionType } } = this.props;

        const { paymentInfo, email } = this.state;
        const {
            price,
            invoiceId,
            accountId,
        } = paymentInfo;

        const paymentMethodBySubscriptionType = {
            singlePay: 'online',
            sbpSinglePay: 'sbp',
        };

        const paymentMethod = subscriptionType in paymentMethodBySubscriptionType
            ? paymentMethodBySubscriptionType[subscriptionType]
            : 'recurrent';

        paymentService.showWidget({
            paymentMethod,
            needСreateSubscription: subscriptionType === 'subscription',
            invoiceId,
            price,
            email,
            accountId,
            onSuccess: () => {
                this.navigateToThxPage();
            },
            onFail: () => { },
        });
    };

    navigateToThxPage = async () => {
        const {
            history,
        } = this.props;

        promocodeVar('');

        const thxSearch = {
            ...getUTMParams(),
            pay_source: 'checkout',
        };

        history.push({
            pathname: SPA_THANKS.href,
            search: queryString.stringify(thxSearch),
        });
    };

    pushLead = async (options = {}) => {
        const {
            pushLead,
            selectedFilters: { selectedPeriod },
        } = this.props;

        const {
            day,
            email,
            street,
            floor,
            apartment,
            entrance,
            telephone,
        } = this.state;

        const phone = options.variables?.phone || telephone;

        const data = await pushLead({
            variables: {
                phone: getDigitsFromPhone(phone),
                email,
                address: {
                    value: street.value,
                    flat: apartment,
                    floor,
                    entrance,
                },
                date: day || selectedPeriod,
            },
        }, {
            version: 2,
        });

        return data;
    };

    getStreetValidationState = (street) => {
        const { localeContext: { locale } } = this.props;

        const validationState = {
            street: false,
            streetErrorText: '',
        };
        const streetIsValid = !!street.data.house && street.value.trim() !== '';
        const isDeliveryOutsideZone = Number(street.data.beltway_distance) > MAX_BELTWAY_DISTANCE;
        const isAddressWrong = isEmpty(street.data) && (street.value && street.value.trim() !== '');
        const isHouseEmpty = !street.data.house && (street.value && street.value.trim() !== '');
        const isAddressEmpty = isEmpty(street.data) && !street.value;

        const inputHasError = !streetIsValid
            || isAddressWrong
            || isDeliveryOutsideZone
            || isAddressEmpty
            || isHouseEmpty;

        if (inputHasError) {
            validationState.street = inputHasError;
            if (isDeliveryOutsideZone) {
                validationState.streetErrorText = locales[locale].addressErrorOutside;
            } else if (isAddressEmpty) {
                validationState.streetErrorText = locales[locale].addressErrorEmpty;
            } else if (isAddressWrong) {
                validationState.streetErrorText = locales[locale].addressErrorWrong;
            } else if (isHouseEmpty) {
                validationState.streetErrorText = locales[locale].addressErrorHouse;
            }
        }
        return validationState;
    };

    validateStreet = (options = {}) => {
        const { forceErrorState } = options;
        const { street } = this.state;
        if (!forceErrorState && (street.value === null || street.value === '')) {
            return null;
        }

        const nextValidationState = this.getStreetValidationState(street);

        this.setState((prevState) => ({
            errors: {
                ...prevState.errors,
                ...nextValidationState,
            },
        }));

        return nextValidationState;
    };

    fillStateFromCache() {
        const {
            checkoutData,
            selectedFilters: { selectedPeriod },
            onSelectDeliveryDate,
        } = this.props;

        if (isEmpty(checkoutData)) return;

        const periodStart = new Date(selectedPeriod);
        const periodEnd = addDays(new Date(selectedPeriod), 7);
        const deliveryDate = new Date(checkoutData.day);
        const isDeliveryDateInsidePeriod = deliveryDate < periodEnd && deliveryDate > periodStart;

        const nextDeliveryDay = isDeliveryDateInsidePeriod ? checkoutData.day : null;

        const nextState = {
            ...checkoutData,
            street: {
                value: checkoutData.street,
                data: {},
            },
            day: nextDeliveryDay,
        };

        if (nextDeliveryDay) {
            onSelectDeliveryDate(nextDeliveryDay);
        }

        this.setState({ ...nextState }, async () => {
            if (checkoutData.street) {
                const { suggestions: [suggest] } = await checkSuggestion({ query: checkoutData.street, count: 1 });
                if (suggest) {
                    this.setState({
                        street: {
                            value: checkoutData.street,
                            data: suggest.data,
                        },
                    });
                }
            }
        });
    }

    /* RENDER */

    renderCheckoutLoading = () => {
        const { localeContext: { locale } } = this.props;

        return (
            <div styleName="basket__empty-wrap">
                <div styleName="basket__empty">
                    <div styleName="basket__empty-title" data-test-id="basket__empty-title">
                        {locales[locale].loading}
                    </div>
                </div>
            </div>
        );
    };

    renderOnlyPaymentButton = () => {
        const { localeContext: { locale } } = this.props;

        return (
            <div styleName="basket__empty-wrap">
                <div styleName="basket__empty">
                    <UIButton
                        // styleName="basket__button"
                        onClick={this.handleOpenPaymentWidget}
                        data-test-id="basket__button"
                    >
                        {locales[locale].payTextCard}
                    </UIButton>
                </div>
            </div>
        );
    };

    renderOnlyPaymentButtonForPaymentPage = () => {
        const { localeContext: { locale } } = this.props;

        return (
            <div styleName="basket__empty-wrap">
                <div styleName="basket__empty">
                    <UIButton
                        type="button"
                        // styleName="basket__button"
                        onClick={this.handleOpenPaymentWidgetForPage}
                        data-test-id="basket__button"
                    >
                        {locales[locale].payTextCard}
                    </UIButton>
                </div>
            </div>
        );
    };

    renderStepByStepLayout = () => {
        const {
            checkoutType,
            closeCheckout,
            subscriptionTypeQuery,

            localeContext: { locale },
            userQuery,
            paymentMethod: { paymentMethod },
            deliveryIntervals,
            deliveryDates,
            basketQuery: {
                cart: {
                    totals: {
                        delivery_price: deliveryPrice,
                        static_common_price: staticCommonPrice,
                    },
                    haveDishesFromTrial,
                    discount_conditions: discountConditions,
                },
                cart,
            },
            hiddenElements: {
                payment_button_online: hideOnline,
                payment_button_offline: hideCash,
            },
            filterQuery: { menuFilter },
            selectedFilters: { selectedPeriod },
            onClickSubscriptionInfoOpen,
            handleOpenDeliveryPricePopup,
            setIsAddressChanged,
            isAddressPopupNeeded,
            setIsCustomizationIgnored,
        } = this.props;

        const {
            apartment, entrance, street, suggestions, floor,
            timePeriod,
            isContactlessDeliveryActivated,
            isAddresIncompleteNotificationOpen,
            errors, email, emailValid,
            subdivision,
        } = this.state;

        const {
            checkoutDateAndTimeState: {
                timeIndex,
                selectedDeliveryDate,
            },
            handleOpenAddressInfoPopup,
        } = this.props;

        const formHasErrors = !!errors;
        const formattedAvailableIntervals = formatIntervals(deliveryIntervals);

        const isDateDisabled = (street.value === '' || street.value === null)
            || ((street.value !== '') && formHasErrors && errors.street)
            || !street.data.house
            || deliveryDates.length === 0
            || deliveryIntervals.length === 0;

        const { street: inputHasError, streetErrorText: errorText } = !!errors && errors;

        const authProps = {
            locale,
            userQuery,
            pushLead: this.pushLead,
            switchToSingleStepCheckout: this.switchToSingleStepCheckout,
            frame: 'phone',
            checkoutType,
        };

        const codeProps = {
            ...authProps,
            frame: 'code',
            checkoutType,
        };

        const addressProps = {
            locale,
            addressValue: street.value || '',
            street,
            suggestions,
            hasError: inputHasError,
            errorText,
            onChangeAddress: this.handleChangeStreet,
            onBlurAddress: this.handleBlurStreet,
            onSelectAddress: this.handleSelectStreet,

            flatValue: apartment || '',
            onChangeFlat: this.handleChangeApartment,
            floorValue: floor || '',
            onChangeFloor: this.handleChangeFloor,
            entranceValue: entrance || '',
            onChangeEntrance: this.handleChangeEntrance,
            onChangeContactlessDelivery: this.handleToggleContactlessDelivery,
            contactlessDeliverychecked: isContactlessDeliveryActivated,
            deliveryPrice,
            haveDishesFromTrial,
            isAddresIncompleteNotificationOpen,
            selectedPeriod,
        };

        const dateTimeProps = {
            disabled: isDateDisabled,
            deliveryDates,
            timeIntervals: formattedAvailableIntervals,
            selectDay: selectedDeliveryDate,
            selectTime: { timePeriod, timeIndex },
            deliveryPrice: cart?.totals?.deliveryPrice ?? 0,
            onSelectDate: this.handleSelectDay,
            onSelectDateInNextPeriod: this.handleChangeDateConfirm,
        };
        const hideRecurrentPayment = checkIsRecurrentPaymentHidden(this.props);

        const period = menuFilter && menuFilter.periods.find((p) => p.start === selectedPeriod);

        const hideCashByPeriod = period
            ? period.hiddenPaymentMethods && period.hiddenPaymentMethods.includes(PAYMENT_PARAM_CASH)
            : false;

        const isFreeOrder = staticCommonPrice === 0;
        const hideFoodcard = isFreeOrder;
        const hideOnlinePayment = hideOnline || isFreeOrder;
        const hideCashPayment = hideCash
            || hideCashByPeriod
            || isContactlessDeliveryActivated
            || ['RU-NIZ', 'RU-NIO'].includes(this.props?.deliveryAddress?.address?.country_subdivision);

        const paymentProps = {
            handleSelectPayment: this.handleSelectPayment,
            togglePaperRecipeSwitch: this.togglePaperRecipeSwitch,
            toggleTableSettingSwitch: this.toggleTableSettingSwitch,
            applyPromocodeHandler: this.applyPromocodeHandler,
            paymentMethod,
            promocodeConditions: discountConditions,
            handleChangeEmail: this.handleChangeEmail,
            handleBlurEmail: this.handleBlurEmail,
            email,
            emailValid,
            hideFoodcard,
            hideOnlinePayment,
            hideCashPayment,
            handlePay: this.handlePay,
            subscriptionTypeQuery,
            onClickSubscriptionInfoOpen,
            handleOpenDeliveryPricePopup,
            hideRecurrentPayment,
            cart,
            subdivision: subdivision || 'RU-MOS',
        };

        const allProps = {
            togglePaperRecipeSwitch: this.togglePaperRecipeSwitch,
            toggleTableSettingSwitch: this.toggleTableSettingSwitch,
            setIsAddressChanged,
            userQuery,
            closeCheckout,
            isAddressPopupNeeded,
            handleOpenAddressInfoPopup,
            STEP_ADDRESS: addressProps,
            STEP_PHONE: authProps,
            STEP_DATETIME: dateTimeProps,
            STEP_PAYMENT: paymentProps,
            STEP_CODE: codeProps,
            setIsCustomizationIgnored,
        };

        return (
            <>
                <Transition
                    in={checkoutType === TYPE_CHECKOUT_STEP_BY_STEP}
                    timeout={600}
                >
                    {(transitionState) => {
                        const containerClasses = classNames({
                            'checkout__step-by-step-container': true,
                            [transitionState]: true,
                            'checkout__step-by-step-container--shifted': checkoutType === TYPE_CHECKOUT_SINGLE_STEP,
                        });
                        return (
                            <div styleName={containerClasses}>
                                {transitionState !== 'exited' && (
                                    <StepByStepCheckout
                                        {...allProps}
                                    />
                                )}
                            </div>
                        );
                    }}
                </Transition>

                <Transition
                    in={checkoutType === TYPE_CHECKOUT_SINGLE_STEP}
                    timeout={600}
                >
                    {(transitionState) => {
                        const containerClasses = classNames({
                            'checkout__step-by-step-container': true,
                            [transitionState]: true,
                        });
                        return (
                            <div styleName={containerClasses}>
                                {transitionState !== 'exited' && (
                                    <SingleStepCheckout
                                        {...allProps}
                                    />
                                )}
                            </div>
                        );
                    }}
                </Transition>
            </>
        );
    };

    render() {
        const { currentView, paymentInfo } = this.state;
        const {
            basketQuery,
            transitionState,
            checkoutType,
        } = this.props;

        const wrapperClasses = classNames({
            checkout: true,
            entered: transitionState === 'entered',
        });

        const isPaymentPage = currentView === 'paymentPage';
        if (isPaymentPage && !paymentInfo) {
            return (
                <div styleName={wrapperClasses}>
                    {this.renderCheckoutLoading()}
                </div>
            );
        }
        if (isPaymentPage) {
            return (
                <div styleName={wrapperClasses}>
                    {this.renderOnlyPaymentButtonForPaymentPage()}
                </div>
            );
        }

        const isOnlyPaymentButton = currentView === 'onlyPaymentButton';
        if (isOnlyPaymentButton) {
            return (
                <div styleName={wrapperClasses}>
                    {this.renderOnlyPaymentButton()}
                </div>
            );
        }

        const { error: basketError } = basketQuery;

        if (basketError) {
            const isBasketErrorHandled = basketError && basketError.message.includes(PERIOD_IS_DISABLED_ERROR_MESSAGE);

            if (!isBasketErrorHandled) {
                return (
                    <div styleName="checkout">
                        <div styleName="checkout__checkout error">
                            <ErrorContent />
                        </div>
                    </div>
                );
            }
        }

        if (TYPE_CHECKOUT_LOADING === checkoutType) {
            return null;
        }

        return this.renderStepByStepLayout();
    }
}

const CheckoutDataAccessContainer = (props) => {
    const {
        isMobileTimeIntervalsOpen = false,
        basketQuery = {},
        checkoutData = {},
        deliveryAddress = {
            address: {
                comment: '',
            },
        },
    } = props;

    useEffect(() => {
        sessionStorage.removeItem('forwardSource');
        sessionStorage.removeItem('backToSource');
        sessionStorage.removeItem('toCheckout');
    }, []);

    const [addItemsToCartByPeriod] = useMutation(ADD_ITEMS_TO_CART_BY_PERIOD);

    return (
        <Checkout
            {...props}
            deliveryAddress={deliveryAddress}
            checkoutData={checkoutData}
            basketQuery={basketQuery}
            isMobileTimeIntervalsOpen={isMobileTimeIntervalsOpen}
            addItemsToCartByPeriod={addItemsToCartByPeriod}
        />
    );
};


export default compose(
    withDispatchOverlay,
)(CheckoutDataAccessContainer);


Checkout.propTypes = {
    checkoutDispatch: PropTypes.func.isRequired,

    checkoutDateAndTimeState: shape({
        timeIndex: PropTypes.number.isRequired,
    }).isRequired,

    checkoutType: PropTypes.oneOf([
        TYPE_CHECKOUT_LOADING,
        TYPE_CHECKOUT_STEP_BY_STEP,
        TYPE_CHECKOUT_SINGLE_STEP,
    ]).isRequired,

    localeContext: PropTypes.shape({
        locale: PropTypes.string,
    }).isRequired,

    deliveryDates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    deliveryIntervals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    fetchDeliveryDaysData: PropTypes.func.isRequired,

    client: PropTypes.shape({
        query: PropTypes.func.isRequired,
    }).isRequired,
    createInvoice: PropTypes.func.isRequired,
    closeCheckout: PropTypes.func.isRequired,

    shipping: PropTypes.func.isRequired,

    transitionState: PropTypes.string.isRequired,

    filterQuery: PropTypes.shape({
        menuFilter: PropTypes.shape({
            periods: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }).isRequired,

    addItemsToCartByPeriod: PropTypes.func.isRequired,

    basketQuery: PropTypes.shape({
        cart: PropTypes.shape({
            sections: PropTypes.arrayOf(PropTypes.shape({
                items: PropTypes.arrayOf(PropTypes.shape({})),
            })),
            totals: PropTypes.shape({
                total_discount_price: PropTypes.number,
                static_common_price: PropTypes.number,
                total_common_price: PropTypes.number,
                delivery_price: PropTypes.number,
                promocode_applied: PropTypes.bool,
            }),
            promocode: PropTypes.string,
            typeOfSet: PropTypes.string.isRequired,
        }),
        error: PropTypes.shape({}),
        refetch: PropTypes.func,
    }),

    selectedFilters: PropTypes.shape({
        selectedPeriod: PropTypes.string,
        selectedTags: PropTypes.arrayOf(
            PropTypes.string,
        ),
    }).isRequired,

    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        location: PropTypes.shape({
            search: PropTypes.string,
        }),
    }).isRequired,
    match: PropTypes.shape({
        path: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        search: PropTypes.string,
    }).isRequired,

    userQuery: PropTypes.shape({
        user: PropTypes.shape({
            phone: PropTypes.string,
            addressBook: PropTypes.shape({
                lastOrderedAddress: PropTypes.shape({}),
            }),
            email: PropTypes.string,
            name: PropTypes.string,
            id: PropTypes.number,
        }),
        refetch: PropTypes.func.isRequired,
        error: PropTypes.shape({}),
    }).isRequired,

    sessionCommentQuery: PropTypes.shape({
        refetch: PropTypes.func.isRequired,
    }).isRequired,

    subscriptionTypeQuery: PropTypes.shape({
        subscriptionType: PropTypes.string.isRequired,
    }).isRequired,

    updateBilling: PropTypes.func.isRequired,
    paymentMethod: PropTypes.shape({
        paymentMethod: PropTypes.string,
    }).isRequired,

    pushLead: PropTypes.func.isRequired,

    // onClickMobileTimeIntervals: PropTypes.func.isRequired,
    onClickSubscriptionInfoOpen: PropTypes.func.isRequired,

    isMobileTimeIntervalsOpen: PropTypes.bool,

    hiddenElements: PropTypes.shape({
        backstep: PropTypes.bool,
        payment_button_recurrent: PropTypes.bool,
        payment_button_online: PropTypes.bool,
        payment_button_offline: PropTypes.bool,
    }).isRequired,

    checkoutData: PropTypes.shape({
        telephone: PropTypes.string,
        email: PropTypes.string,
        street: PropTypes.string,
        apartment: PropTypes.string,
        floor: PropTypes.string,
        entrance: PropTypes.string,
        day: PropTypes.string,
        timeIntervalsInsideBetlwayTitle: PropTypes.string,
        timeIntervalsOutsideBetlwayTitle: PropTypes.string,
        subdivision: PropTypes.string,
    }),
    onWillUnmount: PropTypes.func.isRequired,
    handleOpenDeliveryPricePopup: PropTypes.func.isRequired,
    onSelectDeliveryDate: PropTypes.func.isRequired,

    dispatchOverlayContext: PropTypes.shape({
        pushOverlay: PropTypes.func,
        pushDialogOverlay: PropTypes.func,
        closeLastOverlays: PropTypes.func,
    }).isRequired,

    switchToSingleStepCheckout: PropTypes.func.isRequired,

    deliveryAddress: PropTypes.shape({}),

    pushBasketErrorDialog: PropTypes.func.isRequired,
    isAddressPopupNeeded: PropTypes.bool.isRequired,
    handleOpenAddressInfoPopup: PropTypes.func.isRequired,
    setIsPaperRecipeChanged: PropTypes.func,
};

