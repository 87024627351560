export const dishes = [
    '3979',
    '3092',
    '3980',
    '1493',
    '4443',
];

export const periods = [
    '2025-04-14',
];
