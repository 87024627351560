import React from 'react';
import css from './romanticDetails.module.scss';
import '../../dishDetailsWithCustom.scss';

import boxImage from './img/desc_card_sm.jpg';
import ingredientsImage from './img/ingredients.jpg';
import gameImage from './img/game.jpg';
import additionalImage from './img/additional.jpg';

import firstDish from './img/first_dish.jpg';
import secondDish from './img/second_dish.jpg';
import thirdDish from './img/third_dish.jpg';
import fourthDish from './img/fourth_dish.jpg';
import { gql, useFragment, useQuery } from '@apollo/client';


const RomanticInfo = () => (
    <div styleName="new-details-card-info romantic">
        <div styleName="new-details-card-info-line">
            <div styleName="new-details-card-info-line-one">Порция</div>
            <div styleName="new-details-card-info-line-two basket-with--dots" />
            <div styleName="new-details-card-info-line-three">
                <span styleName="new-details-card-info-digit-value">3</span>
                {' '}
                <span styleName="new-details-card-info-text">блюда на двоих</span>
            </div>
        </div>
        <div styleName="new-details-card-info-line">
            <div styleName="new-details-card-info-line-one">Приготовить</div>
            <div styleName="new-details-card-info-line-two basket-with--dots" />
            <div styleName="new-details-card-info-line-three">
                <span styleName="new-details-card-info-text">на</span>
                {' '}
                <span styleName="new-details-card-info-digit-value">1-3</span>
                {' '}
                <span styleName="new-details-card-info-text">день</span>
            </div>
        </div>
    </div>
);

const RomanticSetDescription = () => (
    <div className={css.cardsList}>
        <div className={css.secondaryCard}>
            <div className={css.cardTitle}>1. Продукты с рецептами</div>
            <img className={css.descriptionCardImage} src={ingredientsImage} alt="description card" />
            <ul className={css.descriptionList}>
                <li>Для ужина из 3 перемен: легкая закуска, основное блюдо, десерт</li>
                <li>
                    Все продукты максимально подготовлены: мы нарежем мясо, смешаем соусы и взвесим ингредиенты. Вам
                    останется только самое интересное – творить на кухне!
                </li>
                <li>
                    Все инструкции подробно расписаны шеф-поваром – у вас точно получится сделать тирамису своими руками
                    и
                    научиться готовить гребешки и утку.
                </li>
            </ul>
        </div>
        <div className={css.secondaryCard}>
            <div className={css.cardTitle}>2. Карточки с вопросами</div>
            <img className={css.secondaryCardImage} src={gameImage} alt="description card" />
            <div className={css.description}>
                Мини-игра, чтобы узнать друг друга получше. Хороший повод поделиться историями, для
                которых обычно нет повода!
            </div>
        </div>
        <div className={css.secondaryCard}>
            <div className={css.cardTitle}>
                3. Приятные дополнения
                <br />
                для сервировки
            </div>
            <img className={css.secondaryCardImage} src={additionalImage} alt="description card" />
            <ul className={css.descriptionList}>
                <li>
                    Салфетки-сердечки – чтобы
                    <br />
                    накрыть стол с самыми красивыми тарелками
                    и приборами, которые есть в доме.
                </li>
                <li>Клубничный микс – для домашнего лимонада или коктейлей: Россини, Маргарита или ваш авторский.</li>
                <li>Бенгальские огни – можно зажечь при подаче десерта и загадать желание.</li>
            </ul>
        </div>
    </div>
);

const RomanticMenuList = () => (
    <div className={css.romanticMenuList}>
        <h2 className={css.mainCartTitle}>
            Меню
            <br />
            романтического ужина
        </h2>
        <div className={css.cardsList}>
            <div className={css.menuCard}>
                <img className={css.menuCardImage} src={firstDish} alt="dish card" />
                <div className={css.dishInfo}>
                    <div className={css.dishTitle}>Закуска из гребешков</div>
                    <div className={css.dishDesc}>
                        Изысканная прелюдия к романтическому ужину с игрой трех текстур — хрустящей
                        посыпки с амарантом, сливочного соуса с гребешками и сочных фруктов.
                    </div>
                </div>
            </div>
            <div className={css.menuCard}>
                <img className={css.menuCardImage} src={secondDish} alt="dish card" />
                <div className={css.dishInfo}>
                    <div className={css.dishTitle}>Стейки из утки</div>
                    <div className={css.dishDesc}>
                        Ужин уровня Мишлен прямо у вас дома: накройте на стол, расставьте свечи, включите
                        музыку и насладитесь вечером вместе.
                        {' '}
                    </div>
                </div>
            </div>
            <div className={css.menuCard}>
                <img className={css.menuCardImage} src={thirdDish} alt="dish card" />
                <div className={css.dishInfo}>
                    <div className={css.dishTitle}>Нежный тирамису с итальянским какао</div>
                    <div className={css.dishDesc}>
                        Собрать десерт из савоярди и домашнего крема – элементарно. Через пару часов
                        найти в холодильнике настоявшийся тирамису – бесценно.
                    </div>
                </div>
            </div>
            <div className={css.menuCard}>
                <img className={css.menuCardImage} src={fourthDish} alt="dish card" />
                <div className={css.dishInfo}>
                    <div className={css.dishTitle}>Клубничная основа для коктейлей</div>
                    <div className={css.dishDesc}>
                        Клубничное пюре + домашний сироп с мятой + лимонный сок = насыщенная натуральная
                        основа для 4-5 бокалов. С содовой или с просекко – яркое дополнение романтического вечера!
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export const RomanticDetails = () => {
    const { data } = useFragment({
        fragment: gql`fragment DISH_TITLE on menuDish {
            title
            caption
        }`,
        from: {
            __ref: 'menuDish:4331',
        },
    });

    const title = data?.title;
    const caption = data?.caption;
    return (
        <div className={css.romanticDetailsRoot}>
            <div className={css.romanticDishTitle}>
                {title}
                <br />
                {caption}
            </div>
            <RomanticInfo />
            <div className={css.mainCard}>
                <h2 className={css.mainCardTitle}>Что в наборе</h2>
                <img className={css.descriptionCardImage} src={boxImage} alt="description card" />
                <div className={css.descriptionCard}>
                    <img className={css.descriptionCardImage} src={additionalImage} alt="description card" />
                    <div className={css.cardContent}>
                        <ul>
                            <li>
                                Всё необходимое для ужина из 3 перемен: легкая закуска, основное блюдо, десерт –
                                плюс приятные дополнения для красивого вечера.
                            </li>
                            <li>
                                Все инструкции подробно расписаны шеф-поваром – у вас точно получится сделать тирамису своими
                                руками и научиться готовить гребешки и стейки из утки.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={css.romanticSetList}>
                <RomanticSetDescription />
            </div>
            <div>
                <RomanticMenuList />
            </div>
        </div>
    );
};
