/* eslint-disable camelcase */
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en';

import { startOfWeek, format as dateFormat, lastDayOfWeek } from 'date-fns';


const locales = {
    ru,
    en,
    defaultLocale: ru,
};

export const setDefaultLocale = (key) => {
    locales.defaultLocale = locales[key];
};

// взвращает строку вида '2021-10-25'
export const dateToEeFormat = (date) => dateFormat(date, 'YYYY-MM-DD');

// возвращает экземпляр типа Date
export const getStartOfPeriod = (day) => startOfWeek(day, { weekStartsOn: 1 });

export const getDayNumber = (date) => format(
    date,
    'DD',
    { locale: locales.defaultLocale },
);

export const getMonthNumber = (date) => format(
    date,
    'MM',
    { locale: locales.defaultLocale },
);

// взвращает строку вида '2 июля'
export const formatDate = (date) => format(
    date,
    'D MMMM',
    { locale: locales.defaultLocale },
);

export const formatPeriod = ({ start, end }) => {
    const startD = new Date(start);

    const customEnd = start === '2024-12-30' ? '2024-12-31' : end;
    const endD = new Date(customEnd);

    return `${formatDate(startD)} - ${formatDate(endD)}`;
};

export const formatPeriodMobile = ({ start, end }) => {
    const startD = new Date(start);

    const customEnd = start === '2024-12-30' ? '2024-12-31' : end;
    const endD = new Date(customEnd);

    // formatDate возвращает месяц в нужном падеже связанном с числом
    const startFormated = formatDate(startD);
    const endFormated = formatDate(endD);
    const [startDay, startMonth] = startFormated.split(' ');
    const [endDay, endMonth] = endFormated.split(' ');

    return {
        start: {
            day: startDay,
            month: startMonth,
        },
        end: {
            day: endDay,
            month: endMonth,
        },
    };
};

export const formatDeliveryDate = (d) => {
    const date = new Date(d);
    return format(
        date,
        'dd, D MMMM',
        { locale: locales.defaultLocale },
    );
};

export const formatDeliveryDateMobile = (d) => {
    const locale = locales.defaultLocale;

    const options = { locale };

    const date = new Date(d);
    const weekday = format(
        date,
        'dd',
        options,
    );
    const fullWeekday = format(
        date,
        'dddd',
        options,
    );
    const month = format(
        date,
        'MM',
        options,
    );
    const shortMonth = format(
        date,
        'D MMM',
        options,
    ).split(' ')[1];

    const [day, fullMonth] = formatDate(date).split(' ');
    return {
        day,
        weekday,
        fullWeekday,
        month,
        fullMonth,
        shortMonth,
    };
};

export const formatDeliveryDateMobileSelect = (d) => {
    const locale = locales.defaultLocale;

    const date = new Date(d);
    const day = format(
        date,
        'DD.MM',
        { locale },
    );
    const month = format(
        date,
        'MMMM',
        { locale },
    );
    const weekday = format(
        date,
        'dd',
        { locale },
    );
    const dayOfMonth = format(
        date,
        'D',
        { locale },
    );
    return {
        day,
        weekday,
        month,
        dayOfMonth,
    };
};

export const formatDeliveryTime = (f, t) => {
    const from = String(f);
    const to = String(t);
    const fromH = from.length === 3 ? from.slice(0, 1) : from.slice(0, 2);
    const fromM = from.length === 3 ? from.slice(1, 3) : from.slice(2, 4);
    const toH = to.length === 3 ? to.slice(0, 1) : to.slice(0, 2);
    const toM = to.length === 3 ? to.slice(1, 3) : to.slice(2, 4);
    const result = `${fromH <= 9 ? `0${fromH}` : fromH}:${fromM} - ${toH <= 9 ? `0${toH}` : toH}:${toM}`;
    return result;
};

export const formatInterval = (hourFrom, hourTo, minute_from = 0, minute_to = 0) => {
    const mf = typeof minute_from === 'number' ? (minute_from < 10 ? `0${minute_from}` : minute_from) : minute_from;
    const mt = typeof minute_to === 'number' ? (minute_to < 10 ? `0${minute_to}` : minute_to) : minute_to;

    return ({
        start: `${hourFrom <= 9 ? `0${hourFrom}` : hourFrom}:${mf}`,
        end: `${hourTo <= 9 ? `0${hourTo}` : hourTo}:${mt}`,
    });
};


const mapIntervals = (intervalsList) => intervalsList.map(({
    hour_from: hourFrom,
    hour_to: hourTo,
    minute_from,
    minute_to,
    ...rest
}) => {
    const formatted = formatInterval(hourFrom, hourTo, minute_from, minute_to);
    return {
        ...rest,
        ...formatted,
    };
});

export const formatIntervals = (intervals = []) => {
    return mapIntervals(intervals);
};

// const insideMkad = intervals.inside_mkad;
// const outsideMkad = intervals.outside_mkad;

// const result = {
//     inside_mkad: mapIntervals(intervals),
//     outside_mkad: mapIntervals(outsideMkad),
// };
export const formatParsedIntervals = (intervals) => intervals.map(
    ({ hourFrom, hourTo, minuteFrom, minuteTo, ...rest }) => {
        const formatted = formatInterval(hourFrom, hourTo, minuteFrom, minuteTo);
        return {
            ...formatted,
            ...rest,
        };
    },
);

// export const timeToHours = (timeFrom, timeTo) => {
//     return ({
//         hourFrom: timeFrom / 100,
//         hourTo: timeTo / 100,
//     });
// };


export const createDateFromString = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    const monthIndex = month - 1;
    return new Date(year, monthIndex, day);
};

export const formatDateToISO = (date) => {
    const s = format(
        date,
        'YYYY-MM-DD',
        { locale: ru },
    );
    return s;
};


export const areDatesInDifferentPeriods = (d1, d2) => {
    const date1 = createDateFromString(d1);
    const date2 = createDateFromString(d2);

    const startOfPeriod = startOfWeek(date1, { weekStartsOn: 1 });
    const endOfPeriod = lastDayOfWeek(date1, { weekStartsOn: 1 });

    return date2 > endOfPeriod || date2 < startOfPeriod;
};

export const areDatesInOnePeriod = (d1, d2) => {
    const date1 = createDateFromString(d1);
    const date2 = createDateFromString(d2);

    const startOfPeriod = startOfWeek(date1, { weekStartsOn: 1 });
    const endOfPeriod = lastDayOfWeek(date1, { weekStartsOn: 1 });

    return date2 > endOfPeriod || date2 < startOfPeriod;
};

const monthtNamesForDate = [
    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря',
];
const monthtShortNamesForDate = [
    'янв', 'фев', 'мар', 'апр', 'мая', 'июня', 'июля', 'авг', 'сен', 'окт', 'ноя', 'дек',
];
const isoLocalesOptions = {
    ru: 'ru-Ru',
    en: 'en-En',
};

export const getDateData = (dateValue, locale = 'ru') => {
    const isEnLocale = locale === 'en';
    const dateObj = new Date(dateValue);

    const day = dateObj.getDay();
    const date = dateObj.getDate();
    const month = dateObj.getMonth();

    const rawData = {
        day,
        date,
        month,
        dateValue,
        dateObj,
    };

    const weekDayName = dateObj.toLocaleDateString(isoLocalesOptions[locale], { weekday: 'long' });
    const weekDayShortName = dateObj.toLocaleDateString(isoLocalesOptions[locale], { weekday: 'short' });
    const monthName = dateObj.toLocaleDateString(isoLocalesOptions[locale], { month: 'long' });
    const monthShortName = dateObj.toLocaleDateString(isoLocalesOptions[locale], { month: 'short' });
    const monthNameForDate = isEnLocale ? monthName : monthtNamesForDate[month];
    const monthShortNameForDate = isEnLocale ? monthShortName : monthtShortNamesForDate[month];

    return {
        weekDayName,
        weekDayShortName,
        date,
        monthName,
        monthShortName,
        monthNameForDate,
        monthShortNameForDate,
        rawData,
    };
};

export default {
    formatPeriod,
};
