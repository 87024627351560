import React from 'react';
import formatPrice from 'app/utils/price';
import VkIcon from 'global/img/vk.svg';
import TgIcon from 'global/img/telegram.svg';
import { UIButton } from 'app/components/ui';

import LINKS from 'app/const/links';

import './open-basket-button.scss';

const { SOCIETY } = LINKS;


export const DesktopOpenBasketButton = (props) => {
    const {
        price,
        originalPrice,
        onClick,
        elType,
        href,
        title = 'Оформить заказ',
    } = props;

    return (
        <div className="pch-float-footer__wrapper pch-width-container">
            <div className="pch-float-footer" id="float-footer">
                <div className="pch-float-footer__buttons-container">
                    <div className="pch-float-footer-icon-caontainer">
                        <ul className="contacts contacts--small">
                            <li
                                className="contact"
                                data-social="To_VK"
                            >
                                <a
                                    className="contact-link"
                                    href={SOCIETY.hrefVk}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="vk"
                                >
                                    <span className="contact-link__icon contact-link__icon--vk">
                                        <img
                                            className="contact-link__icon-img"
                                            src={VkIcon}
                                            alt="VKontakte"
                                        />
                                    </span>
                                </a>
                            </li>
                            <li
                                className="contact"
                                data-social="To_Telegram"
                            >
                                <a
                                    className="contact-link"
                                    href={SOCIETY.telegramHref}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="telegram"
                                >
                                    <span className="contact-link__icon contact-link__icon--telegram">
                                        <img
                                            className="contact-link__icon-img"
                                            src={TgIcon}
                                            alt="telegram"
                                        />
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* КНОПКА */}
                    <div
                        className="pch-float-footer__button-container"
                        data-scroll-to-form="true"
                        data-social="Wanna_Try"
                    >
                        <UIButton
                            onClick={onClick}
                            elType={elType}
                            href={href}
                            aria-label="Оформить заказ"
                        >
                            <span className="title">Оформить заказ</span>
                            {Boolean(price) && (
                                <span className="price">
                                    <span className="num">{formatPrice(price)}</span>
                                    <span className="rub">₽</span>
                                </span>
                            )}
                            {Boolean(originalPrice) && originalPrice !== price && (
                                <span className="original-price">
                                    <span className="num">{originalPrice}</span>
                                    <span className="rub">₽</span>
                                </span>
                            )}
                        </UIButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

