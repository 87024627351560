import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CATEGORY_TITLES } from 'app/const/categories';
/*
    При необходимости его можно заменить на intersectionObserver
*/
import scrollObserver from 'app/utils/scrollObserver';

import Responsive from 'app/components/Responsive';
import Dishes from 'app/components/Dishes';
import { UIHeading } from 'app/components/ui';

import { isDesktop } from 'app/utils/resolution';
import {
    MenuDataContext,
    MenuDispatchContext,
    TOP,
    CATEGORY,
    FIRST_CATEGORY,
    STORED_POSITION,
} from 'app/containers/contexts/menu.context';
import './menu-category.scss';


const categoryScrollingTypes = [
    TOP, CATEGORY, FIRST_CATEGORY,
];


class MenuCategory extends React.Component {
    containerRef = React.createRef();

    mainRef = React.createRef();

    pixelForScrollRef = React.createRef();

    positionIsObserved = false;

    componentDidMount() {
        // BR-1125
        const { isUserInABTest } = this.props;
        this.scrollCategoryToTop();

        if (!isUserInABTest) {
            this.observeViewportPosition();
        }
    }

    componentDidUpdate() {
        const {
            menuDataContext: { scrolling },
        } = this.props;

        if (categoryScrollingTypes.includes(scrolling.target)) {
            this.scrollCategoryToTop();
        }
    }

    componentWillUnmount() {
        scrollObserver.stopObservingPosition({
            el: this.loadingContainerRef,
        });
    }

    handleViewportPostionChange = (viewportState) => {
        const { onViewportPositionChange, categoryId } = this.props;
        onViewportPositionChange({ categoryId, viewportState });
    };

    /**
     * @note Метод завист от props'ов в mein
    */
    scrollCategoryToTop() {
        const {
            categoryId,
            index,
            menuDataContext: { scrolling },
            menuDispatchContext,
        } = this.props;


        const isSmooth = Boolean(scrolling.smooth);
        const options = isSmooth ? {
            behavior: 'smooth',
        } : undefined;

        switch (scrolling.target) {
            case TOP:
            case FIRST_CATEGORY: {
                window.scrollTo(0, 0, options);
                break;
            }
            case STORED_POSITION: {
                window.scrollTo(0, scrolling.value, options);
                break;
            }
            case CATEGORY: {
                const isFirstCategory = index === 1;
                const isCategoryIdForScrolling = categoryId === scrolling.value;

                if (isFirstCategory) {
                    window.scrollTo(0, 0, options);
                    break;
                }
                if (isCategoryIdForScrolling) {
                    const el = this.pixelForScrollRef.current;
                    el.scrollIntoView(options);
                    // const headerElementHeight = document.querySelector('#spaHeaderRef')?.offsetHeight;
                    setTimeout(() => {
                        window.scrollBy({
                            top: window.innerWidth >= 1366 && categoryId !== '3' ? 0 : -140,
                            behavior: 'smooth',
                        });
                    }, 100);
                    break;
                }
                break;
            }
            default: break;
        }

        // сбрасываем необходимость скролла после выполнения скролла
        menuDispatchContext({ type: 'resetScroll' });
    }

    observeViewportPosition() {
        const { viewportPadding } = this.props;
        if (this.containerRef.current && !this.positionIsObserved) {
            scrollObserver.observePosition({
                el: this.containerRef.current,
                callback: this.handleViewportPostionChange,
                viewportPadding,
            });
            this.positionIsObserved = true;
        }
    }

    /**
     * this div need to prevent partial overlap dish image by header when scrollIntoView happens (in scrollCategoryToTop)
     */
    renderScrollToTopPixel(l) {
        const { headerHeight } = this.props;

        const mobileStyles = {
            top: `-${headerHeight}px`,
        };

        return (
            <div
                ref={this.pixelForScrollRef}
                style={isDesktop() ? null : mobileStyles}
                styleName="pixel-for-scroll"
            />
        );
    }

    renderTitle() {
        const {
            categoryId,
            title: titleProp,
            locale,
        } = this.props;

        const title = titleProp ?? CATEGORY_TITLES[locale][categoryId];

        if (Array.isArray(title)) {
            return (
                <>
                    {title.map((part, i) => (
                        <React.Fragment key={part}>
                            {i !== 0 && <span className="micro">.&nbsp;</span>}
                            {part}
                        </React.Fragment>
                    ))}
                </>
            );
        }

        return title;
    }

    render() {
        const {
            title,
            isDishesUpdating,
            locale,
            categoryId,
            ...otherProps
        } = this.props;

        const titleClasses = classNames({
            'category__title-container': true,
            'title-updating': isDishesUpdating,
        });
        return (
            <div ref={this.mainRef}>
                <div styleName={titleClasses}>
                    <Responsive
                        mobile={(
                            <UIHeading level="2.1">
                                {this.renderTitle()}
                            </UIHeading>
                        )}
                        desktop={(
                            <p styleName="category__title">
                                {title}
                            </p>
                        )}
                    />
                </div>
                <div
                    ref={this.containerRef}
                    styleName="dishes-container"
                    data-category_id={categoryId}
                >
                    {this.renderScrollToTopPixel()}

                    <Dishes
                        categoryTitle={title}
                        delayTime={100}
                        isDishesUpdating={isDishesUpdating}
                        locale={locale}
                        categoryId={categoryId}
                        {...otherProps}
                    />
                </div>
            </div>
        );
    }
}

MenuCategory.propTypes = {
    locale: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,

    headerHeight: PropTypes.number.isRequired,

    categoryId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,

    isDishesUpdating: PropTypes.bool.isRequired,
    isUserInABTest: PropTypes.bool,

    viewportPadding: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number,
    }).isRequired,
    onViewportPositionChange: PropTypes.func.isRequired,
};

const MenuCategoryAdapter = (props) => {
    const {
        isUserInABTest = false,
    } = props;

    const menuDispatchContext = useContext(MenuDispatchContext);
    const menuDataContext = useContext(MenuDataContext);
    return (
        <MenuCategory
            {...props}
            menuDispatchContext={menuDispatchContext}
            menuDataContext={menuDataContext}
            isUserInABTest={isUserInABTest}
        />
    );
};

export default MenuCategoryAdapter;
