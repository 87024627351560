import React, {
    useEffect, useState, useRef, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import cn from 'classnames';

import { analyticService } from 'global/services';

import { ADDITIONAL_DISHES_CATEGORIES } from 'app/const/categories';
import { MenuDispatchContext } from 'app/containers/contexts/menu.context';
import { usePrevious } from 'app/utils/hooks';

import Responsive from 'app/components/Responsive';
import DishDAC from 'app/views/Menu/Menu/DAC/DishDAC';
import { MenuSelectionsMobileSlider } from 'app/components/banners/MenuSelectionsMobileSlider/MenuSelections';


import './dishes.scss';
import { isDesktop } from 'app/utils/resolution';
import { NYLPBanner } from '../banners/NYLPBanner/NYLPBanner';


const isShelfLifeDisplayed = (categoryId) => ADDITIONAL_DISHES_CATEGORIES.includes(categoryId);

const isLazyLoadingEnabled = (isCategoryActive, index, useLazyLoading) => {
    if (!isCategoryActive) {
        return useLazyLoading;
    }

    if (index === 0) {
        return false;
    }

    return useLazyLoading;
};


class Dishes extends React.Component {
    constructor(props) {
        super(props);
        this.listRef = React.createRef();
    }

    // eslint-disable-next-line react/sort-comp, class-methods-use-this
    trackDishDetailsViewed = (dishId) => {
        const {
            categoryTitle,
            menuDishesQuery: { menuDishes },
            categoryId,
            subscriptionType,
        } = this.props;

        const dish = menuDishes.find(({ id }) => Number(id) === Number(dishId));

        const {
            id,
            title,
            caption,
            price,
        } = dish;

        analyticService.push({
            id,
            title,
            caption,
            price,
            category: categoryTitle,
            categoryId,
            source: 'menu',
            eventName: 'Dish_Details_Viewed',
            subscriptionType,
        }).push({
            id,
            eventName: 'CityAds_Dish_Details_Viewed',
        });
    };

    dispatchOnCardClickAnalytic = (dish) => {
        const { subscriptionType } = this.props;
        analyticService.push({
            eventName: 'Track_Card_Click', ...dish, list: 'all', subscriptionType,
        });
    };

    render() {
        const {
            spaDishObserver,
            isCategoryActive,
            menuDishesQuery,
            useLazyLoading,
            isBasketLoading,
            isDishesUpdating,
            categoryId,
            dishIndexForRenderSlider,
            isDishesSliderNeeded,
            dishComponentType,
            basketQuery,
            ...dishesProps
        } = this.props;
        const { menuDishes } = menuDishesQuery;

        const isSelectionDish = categoryId === 'selection' && dishComponentType === 'mobile';

        const dishesListRoot = cn({
            'dishes-list': true,
            [categoryId]: true,
        });

        return (
            <ul styleName={dishesListRoot} ref={this.listRef}>
                {menuDishes.map((dish, i, arr) => {
                    const arrTail = arr.length - 1;
                    const useLazyLoadingProp = isLazyLoadingEnabled(isCategoryActive, i, useLazyLoading);

                    // if (dish.id === '4847') {
                    //     return null;
                    // }

                    const commonProps = {
                        arrTail,
                        index: i,
                        isShelfLife: isShelfLifeDisplayed(categoryId),
                        parentRef: this.listRef,
                        useLazyLoading: useLazyLoadingProp,
                        canApplySoldOutStyles: true,
                        onOpenDetails: this.trackDishDetailsViewed,
                        dispatchOnCardClickAnalytic: this.dispatchOnCardClickAnalytic,
                        spaDishObserver,
                        ...dishesProps,
                        basketQuery,
                        categoryId,
                    };

                    const sliderContainerClasses = cn({
                        'dishes-list__slider-container': true,
                        'is-hidden': isDishesUpdating,
                    });

                    // const isNYLPBannerShown = categoryId === '3' && i === 5 && isDesktop();

                    return (
                        <React.Fragment key={dish.id}>
                            <Responsive
                                mobile={(
                                    <>
                                        {isDishesSliderNeeded
                                            && dishIndexForRenderSlider === -1
                                            && i === 0
                                            && (
                                                <div styleName={sliderContainerClasses}>
                                                    <MenuSelectionsMobileSlider realm="menu_realm" />
                                                </div>
                                            )
                                        }
                                        <DishDAC
                                            id={dish.id}
                                            cardProps={{
                                                disabled: isBasketLoading,
                                                isDishUpdating: isDishesUpdating,
                                                ...commonProps,
                                            }}
                                            cardLayout="mobile"
                                        />
                                        {(isDishesSliderNeeded
                                            && dishIndexForRenderSlider === i)
                                            && (
                                                <div styleName={sliderContainerClasses}>
                                                    <MenuSelectionsMobileSlider realm="menu_realm" />
                                                </div>
                                            )
                                        }
                                    </>
                                )}
                                desktop={(
                                    <DishDAC
                                        id={dish.id}
                                        cardProps={dishComponentType === 'mobile'
                                            ? {
                                                disabled: isBasketLoading,
                                                isDishUpdating: isDishesUpdating,
                                                ...commonProps,
                                            }
                                            : commonProps}
                                        cardLayout={isSelectionDish ? 'mobile' : 'desktop'}
                                    />
                                )}
                                key={dish.id}
                            />
                            {/* {isNYLPBannerShown && (
                                <div styleName="NYLPBannerWrapper">
                                    <NYLPBanner />
                                </div>
                            )} */}
                        </React.Fragment>
                    );
                })}
            </ul>
        );
    }
}

Dishes.propTypes = {
    isCategoryActive: PropTypes.bool,
    overlayIsOpen: PropTypes.bool,

    isBasketLoading: PropTypes.bool.isRequired,
    isDishesUpdating: PropTypes.bool.isRequired,
    useLazyLoading: PropTypes.bool,
    menuDispatchContext: PropTypes.func.isRequired,
    categoryTitle: PropTypes.string,
    isDishesSliderNeeded: PropTypes.bool.isRequired,

    spaDishObserver: PropTypes.shape({
        observe: PropTypes.func,
    }).isRequired,

    menuDishesQuery: PropTypes.shape({
        loading: PropTypes.bool,
        error: PropTypes.shape({}),
        menuDishes: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    categoryId: PropTypes.string.isRequired,
    isMobileMenuDishesVisible: PropTypes.bool.isRequired,
    dishIndexForRenderSlider: PropTypes.number.isRequired,

    dishComponentType: PropTypes.string,
    subscriptionType: PropTypes.string,
};
const checkDishesEqual = (prev, next) => {
    if (!prev) {
        return false;
    }
    return isEqual(new Set(prev), new Set(next));
    // return [...new Set([...prev, ...next])].length === 4;
};


const options = {
    rootMargin: '0px',
    threshold: 0.45,
};

const MenuSliderAdapter = ({
    isCategoryActive = false,
    overlayIsOpen = false,
    useLazyLoading = true,
    categoryTitle = '',
    dishComponentType = 'responsive',
    subscriptionType = '',
    menuDishesQuery,
    menuDishesQuery: { menuDishes },
    isDishesSliderNeeded,
    isDishesUpdating,
    location,
    ...restProps
}) => {
    const menuDispatchContext = useContext(MenuDispatchContext);

    const firstThreeDishes = menuDishes.map((e) => e.id).splice(0, 3);
    const prevFirstThreeDishes = usePrevious(firstThreeDishes);

    const [dishIndexForRenderSlider, setDishIndexForRenderSlider] = useState(firstThreeDishes.length - 1);
    const dishesForRenderSliderRef = useRef(firstThreeDishes);

    const prevIsDishesUpdating = usePrevious(isDishesUpdating);
    const isDishesUpdatingChanged = prevIsDishesUpdating !== isDishesUpdating;

    const isDishesEqual = checkDishesEqual(prevFirstThreeDishes, firstThreeDishes);

    useEffect(() => {
        if (!isDishesSliderNeeded) return;

        if (isDishesUpdatingChanged && !isDishesUpdating) {
            dishesForRenderSliderRef.current = firstThreeDishes;
            setDishIndexForRenderSlider(firstThreeDishes.length - 1);
        }
    }, [isDishesUpdating, isDishesSliderNeeded]);

    useEffect(() => {
        if (!isDishesSliderNeeded) return;

        if (isDishesEqual) {
            const nextDishes = dishesForRenderSliderRef.current.filter((e) => firstThreeDishes.includes(e));
            dishesForRenderSliderRef.current = nextDishes;
            setDishIndexForRenderSlider(nextDishes.length - 1);
        }
    }, [isDishesEqual, isDishesSliderNeeded]);

    const spaDishObserver = new IntersectionObserver((entries) => {
        const uri = queryString.parse(location.search);
        const source = (location.pathname === '/menu/') && Boolean(uri.st) ? 'collections' : 'menu';
        entries.forEach((e) => {
            if (e.isIntersecting && location.pathname === '/menu/') {
                const isProgrammaticScroll = JSON.parse(sessionStorage.getItem('isProgrammaticScroll'));
                const intersectingDish = menuDishes.filter((dish) => dish.id === e.target.dataset.id);
                if (isProgrammaticScroll) return;
                analyticService.push({
                    eventName: 'Product_Impression',
                    dish: intersectingDish,
                    subscriptionType,
                    source_name: source,
                });
            }
        });
    }, options);

    return (
        <Dishes
            {...restProps}
            menuDispatchContext={menuDispatchContext}
            menuDishesQuery={menuDishesQuery}
            isDishesUpdating={isDishesUpdating}
            subscriptionType={subscriptionType}
            dishIndexForRenderSlider={dishIndexForRenderSlider}
            isDishesSliderNeeded={isDishesSliderNeeded}
            spaDishObserver={spaDishObserver}
            isCategoryActive={isCategoryActive}
            overlayIsOpen={overlayIsOpen}
            useLazyLoading={useLazyLoading}
            categoryTitle={categoryTitle}
            dishComponentType={dishComponentType}
        />
    );
};

MenuSliderAdapter.propTypes = {
    categoryId: PropTypes.string.isRequired,
    menuDishesQuery: PropTypes.shape({
        menuDishes: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    isDishesSliderNeeded: PropTypes.bool.isRequired,
    isDishesUpdating: PropTypes.bool.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
    }).isRequired,
    subscriptionType: PropTypes.string,
};


export default trackWindowScroll(MenuSliderAdapter);
