/* eslint-disable global-require */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useRef } from 'react';
import { TransitionStatus } from 'react-transition-group';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import cn from 'classnames';

import { analyticService } from 'global/services';
import { tp } from 'app/utils/typograf';
import { isStandaloneApp } from 'app/utils/browser';

import { UIButton } from 'app/components/ui';

import logo from './logo.img.svg';
import close from './close.img.svg';
import BadgeIcon from './badge.component.svg';

import css from './achievementsPopup.module.scss';


type AchievementStatus = 'taster' | 'connoisseur' | 'time_lord' | 'legend' | 'critic' | 'cinnabon' | 'grandchild' | 'experience' | 'friends' | 'holiday'


type Achievement = {
    id: number,
    status: AchievementStatus,
    dishes?: number,
    ingredients?: number,
    time?: number,
    favouriteDish?: string,
}

type AchievementsPopupProps = {
    transitionState: TransitionStatus,
    achievement: Achievement,
    onClose: () => void,
}

type StatusData = {
    title: string,
    description: string[],
};

const statusData: Record<AchievementStatus, StatusData> = {
    taster: {
        title: 'талантливый дегустатор',
        description: [
            'Отличное начало!',
            'Продолжайте в том же духе. Уверены, что скоро вы не сможете представить, как жили раньше без вкусных ужинов за 15 минут.',
        ],
    },
    connoisseur: {
        title: 'ценитель вкусной еды',
        description: [
            'В течение всего года вы открывали с нами мир вкусной еды!',
            'Только посмотрите сколько времени вы сэкономили.',
        ],
    },
    time_lord: {
        title: 'повелитель времени',
        description: [
            'Вы в группе самых верных друзей Elementaree! Спасибо большое за доверие! Надеемся, что вы насладились каждым мгновением сэкономленного времени.',
        ],
    },
    legend: {
        title: 'легенда и вообще наша любовь',
        description: [
            'Вы — просто космос!',
            'Благодарим за вашу лояльность и просто за то, что вы есть. Любим вас.',
        ],
    },
    critic: {
        title: 'ресторанный критик',
        description: [
            'Большое количество оценок блюд сделали вас настоящим экспертом в мире авторской кухни и помогли нам улучшить меню. Осталось последнее — оценить наше новогоднее меню!',
        ],
    },
    cinnabon: {
        title: 'самый сладкий синнабон',
        description: [
            'Оказывается, вы большой любитель сладкого! В этом году вы заказали много десертов, а сэкономленное время потратили на более важные дела, например, на чай с близкими.',
        ],
    },
    grandchild: {
        title: 'не расстраивали бабулю',
        description: [
            'И заказывали супы круглый год.',
            'Теперь вы можете спокойно съесть десерт или пойти погулять.',
        ],
    },

    experience: {
        title: 'едок со стажем',
        description: [
            'Вы встретили с нами уже больше 5 Новых годов! Мы даже пережили вместе високосный. Это любовь! ',
        ],
    },
    friends: {
        title: 'разделили обед с другом',
        description: [
            'Друг познаётся в еде! И вы это знаете, ведь вы рассказываете о нашем сервисе  друзьям. Ценим!',
        ],
    },
    holiday: {
        title: 'человек праздник',
        description: [
            'Оказывается, вы большой любитель тематических боксов и блюд. Вам бы только повод! А мы его всегда найдём.',
        ],
    },
};


const sharingImages: Record<AchievementStatus, string> = {
    taster: require('./sharing/taster.jpg'),
    connoisseur: require('./sharing/connoisseur.jpg'),
    time_lord: require('./sharing/time_lord.jpg'),
    legend: require('./sharing/legend.jpg'),
    critic: require('./sharing/critic.jpg'),
    cinnabon: require('./sharing/cinnabon.jpg'),
    grandchild: require('./sharing/grandchild.jpg'),

    experience: require('./sharing/experience.jpg'),
    friends: require('./sharing/friends.jpg'),
    holiday: require('./sharing/holiday.jpg'),
};


export function AchievementsPopup({
    transitionState,
    achievement,
    onClose,
}: AchievementsPopupProps) {
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!containerRef.current) return () => { };

        if (transitionState === 'entered') {
            disableBodyScroll(containerRef.current);
        } else {
            // console.log('clear locks');
            // clearAllBodyScrollLocks();
        }

        return () => clearAllBodyScrollLocks();
    }, [transitionState]);


    useEffect(() => {
        if (transitionState === 'entered') {
            analyticService.push({
                eventName: 'NY_Achievements_Open',
                user_id: achievement.id,
            });
        }
    }, [transitionState, achievement.id]);


    const { title, description } = statusData[achievement.status];


    const handleShare = useCallback(async () => {
        // NOTE: HTTPS ONLY. Чтобы работала на loopback'e нужен сертифекат
        // NOTE: На localhost тоже не работают. Надо будут как нибудь написать
        // статью в SSL и фичи которые без него не работаю

        analyticService.push({
            eventName: 'NY_Achievements_Click_Share',
            user_id: achievement.id,
        });

        if (navigator.share) {
            const response = await fetch(sharingImages[achievement.status]);
            const blob = await response.blob();

            const files = [
                new File(
                    [blob],
                    'elementaree.jpg',
                    {
                        type: 'image/jpeg',
                        lastModified: new Date().getTime(),
                    },
                ),
            ];

            navigator.share({
                files,
            });
        }
    }, [achievement.status, achievement.id]);


    const handleGoToNYPage = useCallback(() => {
        analyticService.push({
            eventName: 'NY_Achievements_Click_Land',
            user_id: achievement.id,
        });

        if (isStandaloneApp()) {
            window.location.pathname = '/blyuda-dlya-novogodnego-stola';
        } else {
            window.open('/blyuda-dlya-novogodnego-stola');
        }
    }, [achievement.id]);


    const statusClasses = cn({
        [css.achievementsPopupStatus]: true,
        [css[achievement.status]]: achievement.status,
    });

    const titleClasses = cn({
        [css.achievementsPopupStatusText]: true,
        [css[achievement.status]]: achievement.status,
    });

    return (
        <div className={css.achievementsPopup} ref={containerRef}>
            <div className={css.achievementsPopupWrap}>
                {/* HEADER */}
                <div
                    className={css.achievementsPopupHeader}
                    aria-label="achievements-pop-up-header"
                >
                    <div className={css.achievementsPopupHeaderLogo}>
                        <img src={logo} alt="Elementaree" />
                    </div>
                    <div className={css.achievementsPopupCloseContainer}>
                        <button
                            type="button"
                            className={css.achievementsPopupClose}
                            onClick={onClose}
                        >
                            <img src={close} alt="Close" />
                        </button>
                    </div>
                </div>
                {/* BANNER */}
                <div
                    className={css.achievementsPopupBannerRoot}
                    aria-label="achievements-pop-up-banner"
                >
                    <div className={statusClasses}>
                        <p className={css.achievementsPopupPrefix}>
                            в 2024 году вы —
                        </p>
                        <p className={titleClasses}>
                            {tp.execute(title)}
                        </p>
                        <p className={css.achievementsPopupStatusDescription}>
                            {description.map((d) => (
                                <React.Fragment key={d}>
                                    {tp.execute(d)}
                                    <br />
                                </React.Fragment>
                            ))}
                        </p>

                        <div className={css.achievementsPopupStatusShare}>
                            <UIButton onClick={handleShare}>
                                Поделиться
                            </UIButton>
                        </div>
                    </div>
                </div>
                {/* STATS */}
                <div
                    className={css.achievementsPopupStats}
                    aria-label="achievements-pop-up-stats"
                >
                    <div className={css.achievementsPopupStatsContent}>
                        <div className={css.achievementsPopupStatsTitle}>
                            Ваша статистика 2024
                        </div>
                        <div className={css.achievementsPopupStatsMetrics}>
                            {achievement.dishes && (
                                <div className={css.achievementsPopupStatsMetric}>
                                    <div className={css.achievementsPopupStatsMetricText}>
                                        Вы попробовали
                                    </div>
                                    <div className={css.achievementsPopupStatsDivider} />
                                    <div className={css.achievementsPopupStatsMetricCount}>
                                        <span className={css.number}>
                                            {achievement.dishes}
                                        </span>
                                        {' '}
                                        блюд
                                    </div>
                                </div>
                            )}
                            {achievement.ingredients && (
                                <div className={css.achievementsPopupStatsMetric}>
                                    <div className={css.achievementsPopupStatsMetricText}>
                                        Вы заказали
                                    </div>
                                    <div className={css.achievementsPopupStatsDivider} />
                                    <div className={css.achievementsPopupStatsMetricCount}>
                                        <span className={css.number}>
                                            {achievement.ingredients}
                                        </span>
                                        {' '}
                                        ингред.
                                    </div>
                                </div>
                            )}
                            {achievement.time && (
                                <div className={css.achievementsPopupStatsMetric}>
                                    <div className={css.achievementsPopupStatsMetricText}>
                                        Сэкономили времени
                                    </div>
                                    <div className={css.achievementsPopupStatsDivider} />
                                    <div className={css.achievementsPopupStatsMetricCount}>
                                        <span className={css.number}>
                                            {achievement.time}
                                        </span>
                                        {' '}
                                        ч
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* BADGE */}
                {achievement.favouriteDish && (
                    <div
                        className={css.achievementsPopupBadgeRoot}
                        aria-label="achievements-pop-up-badge"
                    >
                        <div className={css.achievementsPopupBadgeCard}>
                            <div className={css.achievementsPopupBadgeIcon}>
                                <BadgeIcon />
                            </div>
                            <div className={css.achievementsPopupBadgeTitle}>
                                Любимое блюдо
                            </div>
                            <div className={css.achievementsPopupBadgeDescription}>
                                {tp.execute(achievement.favouriteDish)}
                            </div>
                        </div>
                    </div>
                )}
                {/* NY BANNER */}
                <div className={css.achievementsPopupNYBanner}>
                    <div className="">
                        {/* eslint-disable-next-line */}
                        <div
                            className={css.achievementsPopupNYbg}
                            onClick={handleGoToNYPage}
                        >
                            <UIButton onClick={handleGoToNYPage}>
                                Отметим новый год
                            </UIButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
