/* eslint-disable max-len */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import classNames from 'classnames';
import s from './video-modal.scss';

export function VideoModal(props) {
    const {
        video,
        handleClose,
        isSpa = false,
    } = props;

    const scrollContentRef = useRef(null);

    useEffect(() => {
        disableBodyScroll(scrollContentRef.current);
        return () => { clearAllBodyScrollLocks(); };
    }, []);

    const videoSliderModalRootClasses = classNames({
        [s['video-slider-modal']]: true,
        [s.spa]: isSpa,
    });

    return (
        <Portal>
            <div className={videoSliderModalRootClasses} ref={scrollContentRef}>
                <div className={s['video-slider-modal-container']}>
                    <div className={s['video-slider-modal-header']}>
                        <button
                            type="button"
                            onClick={handleClose}
                            className={s['video-slider-modal-close']}
                        >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.85949 0.440453C2.27621 -0.146655 1.33052 -0.146655 0.747244 0.440453L0.575981 0.612839C-0.00729844 1.19995 -0.00729776 2.15184 0.575982 2.73894L7.71665 9.92648L0.43746 17.2534C-0.14582 17.8405 -0.14582 18.7924 0.43746 19.3795L0.608723 19.5519C1.192 20.139 2.13769 20.139 2.72097 19.5519L10.0002 12.225L17.279 19.5516C17.8623 20.1387 18.808 20.1387 19.3913 19.5516L19.5625 19.3792C20.1458 18.7921 20.1458 17.8402 19.5625 17.2531L12.2837 9.92647L19.424 2.73928C20.0073 2.15217 20.0073 1.20028 19.424 0.613172L19.2527 0.440785C18.6695 -0.146322 17.7238 -0.146322 17.1405 0.440786L10.0002 7.62798L2.85949 0.440453Z" fill="#9C9C9C" />
                            </svg>
                        </button>
                    </div>
                    <div className={s['video-slider-modal-iframe']}>
                        <iframe
                            width="100%"
                            height="100%"
                            src={video?.src}
                            title={video?.title}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        />
                    </div>
                </div>
            </div>
        </Portal>
    );
}

VideoModal.propTypes = {
    video: PropTypes.shape({
        src: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    handleClose: PropTypes.func.isRequired,
    isSpa: PropTypes.bool,
};

