import merge from 'lodash/merge';
import { getHiddenElements } from 'app/utils/url';

/**
 * @description Определение скрытых элементов в корзине
 */
export const checkHiddenElemnts = (props, _set, _get) => {
    const hiddenElements = getHiddenElements();
    const { type, needDeepCheck, preparedAreas } = _get();

    const computedAreas = {
        top: {
            isCustomizationComment: !hiddenElements.comment,
        },
    };
    const nextAreas = merge({}, preparedAreas, computedAreas);

    _set({
        type,
        needDeepCheck,
        preparedAreas: nextAreas,
    });
};
