import Raven from 'raven-js';
import queryString from 'query-string';
import { analyticService, userSessionService } from 'global/services';
import { clearCachedInvoiceData } from 'app/utils/invoice';
import { appendTagReaction } from 'app/hooks/useFilterHandlers';
import { promocodeVar, subscriptionTypeVar } from 'app/apollo/reaction';


const onUserFetchedBehaviorRecord = [
    {
        eventName: 'initUserSentryContext',
        check: (data: any) => data,
        /**
        * @description Ставит данные о юзере в sentry для более удобной навигации
        * Ошибки будет ассоциированы с пользователем
        */
        emit: (data: any) => {
            const {
                id, phone, email, region,
            } = data;
            Raven.setUserContext({
                id, phone, email, region,
            });
        },
    },
    {
        eventName: 'initUserPersonalPromo',
        check: (data: any) => {
            const { location: { search, pathname } } = window;

            const queryObject = queryString.parse(search);

            const isPosiblePathnameRange = ['/menu/', '/basket/', '/profile/'].includes(pathname);
            const isPersonalPromo = Boolean(data.personalPromo?.linkParams);
            return ![isPersonalPromo, !queryObject?.promo_code, isPosiblePathnameRange].includes(false);
        },

        /**
        * @description Ставит персональное промо, если оно есть
        * @see Jira:FRNT-2408
        */
        emit: async (data: any) => {
            // Приходят из запроса
            const {
                promo_code: promocode,
                payment,
            } = queryString.parse(data.personalPromo.linkParams);

            // CHECK FOR TS
            const currentPromocode = !Array.isArray(promocode) ? promocode : null;
            // @ts-ignore
            promocodeVar(currentPromocode);

            if (payment === 'online') {
                // note: вы этом блоке не нужен вызов мутации updateBilling так как эта мутация вызывается
                subscriptionTypeVar('singlePay');
            }
        },
    },
];

/**
  * @note из connect
  * @description Инициирует состояния связанное userQuery. Выполняется после первого запроса User'a
  * @example Выставляет персональное промо если оно есть, выставляет userContext в sentry
 */
const initUserDeps = (data: any) => {
    onUserFetchedBehaviorRecord
        .filter((event) => event.check(data))
        .forEach((event) => { event.emit(data); });
    return '';
};

const onUserKnown = (userQueryRes: any) => {
    const {
        id, name, email, phone, region, filterTags, hasPendingDelivery,
    } = userQueryRes;
    const normolizedEmail = email || `${phone}@unknown.email`;

    if (!hasPendingDelivery) {
        clearCachedInvoiceData();
    }

    initUserDeps(userQueryRes);

    // закидываем id в ls
    localStorage.setItem('userId', id);

    // Ставим id в обьект сессии
    userSessionService.setData({ id });

    // Пушим аналитику
    analyticService
        .push({
            eventName: 'setUserId',
            userId: id,
        })
        .push({
            eventName: 'Flocktory_Login',
            eventParams: {
                name,
                email: normolizedEmail,
            },
        });

    // Задаем контекст для sentry
    Raven.setUserContext({
        id, phone, email, region,
    });

    // ставим теги кастомизации в фильтр меню
    filterTags?.forEach((tagId: string) => {
        appendTagReaction({ id: tagId });
    });
};

const onUserUnknown = () => { };

/**
 * @description экшены после инициализируемого запроса user'а
 */
export function onUserInited(userQueryRes: any) {
    return userQueryRes ? onUserKnown(userQueryRes) : onUserUnknown();
}
