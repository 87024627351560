import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { isDesktop } from 'app/utils/resolution';

import { Mobile, Desktop } from 'app/components/Responsive';
import ArrowIcon from 'assets/svg/arrow.component.svg';
import PortionDesktop from './components/PortionDesktop';
import PortionMobile from './components/PortionMobile';
import styles from './portion-select.scss';


const animationClasses = {
    enter: styles.dropdownEnter,
    enterActive: styles.dropdownEnterActive,
    exit: styles.dropdownExit,
    exitActive: styles.dropdownExitActive,
};

const optionHeights = {
    normal: 45,
    large: 51,
    small: 47,
};


class PortionSelect extends React.PureComponent {
    optionsRef = React.createRef();

    containerRef = React.createRef();

    state = {
        isExpanded: false,
        optionsOffset: 0,
        forceOptionsOffset: null,
    };

    componentDidUpdate() {
        this.setListOffset();
    }

    // Handlers ================================================================

    handleChangePortion(portion) {
        this.handleToggle();
        const { onChange } = this.props;
        onChange({ value: portion });
    }

    setListOffset() {
        const {
            portions,
            portion: selectedPortion,
            size,
            parentRef,
            scrollbarsRef,
            renderContext,
            from,
        } = this.props;

        if (from === 'basket') {
            this.setState({
                optionsOffset: 1000,
            });
            return;
        }

        const { isExpanded } = this.state;
        if (!isExpanded) return;

        const selectedIndex = portions.findIndex((p) => p === selectedPortion) || 0;
        let parentTop;
        let parentBottom;
        if (scrollbarsRef.current) {
            const scrollbars = scrollbarsRef.current;
            parentBottom = scrollbars.getScrollHeight() - scrollbars.getScrollTop();
            parentTop = scrollbars.view ? scrollbars.view.getBoundingClientRect().top : 0;
        } else {
            parentBottom = parentRef.current.getBoundingClientRect().bottom;
            parentTop = parentRef.current.getBoundingClientRect().top;
        }
        const optionHeight = optionHeights[size];

        const optionsHeight = optionHeight * portions.length;

        const optionsBottom = this.containerRef.current.getBoundingClientRect().bottom + optionsHeight;

        const optionsOffset = -optionHeight * selectedIndex;

        const optionsTop = optionsBottom - optionsHeight + optionsOffset - optionHeight;
        if ((optionsBottom + 200) > parentBottom) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ forceOptionsOffset: -optionsHeight + optionHeight - 100 });
        } else if (optionsTop < parentTop) {
            this.setState({
                forceOptionsOffset: -optionHeight,
            });
        } else {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                optionsOffset,
                forceOptionsOffset: null,
            });
        }
    }

    getOptionsOffset(parentRect = null) {
        const rect = this.optionsRef.current.getBoundingClientRect();
        const headerHeight = 120; // TODO:

        const parentTop = parentRect ? parentRect.top : headerHeight;
        const parentBottom = parentRect ? parentRect.bottom : document.documentElement.clientHeight;

        const top = rect.top - parentTop;
        const { bottom } = rect;

        const offset = {
            top: {
                inViewport: top >= 0,
                value: top,
            },
            bottom: {
                inViewport: bottom <= parentBottom,
                value: bottom,
            },
        };
        return offset;
    }

    // eslint-disable-next-line react/sort-comp
    scrollSelectIntoView() {
        const { isExpanded } = this.state;
        const { from } = this.props;
        if (from === 'basket') return;
        if (!isExpanded) return;

        const { scrollbarsRef, scrollPadding } = this.props;
        const selectContainerEl = this.optionsRef.current;

        setTimeout(() => {
            const parentRect = scrollbarsRef.current && scrollbarsRef.current.container.getBoundingClientRect();

            const offset = this.getOptionsOffset(parentRect);
            const isDropdownOverlappedAbove = !offset.top.inViewport;
            const isDropdownOverlappedBelow = !offset.bottom.inViewport;
            const isDropdownInViewport = offset.top.inViewport && offset.bottom.inViewport;
            const isInBasket = scrollbarsRef.current;

            if (isDropdownInViewport) return;

            const scrollElement = isInBasket ? scrollbarsRef.current.view : document.documentElement;
            let scrollYValue = 0;

            if (isDropdownOverlappedAbove) {
                scrollYValue = -scrollPadding.top;
            }
            if (isDropdownOverlappedBelow) {
                scrollYValue = scrollPadding.bottom;
            }

            selectContainerEl.scrollIntoView({ block: 'nearest' });
            scrollElement.scrollBy(0, scrollYValue);
        });
    }

    handleCloseOptions = () => {
        this.handleToggle(null, { isExpanded: false });
    };

    handleToggle = (e, nextState) => {
        const { onTogglePortionSelect } = this.props;
        const { isExpanded } = this.state;
        const next = nextState ? nextState.isExpanded : !isExpanded;

        this.setState({
            isExpanded: next,
        }, this.scrollSelectIntoView);
        if (!next) {
            onTogglePortionSelect(next);
        }
    };

    handleMobileToggle = () => {
        const {
            onClickMobilePortions, id, portions, portion, weight, onChange, portionAlias,
        } = this.props;
        onClickMobilePortions({
            id, portions, portion, weight, onChange, portionAlias,
        });
    };

    // Render ==================================================================

    // eslint-disable-next-line class-methods-use-this
    renderArrow() {
        const { size, placement } = this.props;
        const isSizeSmall = size === 'small';

        const toggleClasses = classNames({
            'styles.select-toggle': true,
            'styles.small': isSizeSmall,
            'styles.placement-details': placement === 'dish-details',
        });

        const iconClasses = classNames({
            'styles.select-toggle-icon': true,
            'styles.is-expanded': false,
        });

        return (
            <div styleName={toggleClasses}>
                {isSizeSmall ? (
                    <div styleName="styles.select-toggle-icon styles.small" />
                ) : (
                    <div styleName={iconClasses}><ArrowIcon /></div>
                )}
            </div>
        );
    }

    renderList() {
        const { isExpanded, optionsOffset, forceOptionsOffset } = this.state;
        const {
            placement,
            portions,
            portion: selectedPortion,
            portionAlias,
            weight,
            size,
            id,
        } = this.props;
        // const dropdownStyles = { top: forceOptionsOffset || optionsOffset };
        const dropdownStyles = { top: 0 };
        const transparentStyles = classNames({
            transparent: true,
            transparentSmall: size === 'small' && styles.transparentSmall,
        });

        const dropdownClasses = classNames({
            'styles.select-dropdown': true,
            'styles.placement-details': placement === 'dish-details',
        });

        return (
            <div>
                {isExpanded && (
                    <button
                        type="button"
                        aria-label="Закрыть"
                        styleName="styles.dropdown-overlay"
                        onClick={this.handleCloseOptions}
                    />
                )}

                <CSSTransition
                    in={isExpanded}
                    timeout={200}
                    classNames={animationClasses}
                    unmountOnExit
                >
                    <div
                        styleName="styles.select-dropdown-container"
                        style={dropdownStyles}
                        ref={this.optionsRef}
                    >
                        <div styleName={dropdownClasses}>
                            {portions.map((portion) => {
                                const buttonClasses = classNames({
                                    'styles.select-dropdown-item': true,
                                    'styles.active': portion === selectedPortion,
                                });
                                return (
                                    <button
                                        key={portion}
                                        type="button"
                                        styleName={buttonClasses}
                                        onClick={() => this.handleChangePortion(portion)}
                                    >
                                        <PortionDesktop
                                            portionAlias={portionAlias}
                                            portion={portion}
                                            weight={weight}
                                            size={size}
                                            colors={portion === selectedPortion ? 'normal' : 'light'}
                                            isInteractive
                                            isActive={portion === selectedPortion}
                                            id={id}
                                        />
                                    </button>
                                );
                            })}
                        </div>
                        {/* TODO: хак для регулирования отступа снизу при подскролле контейнера */}
                        <button
                            type="button"
                            aria-label="Закрыть"
                            onClick={this.handleCloseOptions}
                            styleName={transparentStyles}
                        />
                    </div>
                </CSSTransition>
            </div>
        );
    }

    renderDesktopSelect() {
        const {
            portionAlias,
            size,
            basket,
            weight,
            portion,
            portions,
            disabled: disabledProp,
            placement,
            id,
        } = this.props;

        const hasPortions = portions && portions.length > 0;
        const disabled = disabledProp || !hasPortions;

        const containerClassName = classNames({
            'styles.large': size === 'large',
            'styles.small': size === 'small',
            'styles.placement-details': placement === 'dish-details',
            basket,
        });

        const selectClassName = classNames({
            'styles.select': true,
            'styles.small': size === 'small',
            'styles.placement-details': placement === 'dish-details',
        });

        const buttonClassName = classNames({
            'styles.select-button': true,
            'styles.disabled': disabled,
            'styles.small': size === 'small',
            'styles.placement-details': placement === 'dish-details',
        });

        return (
            <div styleName={containerClassName} ref={this.containerRef}>
                <div styleName={selectClassName}>
                    <Mobile>
                        <button
                            disabled={disabled}
                            type="button"
                            styleName={buttonClassName}
                            onClick={this.handleMobileToggle}
                        >
                            <div styleName="styles.select-value">
                                <PortionDesktop
                                    portionAlias={portionAlias}
                                    portion={portion}
                                    weight={weight}
                                    size={size}
                                    basket={basket}
                                />
                            </div>
                            {disabled ? null : this.renderArrow()}
                        </button>
                    </Mobile>

                    <Desktop>
                        <button
                            disabled={disabled}
                            type="button"
                            styleName={buttonClassName}
                            onClick={disabled ? undefined : this.handleToggle}
                        >
                            <div styleName="styles.select-value">
                                <PortionDesktop
                                    portionAlias={portionAlias}
                                    portion={portion}
                                    weight={weight}
                                    size={size}
                                    basket={basket}
                                    placement={placement}
                                    id={id}
                                />
                            </div>
                            {disabled ? null : this.renderArrow()}
                        </button>
                        {!disabled && this.renderList()}
                    </Desktop>
                </div>
            </div>
        );
    }

    renderMobileSelect() {
        const {
            portionAlias,
            portion,
            portions,
            disabled: disabledProp,
            dishItemType,
            locale,
            from,
            id,
        } = this.props;

        const hasPortions = portions && portions.length > 0;
        const disabled = disabledProp || !hasPortions;

        const deskTopHandler = disabled ? undefined : this.handleToggle;

        const isBasketContext = from === 'basket' && isDesktop();
        const isDesktopHadler = isDesktop() ? deskTopHandler : this.handleMobileToggle;

        return (
            <button
                disabled={disabled}
                type="button"
                onClick={isDesktopHadler}
                onKeyDown={() => { }}
            >
                <PortionMobile
                    portionAlias={portionAlias}
                    portion={portion}
                    disabled={disabled}
                    dishItemType={dishItemType}
                    locale={locale}
                />
                {!disabled && isBasketContext && this.renderList()}
            </button>
        );
    }

    render() {
        const { dishItemType } = this.props;

        return dishItemType === 'desktop'
            ? this.renderDesktopSelect()
            : this.renderMobileSelect();
    }
}


PortionSelect.propTypes = {
    portionAlias: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    portions: PropTypes.arrayOf(PropTypes.number).isRequired,
    weight: PropTypes.number.isRequired,
    portion: PropTypes.number.isRequired,
    size: PropTypes.oneOf(['small', 'large', 'normal', null]),
    placement: PropTypes.oneOf([null, 'dish-details']),
    parentRef: PropTypes.shape({
        current: PropTypes.shape({}),
    }),
    scrollbarsRef: PropTypes.shape({
        current: PropTypes.shape({}),
    }),
    id: PropTypes.string,
    dishItemType: PropTypes.string,
    onClickMobilePortions: PropTypes.func,
    onTogglePortionSelect: PropTypes.func,
    basket: PropTypes.bool,
    disabled: PropTypes.bool,
    scrollPadding: PropTypes.shape({
        top: PropTypes.number,
        bottom: PropTypes.number,
    }),
};

const PortionSelectFC = (props) => {
    const {
        portionAlias = null,
        onChange = () => { },
        onTogglePortionSelect = () => { },
        onClickMobilePortions = null,
        size = 'normal',
        placement = null,
        parentRef = {
            current: document.body,
        },
        basket = false,
        scrollbarsRef = {},
        id = null,
        disabled = false,
        scrollPadding = {
            top: 0,
            bottom: 0,
        },
        dishItemType = 'desktop', // desktop, small
    } = props;

    return (
        <PortionSelect
            {...props}
            portionAlias={portionAlias}
            onChange={onChange}
            onTogglePortionSelect={onTogglePortionSelect}
            onClickMobilePortions={onClickMobilePortions}
            size={size}
            placement={placement}
            parentRef={parentRef}
            basket={basket}
            scrollbarsRef={scrollbarsRef}
            id={id}
            disabled={disabled}
            scrollPadding={scrollPadding}
            dishItemType={dishItemType}
        />
    );
};

export default PortionSelectFC;
