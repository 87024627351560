/* eslint-disable camelcase */
import React, {
    useEffect, useMemo, useState, useRef,
    MutableRefObject,
} from 'react';

import { analyticService } from 'global/services';
import { FrameDialog } from 'app/components/Dialog';
import { UIButton, UIRadioIcon } from 'app/components/ui';
import { Portal } from 'react-portal';
import cn from 'classnames';
import { DialogStaticDesktop } from 'app/components/Dialog/BR1156/DialogStaticDesktop';
import { DialogAnimationWrapper } from 'app/components/Dialog/DialogAnimationWrapper';
import { UIRadioIconStatic } from 'app/components/ui/UIRadioIcon/UIRadioIconStatic';
import { Desktop, Mobile } from 'app/components/Responsive';
import { gql, useApolloClient } from '@apollo/client';
import { CATEGORIES_URL_VALUES } from 'app/const/categories';
import Icon from './img/arrow.component.svg';

import css from './mobileCustomIngredientsSelect.module.scss';
import { ingredientTypeTitleRecord } from '../customIngredientsSelectDishRow.records';
// import { NextOverlay } from './overlay/nextOverlay';
import { ICustomIngredientsSelectProps } from '../CustomIngredientsSelectDishRow.types';

interface ISelectComponentProps extends ICustomIngredientsSelectProps {
    isOpen: boolean
    handleSubmit: () => void
    handleClose: () => void
}

const DialogWrapper = (props: any) => {
    const { children, handleClose, handleSubmit } = props;

    return (
        <>
            <Mobile>
                {/* @ts-ignore */}
                <FrameDialog
                    handleClose={handleClose}
                >
                    {children}
                </FrameDialog>
            </Mobile>
            <Desktop>
                <DialogStaticDesktop
                    notifyOnly
                    onConfirm={handleSubmit}
                    onReject={handleClose}
                    confirmText="Готово"
                    isCloseConfirmAction={false}
                >
                    {children}
                </DialogStaticDesktop>
            </Desktop>
        </>
    );
};

const SelectComponent = (props: ISelectComponentProps) => {
    const {
        id,
        isOpen,
        handleClose,
        selectedDishId,
        secondDishPart,
        ingredientsList = [],
        checkIsSelected,
        onNextIdSelected,
        handleSubmit = () => { },
    } = props;

    const previousSelectedId: MutableRefObject<string | null> = useRef<string | null>(null);
    const source_name = window?.location?.pathname?.includes('menu') ? 'menu' : 'basket';

    const handleClickOnIngredientItem = (targetId: string) => {
        onNextIdSelected(targetId);
        analyticService.push({
            eventName: 'Custom_Card_Change',
            dish_id: targetId,
            source_name,
        });
    };

    useEffect(() => {
        if (isOpen) {
            analyticService.push({
                eventName: 'Custom_Card_Open',
                dish_id: id,
                source_name,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    /* SELECTORS */
    const title = useMemo(
        // @ts-ignore
        () => ingredientTypeTitleRecord[secondDishPart] || '',
        [secondDishPart],
    );

    return (
        // @ts-ignore
        <DialogWrapper
            handleSubmit={handleSubmit}
            handleClose={() => {
                const prevId: string | null = previousSelectedId.current;
                if (prevId) {
                    handleClickOnIngredientItem(prevId);
                }
                handleClose();
            }}
        >
            <div className={css.selectIngredientsRoot}>
                <div className={css.selectIngredientsTitle}>
                    Выберите
                    {' '}
                    {title}
                </div>
                <div className={css.selectIngredientsItems}>
                    {ingredientsList.map((item) => (
                        <button
                            className={cn(css.selectIngredientsItemButton)}
                            type="button"
                            onClick={() => {
                                previousSelectedId.current = id;
                                handleClickOnIngredientItem(item.id);
                            }}
                            id={item.id}
                            key={item.id}
                            disabled={selectedDishId === item.id}
                        >
                            <div
                                className={
                                    // @ts-ignore
                                    cn(css.selectIngredientsItemText, checkIsSelected(item.id, selectedDishId))
                                }
                            >
                                {item.replaceableIngredient}
                            </div>
                            <Mobile>
                                <UIRadioIcon checked={item.id === selectedDishId} />
                            </Mobile>
                            <Desktop>
                                <UIRadioIconStatic checked={item.id === selectedDishId} />
                            </Desktop>
                        </button>
                    ))}
                </div>
                <Mobile>
                    <div className={css.selectIngredientsSubmit}>
                        <UIButton onClick={handleSubmit}>
                            Готово
                        </UIButton>
                    </div>
                </Mobile>
            </div>
        </DialogWrapper>
    );
};

// NOTE: Используется в корзинах в desktop'е
export function MobileCustomIngredientsSelect(props: ICustomIngredientsSelectProps) {
    const {
        id,
        selectedDishId,
        ingredientTypeText,
        replaceableIngredient,
        checkIsSelected,
        ingredientsList,
        secondDishPart,
        onNextIdSelected,
        environmentContext,
        onSubmitHandler = () => { },
        disabled = false,
    } = props;

    const client = useApolloClient();

    const { category_id } = client.readFragment({
        id: `menuDish:${selectedDishId}`,
        fragment: gql`
            fragment DISH_CATEGORY_ANALYTIC_FRAGMENT on menuDish {
                category_id,
            }`,
    }) || { category_id: 3 };

    const [isOpen, setIsOpen] = useState(false);
    const source_name = window?.location?.pathname?.includes('menu') ? 'menu' : 'basket';

    const openSelect = () => {
        analyticService.push({
            eventName: 'Custom_Card_Click',
            dish_id: id,
            source_name,
        });

        // @ts-ignore
        setIsOpen(true);
    };

    const handleClose = () => {
        analyticService.push({
            eventName: 'Custom_Card_Close',
            dish_id: id,
            source_name,
        });
        setIsOpen(false);
    };

    const handleSubmit = () => {
        analyticService.push({
            eventName: 'Custom_Card_Close_Confirmation',
            dish_id: id,
            source_name,
            // @ts-expect-error
            dish_category: CATEGORIES_URL_VALUES[category_id],
        });
        onSubmitHandler({
            onCompoleted: () => {
                setIsOpen(false);
            },
        });
    };

    const customIngredientsSelect = cn({
        [css.customIngredientsSelectRoot]: true,
        [css.basket]: environmentContext === 'basket',
        [css.menu]: environmentContext === 'menu',
    });

    const titleClasses = cn({
        [css.customIngredientsSelectButton]: true,
        [css.basket]: environmentContext === 'basket',
        [css.menu]: environmentContext === 'menu',
    });

    const iconClasses = cn({
        [css.customIngredientsSelectIcon]: true,
        [css.basket]: environmentContext === 'basket',
        [css.menu]: environmentContext === 'menu',
    });

    const customIngredientsSelectTextClasses = cn({
        [css.customIngredientsSelectText]: true,
        [css.basket]: environmentContext === 'basket',
        [css.menu]: environmentContext === 'menu',
    });

    return (
        <div className={customIngredientsSelect}>
            <button
                type="button"
                onClick={openSelect}
                className={titleClasses}
                disabled={disabled}
            >
                <div className={customIngredientsSelectTextClasses}>
                    <span className={css.ingredientType}>
                        {ingredientTypeText}
                    </span>
                    <span className={css.clampText}>
                        {replaceableIngredient}
                    </span>
                </div>
                <div className={iconClasses}>
                    <Icon />
                </div>
            </button>
            <Portal>
                <DialogAnimationWrapper
                    isOpen={isOpen}
                    onReject={handleClose}
                >
                    <SelectComponent
                        id={id}
                        isOpen={isOpen}
                        handleClose={handleClose}
                        selectedDishId={selectedDishId}
                        secondDishPart={secondDishPart}
                        checkIsSelected={checkIsSelected}
                        ingredientsList={ingredientsList}
                        onNextIdSelected={onNextIdSelected}
                        handleSubmit={handleSubmit}
                    />
                </DialogAnimationWrapper>
            </Portal>
        </div>

    );
}
