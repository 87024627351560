import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import Raven from 'raven-js';
import compose from 'lodash/flowRight';

/* NETWORK */
import USER_QUERY from 'app/graphql/network/auth/userQuery.graphql';
import billingMutation from 'app/graphql/network/billing';

/* CLIENT */
import { GET_SUBSCRIPTION_TYPE } from 'app/graphql/client/subscription';
import { GET_PAYMENT_METHOD } from 'app/graphql/client/payment';
import { GET_PROMOCODE } from 'app/graphql/client/promocode';

/* HOCS */
import withPushLead from 'app/containers/withPushLead';
import { withAuthContext } from 'app/containers/AuthContainer';
import { getBillingPaymentMethod } from 'app/containers/withBillingParams';
import { withFiltersContext } from 'app/containers/contexts/filters.context/filters.context';
import { withBasketQuery } from './connectToBasket';
import { withSelectedFilters } from './connectToSelectedFilters';


/**/
const connect = (Component) => compose(
    withRouter,
    withSelectedFilters,
    graphql(GET_SUBSCRIPTION_TYPE, { name: 'subscriptionTypeQuery' }),
    graphql(GET_PAYMENT_METHOD, { name: 'paymentMethod' }),
    graphql(GET_PROMOCODE, { name: 'promocode' }),

    withFiltersContext,
    graphql(USER_QUERY, {
        name: 'userQuery',
        options: {
            onError: (error) => {
                Raven.captureMessage('[Auth]: userQuery auth error', {
                    extra: {
                        error,
                        scope: 'connect.js',
                    },
                    tags: {
                        type: 'Auth error',
                    },
                });
            },
            context: {
                massage: 'app:init:connect',
            },
        },
    }),

    // withBasketQuery,
    graphql(billingMutation, {
        name: 'updateBilling',
        options: (props) => {
            /*
                Добавляет дефолтные значения параметров, если они не переданы в мутацию явно
            */
            const {
                subscriptionTypeQuery: { subscriptionType },
                paymentMethod: { paymentMethod },
                selectedFilters: { selectedPeriod },
                promocode: { promocode },
            } = props;

            const { billingPaymentMethod } = getBillingPaymentMethod({
                statePaymentMethod: paymentMethod,
                subscriptionType,
            });

            return {
                variables: {
                    promocode,
                    period: selectedPeriod,
                    payment_method: billingPaymentMethod,
                },
            };
        },
    }),
    withPushLead,
    withAuthContext,
)(Component);

export default connect;
