/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import {
    speed,
    sweets,
    nz,
    breakfast,
    veg,
    forkids,
    menu,
    hits,
    creativity,
    guests,
    green,
    soups,
    hp,
    ny,
    maxim,
    hochland,
    christmas,
    romantic,
    maslenitsa,
    marchgifts,
    march,
    fasting,
    YaP,
    easter,
} from 'app/assets/dishesSelections/dishesSelections';

import { periods as hpPeriods } from 'global/const/hp';
// import { periods as hochlandPeriods } from 'global/const/hochland';
import { periods as easterPeriods } from 'global/const/easter';
import { periods as maslenitsaPeriods } from 'global/const/maslenitsa';
import { periods as marchgiftsPeriods } from 'global/const/marchgifts';
import { periods as marchPeriods } from 'global/const/march';
import { periods as YaPPeriods } from 'global/const/yapracticum';

import { isDesktop } from 'app/utils/resolution';


type TPayload = {
    selectedPeriod: string,
    availableLinks: any,
}

type TBannerType = 'link' | 'selection' | 'action/close' | 'detail';

export type TActiveSelectionKeys = '1220' | '1809' | '1218' | '1218' | '2028' |
    'soups' | 'kids' | 'hits' | 'creativity' | 'guests' | 'christmas' | 'ny' | 'hp'
    | 'march' | 'maslenitsa' | 'sweets' | 'breakfast' | 'film' | 'kozel' | 'ny' | 'maxim' | 'hochland' | 'romantic' | 'march' | 'marchgifts'
    | '2121' | 'YaP' | 'easter'

export type TActiveLinks = 'menu';

type TBannersMeta = {
    // eslint-disable-next-line no-unused-vars
    check: (data: TPayload) => boolean,
    title: React.ReactElement | string
    backgroundImagePath: string
    preloadImage: string,
    id: string
    tag: TActiveSelectionKeys | TActiveLinks,
    type: TBannerType,
    href?: string,
}

export const selectionRecord: Array<TBannersMeta> = [
    // {
    //     check: (props) => {
    //         if (isDesktop()) return false;
    //         return maslenitsaPeriods.includes(props.selectedPeriod);
    //     },
    //     title: <>Масленицa</>,
    //     backgroundImagePath: maslenitsa.xs,
    //     preloadImage: maslenitsa.m,
    //     id: 'maslenitsa',
    //     tag: 'maslenitsa',
    //     type: 'selection',
    // },
    {
        check: (props) => {
            if (isDesktop()) return false;
            return easterPeriods.includes(props.selectedPeriod);
        },
        title: <>
            Пасхальный
            <br />
            завтрак
        </>,
        backgroundImagePath: easter.xs,
        preloadImage: easter.m,
        id: 'easter',
        tag: 'easter',
        type: 'selection',
    },
    {
        check: (props) => {
            if (isDesktop()) return false;
            return [
                '2025-03-03',
                '2025-03-10',
                '2025-03-17',
                '2025-03-24',
                '2025-03-31',
                '2025-04-07',
                '2025-04-14',
            ].includes(props.selectedPeriod);
        },
        title: <>Постное<br />меню</>,
        backgroundImagePath: fasting.xs,
        preloadImage: fasting.m,
        id: 'fasting',
        tag: '2121',
        type: 'selection',
    },
    {
        check: (props) => {
            if (isDesktop()) return false;
            return YaPPeriods.includes(props.selectedPeriod);
        },
        title: '',
        backgroundImagePath: YaP.xs,
        preloadImage: YaP.m,
        id: 'YaP',
        tag: 'YaP',
        type: 'selection',
    },
    {
        check: (props) => {
            if (isDesktop()) return false;
            return marchgiftsPeriods.includes(props.selectedPeriod);
        },
        title: <>
            Подарки
            <br />
            к 8 марта
        </>,
        backgroundImagePath: marchgifts.xs,
        preloadImage: marchgifts.xs,
        id: 'marchgifts',
        tag: 'marchgifts',
        type: 'link',
        href: '/marchgifts',
    },
    // {
    //     check: () => !isDesktop(),
    //     title: (
    //         <>
    //             Время
    //             <br />
    //             свиданий
    //             <br />
    //             дома
    //         </>
    //     ),
    //     backgroundImagePath: romantic.xs,
    //     preloadImage: romantic.m,
    //     id: 'romantic',
    //     tag: 'romantic',
    //     type: 'detail',
    // },
    // {
    //     check: (props) => {
    //         if (isDesktop()) return false;
    //         return hpPeriods.includes(props.selectedPeriod);
    //     },
    //     title: (
    //         <>
    //             Меню
    //             <br />
    //             из&nbsp;Хогвартса
    //         </>
    //     ),
    //     backgroundImagePath: hp.xs,
    //     preloadImage: hp.m,
    //     id: 'hp',
    //     tag: 'hp',
    //     type: 'selection',
    // },
    {
        check: () => {
            if (isDesktop()) return false;
            return true;
        },
        title: <>Время для<br />творчества</>,
        backgroundImagePath: creativity.xs,
        preloadImage: creativity.m,
        id: 'creativity',
        tag: 'creativity',
        type: 'selection',
    },
    {
        check: () => {
            if (isDesktop()) return false;
            return true;
        },
        title: <>К приходу<br />гостей</>,
        backgroundImagePath: guests.xs,
        preloadImage: guests.m,
        id: 'guests',
        tag: 'guests',
        type: 'selection',
    },
    {
        check: () => {
            if (isDesktop()) return false;
            return true;
        },
        title: '',
        backgroundImagePath: green.xs,
        preloadImage: green.m,
        id: 'green',
        tag: '1220',
        type: 'selection',
    },
    {
        check: () => !isDesktop(),
        title: <>Детское<br />меню</>,
        backgroundImagePath: forkids.xs,
        preloadImage: forkids.m,
        id: 'kids',
        tag: 'kids',
        type: 'selection',
    },
    {
        check: () => {
            if (isDesktop()) return false;
            return true;
        },
        title: <>Хиты<br />вне времени</>,
        backgroundImagePath: hits.xs,
        preloadImage: hits.m,
        id: 'hits',
        tag: 'hits',
        type: 'selection',
    },
    {
        check: () => !isDesktop(),
        title: <>Супы<br />за 5-15 минут</>,
        backgroundImagePath: soups.xs,
        preloadImage: soups.m,
        id: 'soups',
        tag: 'soups',
        type: 'selection',
    },
    {
        check: () => !isDesktop(),
        title: <>Вег-меню<br />Лучшее</>,
        backgroundImagePath: veg.xs,
        preloadImage: veg.m,
        id: 'veg',
        tag: '1218',
        type: 'selection',
    },
    {
        check: () => !isDesktop(),
        title: <>Готовим<br /> за 15 мин!</>,
        backgroundImagePath: speed.xs,
        preloadImage: speed.m,
        id: 'fast',
        tag: '1809',
        type: 'selection',
    },
    {
        check: () => !isDesktop(),
        title: <>Печём <br /> вместе</>,
        backgroundImagePath: sweets.xs,
        preloadImage: sweets.m,
        id: 'sweets',
        tag: 'sweets',
        type: 'selection',
    },
    {
        check: () => !isDesktop(),
        title: <>Богемные <br /> завтраки</>,
        backgroundImagePath: breakfast.xs,
        preloadImage: breakfast.m,
        id: 'breakfast',
        tag: 'breakfast',
        type: 'selection',
    },
    {
        check: () => !isDesktop(),
        title: <>Про запас</>,
        backgroundImagePath: nz.xs,
        preloadImage: nz.m,
        id: 'nz',
        tag: '2028',
        type: 'selection',
    },
    // FOR DESKTOP UPSELL
    {
        check: () => isDesktop(),
        title: <>Все блюда<br />меню</>,
        backgroundImagePath: menu.xs,
        preloadImage: menu.xs,
        id: 'menu',
        tag: 'menu',
        type: 'link',
        href: '/menu',
    },
    {
        check: () => isDesktop(),
        title: '',
        backgroundImagePath: green.xs,
        preloadImage: green.m,
        id: 'green',
        tag: '1220',
        type: 'link',
        href: '/green',
    },
    {
        check: () => isDesktop(),
        title: <>Детское<br />меню</>,
        backgroundImagePath: forkids.xs,
        preloadImage: forkids.m,
        id: 'kids',
        tag: 'kids',
        type: 'link',
        href: '/kids',
    },
];

// eslint-disable-next-line arrow-body-style
export const getSelectionRecord = (data: TPayload) => {
    return selectionRecord.filter((item: TBannersMeta) => item.check(data));
};


/**
    * Example
    */
// {
//     check: (props) => {
//         if (isDesktop()) return false;
//         return maslenitsaPeriods.includes(props.selectedPeriod);
//     },
//     title: <>Масленица</>,
//     backgroundImagePath: maslenitsa.xs,
//     preloadImage: maslenitsa.m,
//     id: 'maslenitsa',
//     tag: 'maslenitsa',
//     type: 'selection',
// },
// {
//     check: () => true,
//     title: <>Десерты<br />и выпечка</>,
//     backgroundImagePath: sweet.xs,
//     type: 'link',
//     id: 'sweet',
//     tag: 'sweet',
//     href: SWEET_PAGE.hrefRu,
// },
// {
//     check: (props) => {
//         const periods = ['2022-02-28', '2022-03-07'];
//         return periods.includes(props.selectedPeriod);
//     },
//     title: <>Меню<br />8 марта</>,
//     backgroundImagePath: special.xs,
//     preloadImage: special.m,
//     id: 'special',
//     tag: '2110',
// },
// {
//     check: (props) => {
//         if (isDesktop()) return false;
//         const periods = ['2022-10-10', '2022-10-17'];
//         return periods.includes(props.selectedPeriod);
//     },
//     title: (
//         <>
//             Меню
//             <br />
//             Oktoberfest
//         </>
//     ),
//     backgroundImagePath: special.xs,
//     preloadImage: special.m,
//     id: 'special',
//     tag: 'special',
// },
// {
//     check: (props) => {
//         if (isDesktop()) return false;
//         return easterPeriods.includes(props.selectedPeriod);
//     },
//     title: <>Пасхальное<br />меню</>,
//     backgroundImagePath: easter.xs,
//     preloadImage: easter.m,
//     id: 'easter',
//     tag: 'easter',
//     type: 'selection',
// },
// {
//     check: (props) => {
//         if (isDesktop()) return false;
//         return marchPeriods.includes(props.selectedPeriod);
//     },
//     title: <>Великие<br />женщины</>,
//     backgroundImagePath: march.xs,
//     preloadImage: march.m,
//     id: 'march',
//     tag: 'march',
//     type: 'selection',
// },

// {
//     check: (props) => {
//         if (isDesktop()) return false;
//         return hpPeriods.includes(props.selectedPeriod);
//     },
//     title: (
//         <>
//             Меню
//             <br />
//             из&nbsp;Хогвартса
//         </>
//     ),
//     backgroundImagePath: hp.xs,
//     preloadImage: hp.m,
//     id: 'hp',
//     tag: 'hp',
//     type: 'selection',
// },
