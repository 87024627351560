/* eslint-disable indent */
import React, {
    useState,
    useEffect,
    useReducer,
    useCallback,
    useContext,
    useMemo,
} from 'react';
import { Portal } from 'react-portal';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import cn from 'classnames';
import { useQuery } from '@apollo/client';

import { analyticService } from 'global/services';

import { DialogAnimated } from 'app/components/Dialog';
import USER_QUERY from 'app/graphql/network/auth/userQuery.graphql';

import {
    getConfirmCustomizationLocales,
} from 'app/views/Basket/components/BasketCustomizationTags.DEPRECATED/basket-customization-locales';

import { useCustomContradictionDefine } from 'app/views/Basket/hooks/useCustomContradictionDefine';
import { LocaleContext } from 'app/containers/LocaleProvider';
import { CheckoutStoreContext } from '../../store/checkoutStore.context';


import { StepAuth } from '../StepAuth/StepAuth';
import { StepAddress } from '../StepAddress/StepAddress';
import { StepDatetime } from '../StepDatetime/StepDatetime';
import { StepPayment } from '../StepPayment/StepPayment';


import {
    STEP_PHONE,
    STEP_ADDRESS,
    STEP_DATETIME,
    STEP_PAYMENT,
    STEP_CODE,
    EVENTS,
} from '../stepByStepCheckoutConst';
import { useAuthState } from '../stepByStepCheckoutHooks';

import './step-by-step-checkout.scss';


const STEPS_ORDER_AUTHED = [
    STEP_PHONE,
    STEP_ADDRESS,
    STEP_DATETIME,
    STEP_PAYMENT,
];

const STEPS_ORDER_NOT_AUTHED = [
    STEP_PHONE,
    STEP_ADDRESS,
    STEP_DATETIME,
    STEP_CODE,
    STEP_PAYMENT,
];

const STEPS_COMPONENTS = {
    [STEP_PHONE]: StepAuth,
    [STEP_ADDRESS]: StepAddress,
    [STEP_DATETIME]: StepDatetime,
    [STEP_PAYMENT]: StepPayment,
    [STEP_CODE]: StepAuth,
};

const initialState = {
    openedSteps: [],
    isNextStepButtonVisible: true,
};

function reducer(state, action) {
    switch (action.type) {
        case 'INIT':
            return {
                ...state,
                ...action.payload,
            };
        case 'OPEN_NEXT_STEP': {
            const nextOpenedSteps = [
                ...state.openedSteps,
                action.payload.nextStep,
            ];
            return {
                ...state,
                openedSteps: nextOpenedSteps,
            };
        }
        case 'CLOSE_CURRENT_STEP': {
            const nextOpenedSteps = state.openedSteps.slice(0, state.openedSteps.length - 1);
            return {
                ...state,
                openedSteps: nextOpenedSteps,
            };
        }
        case 'GO_TO_FIRST_STEP': {
            return {
                ...state,
                openedSteps: ['STEP_PHONE'],
            };
        }
        default:
            return state;
    }
}


export function StepByStepCheckout(props) {
    const {
        closeCheckout,
        [STEP_PAYMENT]: paymentProps,
        setIsCustomizationIgnored,
        setIsAddressChanged,
    } = props;
    const { dispatch: checkoutDispatch } = useContext(CheckoutStoreContext);

    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        openedSteps,
        isNextStepButtonVisible,
    } = state;


    const userQuery = useQuery(USER_QUERY, {
        onCompleted: () => {
            setIsAddressChanged(true);
        },
        fetchPolicy: 'cache-first',
        context: {
            step: 'checkout:refetch:SingleStepCheckout',
        },
    });

    const [
        authState,
        setAuthState,
        initialAuthState,
    ] = useAuthState(userQuery);

    const STEPS_ORDER = useMemo(() => {
        const authResult = authState === 'authResult';
        if (authResult) {
            return STEPS_ORDER_AUTHED;
        }
            return STEPS_ORDER_NOT_AUTHED;
    }, [authState]);

    const history = useHistory();
    const [isCustomizationDialogClosed, setIsCustomizationDialogClosed] = useState(false);

    const {
        isCustomizationDialogShown,
        setIsCustomizationDialogShown,
        dialogIngridient,
    } = useCustomContradictionDefine({ userQuery: { data: userQuery }, cart: paymentProps.cart });

    /**
    * Handlers
    */
    const handleGoToFirstStep = useCallback(() => {
        dispatch({
            type: 'GO_TO_FIRST_STEP',
        });
    }, []);

    const handleCloseCurrentStep = useCallback(() => {
        dispatch({
            type: 'CLOSE_CURRENT_STEP',
        });

        const currentStep = openedSteps[openedSteps.length - 1];

        const stepEvent = EVENTS[currentStep].back;
        analyticService.push({
            eventName: stepEvent,
        });
    }, [openedSteps]);

    const handleCloseAllSteps = useCallback(() => {
        const currentStep = openedSteps[openedSteps.length - 1];
        let stepEvent = EVENTS[currentStep].close;

        if (currentStep === STEP_PHONE) {
            stepEvent = authState === 'pincode' ? EVENTS[currentStep].closeCode : EVENTS[currentStep].close;
        }

        analyticService.push({
            eventName: stepEvent,
        });

        closeCheckout();
    }, [closeCheckout, openedSteps, authState]);

    const handleOpenNextStep = useCallback(() => {
        const nextIndex = openedSteps.length;
        const nextStep = STEPS_ORDER[nextIndex];
        if (!nextStep) return;

        dispatch({
            type: 'OPEN_NEXT_STEP',
            payload: {
                nextStep,
            },
        });

        checkoutDispatch({
            type: 'SET_CHECKOUT_ACTIVE_STEP',
            payload: nextStep,
        });

        const stepEvent = EVENTS[nextStep].open;
        analyticService.push({
            eventName: stepEvent,
        });
    }, [openedSteps, checkoutDispatch]);

    const handleUserEnteredCode = useCallback((result) => {
        const eventName = EVENTS[STEP_PHONE].submitCode;
        analyticService.push({
            eventName,
            result,
        });
    }, []);

    /**
    * Open initial overlay
    */
    useEffect(() => {
        const { user } = userQuery;
        const isUserAuthed = Boolean(user);

        const step = isUserAuthed ? STEP_ADDRESS : STEP_PHONE;
        // const step = STEP_PAYMENT;

        const stepIndex = STEPS_ORDER.findIndex((i) => i === step);
        const initialOpenedSteps = STEPS_ORDER.slice(0, stepIndex + 1);

        dispatch({
            type: 'INIT',
            payload: {
                openedSteps: initialOpenedSteps,
            },
        });

        const lastStep = initialOpenedSteps.at(-1);
        checkoutDispatch({
            type: 'SET_CHECKOUT_ACTIVE_STEP',
            payload: lastStep,
        });

        const stepEvent = EVENTS[step].open;

        if (step === STEP_PHONE && initialAuthState === 'pincode') {
            //
        } else {
            analyticService.push({
                eventName: stepEvent,
            });
        }
        // it's didMount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const currentStep = openedSteps[openedSteps.length - 1];
        if (currentStep === STEP_PHONE && authState === 'pincode') {
            analyticService.push({
                eventName: EVENTS[currentStep].openCode,
            });
        }
    }, [openedSteps, authState]);

    const { locale } = useContext(LocaleContext);

    const dialogTexts = dialogIngridient
        ? getConfirmCustomizationLocales(dialogIngridient[locale])[locale].dialog.success
        : null;

    return (
        <div styleName="step-by-step__container">
            {STEPS_ORDER.map((stepKey) => {
                const stepIndex = openedSteps.findIndex((sk) => sk === stepKey);
                const isOpen = stepIndex !== -1;
                const hasNextOpenStep = stepIndex < openedSteps.length - 1;

                const Component = STEPS_COMPONENTS[stepKey];
                const stepProps = props[stepKey];

                const childProps = {
                    handleOpenNextStep,
                    handleCloseCurrentStep,
                    handleCloseAllSteps,
                    handleGoToFirstStep,

                    currentStepNumber: stepIndex + 1,
                    isNextStepButtonVisible,

                    ...stepProps,
                };

                return (
                    <React.Fragment key={stepKey}>
                        <Transition
                            in={isOpen}
                            timeout={600}
                            key={stepKey}
                        >
                            {(transitionState) => {
                                const containerClasses = cn({
                                    'step-by-step__transition': true,
                                    [transitionState]: true,
                                    'step__transition--shifted': hasNextOpenStep,
                                });

                                return (
                                    <div styleName={containerClasses}>
                                        {transitionState !== 'exited' && (
                                            <Component
                                                {...childProps}
                                                authState={authState}
                                                setAuthState={setAuthState}
                                                handleUserEnteredCode={handleUserEnteredCode}
                                                transitionState={transitionState}
                                            />
                                        )}
                                    </div>
                                );
                            }}
                        </Transition>
                        {dialogTexts && !isCustomizationDialogClosed && (
                            <Portal>
                                <DialogAnimated
                                    {...dialogTexts}
                                    isOpen={Boolean(isCustomizationDialogShown)}
                                    onConfirm={() => {
                                        console.log('confirm');
                                        setIsCustomizationIgnored(true);
                                        setIsCustomizationDialogShown(false);
                                        setIsCustomizationDialogClosed(true);
                                        dispatch({
                                            type: 'SET_IGNORE_DATES_FILTER',
                                            payload: { isNeedToIgnoreDatesFilter: true },
                                        });
                                    }}
                                    onReject={() => {
                                        console.log('reject');
                                        setIsCustomizationIgnored(false);
                                        setIsCustomizationDialogShown(false);
                                        setIsCustomizationDialogClosed(true);
                                        history.push('/basket');
                                    }}
                                />
                            </Portal>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
}

StepByStepCheckout.propTypes = {
    userQuery: PropTypes.shape({
        user: PropTypes.shape({}),
    }).isRequired,
    closeCheckout: PropTypes.func.isRequired,
};
