export const dishes = [
    '3871',
    '3874',
    '3873',
    '3872',
    '3878',
    '3879',
    '3880',
    '3881',
    '3454',
];

export const periods = [
    '2025-01-06',
    '2025-01-13',
];
