import React from 'react';
import { gql, useFragment } from '@apollo/client';
import css from './easterDetails.module.scss';
import '../../dishDetailsWithCustom.scss';

import boxCard1 from './img/boxCard1.ea.jpg';
import boxCard2 from './img/boxCard2.ea.jpg';
import boxCard3 from './img/boxCard3.ea.jpg';
import boxCard4 from './img/boxCard4.ea.jpg';

const EasterInfo = () => (
    <div styleName="new-details-card-info romantic">
        <div styleName="new-details-card-info-line">
            <div styleName="new-details-card-info-line-one">Порция</div>
            <div styleName="new-details-card-info-line-two basket-with--dots" />
            <div styleName="new-details-card-info-line-three">
                <span styleName="new-details-card-info-digit-value">3</span>
                {' '}
                <span styleName="new-details-card-info-text">блюда на двоих</span>
            </div>
        </div>
        <div styleName="new-details-card-info-line">
            <div styleName="new-details-card-info-line-one">Приготовить</div>
            <div styleName="new-details-card-info-line-two basket-with--dots" />
            <div styleName="new-details-card-info-line-three">
                <span styleName="new-details-card-info-text">на</span>
                {' '}
                <span styleName="new-details-card-info-digit-value">1-3</span>
                {' '}
                <span styleName="new-details-card-info-text">день</span>
            </div>
        </div>
    </div>
);

const EasterSetDescription = () => (
    <div className={css.easterboxSetList}>
        <div className={css.mainCard}>
            <h2 className={css.mainCardTitle}>Что в наборе</h2>
            <img className={css.descriptionCardImageMobile} src={boxCard1} alt="description card" />
            <div className={`${css.cardContent} ${css.cardContentMobile}`}>
                <ul className={css.cardContentList}>
                    <li>
                        Всё для приготовления 2 больших роскошных Панеттоне — с начинкой
                        и декором.
                    </li>
                    <li>
                        Всё для украшения 12 яиц —
                        с натуральными красителями и проверенной инструкцией.
                    </li>
                    <li>
                        Всё для пасхального завтрака — чтобы вкусно есть яйца с разными топингами и бутербродами.
                    </li>
                </ul>
            </div>
        </div>
        <div className={css.mainCard}>
            <img className={css.descriptionCardImageMobile} src={boxCard4} alt="description card" />
            <div className={`${css.cardContent} ${css.cardContentMobile}`}>
                <p className={css.cardContentTitle}>1. Панеттоне</p>
                <ul className={css.cardContentList}>
                    <li>
                        Внутри 11 видов орехов и сухофруктов! Включая макадамию, пекан, инжир и сушеную клюкву (и никакой
                        кураги!)
                    </li>
                    <li>
                        Плюс для особого вкуса кулича – марципан и натуральные апельсиновые цукаты! А для украшения –
                        миндаль в пасхальной глазури.
                    </li>
                    <li>Все ингредиенты чётко отмерены, чтобы у вас легко поднялось тесто и взбилась глазурь.</li>
                    <li>Красивые формы для выпечки – тоже в наборе.</li>
                </ul>
            </div>
        </div>
        <div className={css.mainCard}>
            <img className={css.descriptionCardImageMobile} src={boxCard3} alt="description card" />
            <div className={`${css.cardContent} ${css.cardContentMobile}`}>
                <p className={css.cardContentTitle}>2. Пасхальные яйца</p>
                <ul className={css.cardContentList}>
                    <li>
                        Только с натуральными красителями и с проверенной инструкцией для нежных и благородных цветов. А с
                        золотом в наборе — получится прямо как у Фаберже!
                    </li>
                    <li>
                        И самое приятное — мы всё подготовим, чтобы вам не пришлось даже пачкать руки лишний раз: выжимаем
                        свекольный сок, режем красную капусту, отмеряем куркуму и каркаде.
                    </li>
                </ul>
            </div>
        </div>
        <div className={css.mainCard}>
            <img className={css.descriptionCardImageMobile} src={boxCard2} alt="description card" />
            <div className={`${css.cardContent} ${css.cardContentMobile}`}>
                <p className={css.cardContentTitle}>3. Пасхальный завтрак</p>
                <ul className={css.cardContentList}>
                    <p>В наборе — всё, что вкусно есть с яйцами!</p>
                    <li>Багет, лосось, кремчиз, блючиз, чоризо – для бутербродов на любой вкус.</li>
                    <li>
                        А ещё свежая зелень и наш домашний майонез с зернышками дижонской горчицы, которыми классно
                        дополнять варёные яйца.
                    </li>
                </ul>
            </div>
        </div>
    </div>
);

export const EasterDetails = () => {
    const { data } = useFragment({
        fragment: gql`fragment DISH_TITLE on menuDish {
            title
            caption
        }`,
        from: {
            __ref: 'menuDish:4875',
        },
    });

    const title = data?.title;
    const caption = data?.caption;
    return (
        <div className={css.easterboxDetailsRoot}>
            <div className={css.easterboxDishTitle}>
                {title}
                <br />
                {caption}
            </div>
            <div className={css.easterboxSetList}>
                <EasterSetDescription />
            </div>
        </div>
    );
};
