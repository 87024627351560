/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, {
    useCallback, useContext, useEffect, useMemo, useReducer,
    useState,
} from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';

import FETCH_AVAILABLE_MENU_DATES, { MenuDatesCustomizationQuery } from 'app/graphql/network/menuDates';
import { errorService } from 'global/services';

import { getCustomizationSource } from 'app/customizationSource/customizationSource';
import { formatDateToISO } from 'app/utils/date';

import { THREE_DAYS_IN_UTC_FORMAT } from './menuDates.consts';
import resolveDefaultDate from './menuDates.resolveDefaultDate';
import { selectedPeriodVar } from '../../apollo/reaction';
import { LocationContext } from '../LocationProvider';


export const menuDatesState = React.createContext();

const currentDateInitial = (() => {
    const now = new Date();
    const today = new Date(formatDateToISO(now));
    return today.getTime();
})();


const initialState = {
    firstVisibleDates: {}, // first visible datesData (period.isVisibleInFilter === 'visible')
    enabledDates: {}, // all enabled datesData (period.isVisibleInFilter !== 'disabled')
    rawDates: {}, // data from backend

    eeAvailableDates: false,
    everydayAvailableDates: false,
    premiumAvailableDates: false,
    topAvailableDates: false,
    trialAvailableDates: false,
    zapasAvailableDates: false,

    // FIXIT: Это значение первого доступного периода в udd. Внезапно
    filterPeriod: '',
    selectedFilterPeriod: '',
    prevSelectedFilterPeriod: '',

    currentDate: currentDateInitial,
    defaultDate: '',
    selectedDate: '',
    prevSelectedDate: '',

    dateFilterStatus: '0',
    prevDateFilterStatus: null,

    isSelectedDateInEditableRange: true,
};

function periodFilterReducer(state, action) {
    switch (action.type) {
        case 'SET_INITIAL_EE_DATES_DATA_AFTER_FETCH': {
            return {
                ...state,
                ...action.payload,
            };
        }
        case 'SET_RAW_DATE_DATA': {
            return { ...state, rawDates: action.payload };
        }
        case 'SET_ENABLED_DATE_DATA': {
            return { ...state, enabledDatesData: action.payload };
        }
        case 'SET_NEW_CURRENT_DATE': {
            return { ...state, currentDate: action.payload };
        }
        case 'RESET_FILTER_STATUS_TO_DEFAULT': {
            return { ...state, dateFilterStatus: '0' };
        }
        case 'SET_SELECTED_DATE': {
            const { selectedDiff, selectedPeriod } = action.payload;
            const nextSelectedDate = formatDateToISO(new Date(state.currentDate + selectedDiff * 1000));
            const isSelectedDateInEditableRange = Number(selectedDiff) >= Number(THREE_DAYS_IN_UTC_FORMAT);

            return {
                ...state,

                selectedDate: nextSelectedDate,
                prevSelectedDate: state.selectedDate,

                selectedFilterPeriod: selectedPeriod,
                prevSelectedFilterPeriod: state.selectedFilterPeriod,

                dateFilterStatus: selectedDiff,
                prevDateFilterStatus: state.dateFilterStatus,

                isSelectedDateInEditableRange,
            };
        }
        case 'FILTER_AVAILABLE_DISHES_AND_SETS': {
            return { ...state, ...action.payload };
        }
        case 'SET_NEW_FILTER_PERIOD': {
            return {
                ...state,
                selectedFilterPeriod: action.payload,
                prevSelectedFilterPeriod: state.selectedFilterPeriod,
            };
        }
        default: {
            return state;
        }
    }
}

// Selector
const selectEnabledDishesAndSets = (datesData, selectedDate) => Object.keys(datesData).reduce((acc, name) => {
    acc[name] = datesData[name].includes(selectedDate);
    return acc;
}, {});

const NYDeliveryDatesToExclude = [
    '2025-01-04',
    '2025-01-05',
];

const MenuDatesProvider = (props) => {
    const {
        userQuery: { user },
        filterQuery: { menuFilter },
        children,
    } = props;

    const selectedPeriod = useReactiveVar(selectedPeriodVar);
    const [state, dispatch] = useReducer(periodFilterReducer, initialState);

    const [menuDates, setMenuDates] = useState([]);
    const { locationKey } = useContext(LocationContext);

    const {
        currentDate,
        selectedDate,
        filterPeriod,
        firstVisibleDates,
        rawDates,
    } = useMemo(() => state, [state]);

    /* REQUESTS */

    const { data: customizationData } = useQuery(MenuDatesCustomizationQuery);

    const customization = customizationData ? getCustomizationSource(customizationData) : null;

    const handleFetchDates = (fetchedDates) => {
        try {
            const { dates } = fetchedDates;

            const { menu } = queryString.parse(window.location.search);

            const enabledPeriods = menuFilter.periods.filter(
                (period) => period.isVisibleInFilter !== 'disabled',
            );

            const firstVisiblePeriod = enabledPeriods.find(
                (period) => period.isVisibleInFilter === 'visible',
            );

            const firstPeiodToPick = (menu && menu === 'premium')
                ? menuFilter.periods[0]
                : firstVisiblePeriod;

            const firstVisiblePeriodDates = dates.find(
                ({ startDate }) => startDate === firstPeiodToPick.start,
            );

            /* SET INITIAL DATA */
            const { date: dateFromUrl } = queryString.parse(window.location.search);

            const defaultDate = resolveDefaultDate({
                menuDates: firstVisiblePeriodDates,
                currentDate,
                dateFromUrl,
            });

            const initialPayload = {
                filterPeriod: firstVisiblePeriod.start,
                selectedFilterPeriod: firstVisiblePeriod.start,
                rawDates: dates,
                defaultDate,
                selectedDiff: defaultDate,
                firstVisibleDates: firstVisiblePeriodDates,
            };

            dispatch({
                type: 'SET_INITIAL_EE_DATES_DATA_AFTER_FETCH', payload: initialPayload,
            });

            dispatch({
                type: 'SET_SELECTED_DATE',
                payload: {
                    selectedDiff: defaultDate, selectedPeriod: firstVisiblePeriod.start,
                },
            });
        } catch (error) {
            errorService.log({
                text: 'Error on MenuDatesProvider init data executed',
                source: 'client',
                scope: 'menuDates.context.js',
                error,
            });
        }
    };


    const data = useQuery(FETCH_AVAILABLE_MENU_DATES, {
        fetchPolicy: 'network-only',
        variables: {
            filtersModified: Boolean(customization),
        },
        onCompleted: ({ menuDates }) => {
            setMenuDates(menuDates);
            handleFetchDates(menuDates);
        },
        onError: (error) => {
            errorService.log({
                text: 'Error on MenuDatesProvider init data fetched',
                source: 'server',
                scope: 'menuDates.context.js',
                error,
            });
        },
        context: {
            message: 'app:init:MenuDatesProvider',
        },
        skip: !menuFilter || !customizationData,
    });

    /* SELECTORS  */
    const hasDeliveries = useMemo(() => (!user ? false : !user?.isNewbie), [user]);

    // TODO: сделать нормальную проверку после нового года
    const isDeliveryEditing = window.location.pathname.includes('delivery');

    /* EFFECTS  */

    // установка выбранного периода
    useEffect(() => {
        if (selectedPeriod) {
            dispatch({ type: 'SET_NEW_FILTER_PERIOD', payload: selectedPeriod });
        }
    }, [selectedPeriod]);

    useEffect(
        () => {
            handleFetchDates(menuDates);
        },
        [locationKey],
    );

    // извлечение, обработка и сохранение из rawDates нужных данных
    useEffect(() => {
        if (isEmpty(rawDates)) return;

        const processedDates = rawDates.map((i) => ({
            ...i,
            trialAvailableDates: hasDeliveries ? [] : i.trialAvailableDates,
        }));

        dispatch({ type: 'SET_ENABLED_DATE_DATA', payload: processedDates });
    }, [rawDates, hasDeliveries, filterPeriod]);

    // useEffect(() => {
    //     if (!firstVisibleDates || isEmpty(firstVisibleDates)) return;

    //     const { date: dateFromUrl } = queryString.parse(window.location.search);

    //     const defaultDate = resolveDefaultDate({
    //         menuDates: firstVisibleDates,
    //         currentDate,
    //         dateFromUrl,
    //     });

    //     handleSelectDate(defaultDate);
    //     dispatch({ type: 'SET_DEFAULT_DATE', payload: defaultDate });
    // }, [firstVisibleDates, currentDate, handleSelectDate]);

    useEffect(() => {
        if (selectedDate && !isEmpty(firstVisibleDates)) {
            /*
                TODO: вместо firstVisibleDates в функцию нужно передавать объект,
                в котором будут массивы дат наборов из first visisble period, а массив дат для EE
                из выбранного юзером периода
                Тогда можно будет убрать новогодний if ниже
            */
            // FIXIT Чтобы в функцию не попадали лишние поля
            const result = selectEnabledDishesAndSets({
                eeAvailableDates: firstVisibleDates?.eeAvailableDates || [],
                everydayAvailableDates: firstVisibleDates?.everydayAvailableDates || [],
                premiumAvailableDates: firstVisibleDates?.premiumAvailableDates || [],
                topAvailableDates: firstVisibleDates?.topAvailableDates || [],
                trialAvailableDates: firstVisibleDates?.trialAvailableDates || [],
                zapasAvailableDates: firstVisibleDates?.zapasAvailableDates || [],
            }, selectedDate);

            dispatch({ type: 'FILTER_AVAILABLE_DISHES_AND_SETS', payload: result });
        }
    }, [selectedDate, firstVisibleDates, isDeliveryEditing]);


    /* HANDLERS  */

    const handleSelectDate = useCallback((selectedDiff, selectedPeriod) => {
        dispatch({ type: 'SET_SELECTED_DATE', payload: { selectedDiff, selectedPeriod } });
    }, []);

    /* CONTEXT DATA  */

    const contextValue = useMemo(() => ({
        state,
        dispatch,
        onSelectDate: handleSelectDate,
    }), [handleSelectDate, state]);

    return (
        <menuDatesState.Provider value={contextValue}>
            {children}
        </menuDatesState.Provider>
    );
};


const { Consumer } = menuDatesState;
export const withMenuDatesContext = (Component) => (props) => (
    <Consumer>
        {(menuDatesContext) => <Component {...props} menuDatesContext={menuDatesContext} />}
    </Consumer>
);


export default MenuDatesProvider;
