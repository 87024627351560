import { useMutableState } from 'app/utils/hooks';
import { useReactiveVar } from '@apollo/client';
import { isBasketFilledVar } from 'app/apollo/reaction';
import {
    checkPrimarySign,
    checkHiddenElemnts,
} from './handlers';


const initialState = {
    type: 'INITIAL_BASKET',
    needDeepCheck: true,
    preparedAreas: {
        top: {
            isCustomizationComment: false,
        },
        list: {
            titleType: 'regular',
        },
    },
};

/**
 * @param {basketProps} props
 * @returns Возвращает тип корзины в виде строки и отдает в dispatch basketUiStore.context
 * @NOTE Сигнатура хука нужна, чтобы вызвать useMutableState. Мне кажется такой хук пригодится гдето еще ))
 *
 * @description Определяет тип корзины.
 * За основу была взяла концепция chain of responsibility - привратить поведение в обьект
 * передовая его по цепи обработчиков до тех пор, пока мы не получим нужный тип.
 */
export const useBasketTypeChain = (props) => {
    /*
     * Кастомный хук
     * Мутабельный стейт. Является основным управляющим объектом. Не вызывает ререндер.
     */
    const [setPrepState, getPrepState] = useMutableState(initialState);
    const isBasketFilled = useReactiveVar(isBasketFilledVar);
    /**
    * Поехали по цепи!
    * Каждый обработчик либо ставит тип сам, либо отдает его следующему обработчику через state
    * @see __dev__signHandler.js пример обработчика __dev__sign,
    */
    [
        checkHiddenElemnts,
        checkPrimarySign,
    ].forEach((handler) => handler({ ...props, isBasketFilled }, setPrepState, getPrepState));
    // Взяли после поехали
    const result = getPrepState();
    // Отдали в basketUiStore.context после того как взяли
    return result;
};
